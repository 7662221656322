import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { signUpWithInvite } from '../../../redux/actions/authActions'
import { Redirect } from 'react-router-dom'

import Header from './Header'
import CreateUserFromInvite from './CreateUserFromInvite'

class SignUpWithInvite extends Component {
    state = {
        email: '',
        password: '',
        password_2: '',
        fullName: '',
        passwordError: ''
    }

    handleChange = (e) => {
        this.setState({
           [e.target.id]: e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const { email, password, password_2, fullName } = this.state;
        const params = new URLSearchParams(this.props.location.search);
        const invite_id = params.get('invite'); 
        const account_id = params.get('account'); 

        if (password !== password_2) {
          this.setState({passwordError: "Passwords don't match."})
        } else {
          var firstName = fullName.split(' ').slice(0, -1).join(' ');
          var lastName = fullName.split(' ').slice(-1).join(' ');
  
          const newUser = {
            email: this.props.invite_data.email,
            password,
            fullName
          }

        this.props.signUpWithInvite(newUser, account_id, this.props.invite_data)
          
          
        }

    }

    render() {
        const { authError, auth, signup_status } = this.props;
        const { showEmailSetup, passwordError } = this.state;
        if (auth.uid) return <Redirect to="/" />

        const params = new URLSearchParams(this.props.location.search);
        const param_fields = {
            email: params.get('email'),
            invite_id: params.get('invite'),
            account_id: params.get('account')
        }; 

        return (

    <div style={{top:'80px', paddingBottom:'60px'}}>

        <Header selected='create-account' />

        <div style={{paddingTop:'80px'}}>

        <CreateUserFromInvite
            handleSubmit={this.handleSubmit} 
            handleChange={this.handleChange}
            signInWithGoogle={this.props.signInWithGoogle}
            passwordError={passwordError}
            authError={authError}
            params={param_fields}
            invite_data={this.props.invite_data}
        />
          
        
      </div>




    </div>

        )
    }
}

const mapStateToProps = (state, ownProps) => {
    console.log(state)
    const params = new URLSearchParams(ownProps.location.search);

    const invite_id = params.get('invite');
    return {
        authError: state.auth.authError,
        authLoading: state.auth.authLoading,
        auth: state.firebase.auth,
        signup_status: state.auth.signup_status,
        invite_id: invite_id,
        invite_data: state.firestore.ordered.user_invites ? state.firestore.ordered.user_invites[0] : null
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signUpWithInvite: (newUser, account_id, invite_id) => dispatch(signUpWithInvite(newUser, account_id, invite_id)),
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((props) => [  
       {
        collection: "user_invites",
        doc: props.invite_id
        }
    ])
  )(SignUpWithInvite);



