import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import {
  updateVideoCluePlayingControl,
  updateVideoClueOnScreen,
  updateVideoClueCurrentTime,
} from "../../../../redux/actions/timerActions";
import FadeIn from "react-fade-in";
import { loadAudioState, saveAudioState } from "../../../../localStorage";

import Hints from "./Hints";
import { classNames } from "../../../../utils/Utils";

function TimerScreen(props) {
  const {
    settings,
    clueOnScreen,
    time,
    video_brief,
    videoIsPlaying,
    clueOnScreen_Video,
    audio_volume,
    gameComplete,
    isInfiniteClues,
    clueOnScreenCount,
    background_image,
    customIcon,
    handleVideoClueFinished,
  } = props;

  const bg_video = useRef();
  const video_clue_ref = useRef();
  const [anyVideoPlaying, setAnyVideoPlaying] = useState(false);
  const [bgVideoPlaying, setBGVideoPlaying] = useState(false);
  const [videoCluePlaying, setVideoCluePlaying] = useState(false);

  const [bg_video_src, setBGVideo] = useState();
  const [video_clue_src, setVideoClueSrc] = useState();
  const [video_brief_src, setVideoBrief] = useState();
  const [videoKey, setVideoKey] = useState();

  const [videoClueOnScreen, setClueOnScreenVideo] = useState();
  const [videoClueDonePlaying, setVideoClueDonePlaying] = useState(false);

  useEffect(() => {
    console.log("LS TEST");
    try {
      const serializedState = localStorage.getItem("BOLT_videoClue");

      if (serializedState === null) {
        return localStorage.setItem("BOLT_videoClue");
      }

      if (!videoClueOnScreen || videoClueOnScreen !== clueOnScreen_Video)
        setClueOnScreenVideo(JSON.parse(serializedState));
    } catch (err) {
      console.log(err);
    }
  }, [clueOnScreen_Video]);

  useState(() => {
    // clear local storage on laod
    props.updateVideoClueOnScreen(null);
  }, []);

  useEffect(() => {
    if (video_brief) {
      setVideoBrief(video_brief.url);
      //bg_video.current.volume = audio_volume.audio_video_brief;
    }
  }, [video_brief]);

  useEffect(() => {
    if (videoClueOnScreen !== clueOnScreen_Video) {
      setTimeout(() => {
        setVideoClueDonePlaying(false);
        handlePlayVideoClue();
      }, 750);
    }
  }, [videoClueOnScreen]);

  useEffect(() => {
    if (videoIsPlaying) {
      setAnyVideoPlaying(true);
      setBGVideoPlaying(false);
      bg_video.current.pause();
      bg_video.current.loop = false;
      setTimeout(() => {
        setVideoKey("video_brief");
        handlePlayVideo();
      }, 500);
    } else if (
      !videoIsPlaying &&
      videoKey === "video_brief" &&
      background_image &&
      background_image.type &&
      background_image.type.startsWith("video/")
    ) {
      setAnyVideoPlaying(false);
      handleStopVideo();

      setBGVideoPlaying(true);
      setVideoKey("videoBG");
      setBGVideo(background_image.url);
      setTimeout(() => {
        bg_video.current.play();
        bg_video.current.loop = true;
      }, 0);
    } else {
      setAnyVideoPlaying(false);
      handleStopVideo();
    }
  }, [videoIsPlaying]);

  useEffect(() => {
    if (
      !videoIsPlaying &&
      background_image &&
      background_image.type &&
      background_image.type.startsWith("video/")
    ) {
      console.log("bg video play");
      setBGVideoPlaying(true);
      setVideoKey("videoBG");
      setBGVideo(background_image.url);
      setTimeout(() => {
        bg_video.current.play();
        bg_video.current.loop = true;
      }, 0);
    }
  }, [background_image]);

  const convertPXtoVH = (px) => {
    return px * (100 / [document.documentElement.clientHeight]) * 3.4 + "vh";
  };

  const timerTextStyle = (position) => {
    var style = {};

    if (position === "main") {
      style = {
        fontSize:
          settings.timer && settings.timer.size
            ? settings.timer.size + "vh"
            : "22vh",
        fontWeight: "bold",
        color:
          settings.timer && settings.timer.color
            ? settings.timer.color
            : "#fafafa",
        display:
          settings.timer && settings.timer.show === false ? "none" : null,
        fontFamily:
          settings.timer && settings.timer.font ? settings.timer.font : null,
      };
    }

    if (position === "small") {
      style = {
        fontSize: "8vh",
        fontWeight: "bold",
        color:
          settings.timer && settings.timer.hintTimerColor
            ? settings.timer.hintTimerColor
            : "#fafafa",
        fontFamily:
          settings.timer && settings.timer.font ? settings.timer.font : null,
        marginBottom: "5px",
      };
    }

    return {
      ...style,
    };
  };

  const handlePlayVideo = () => {
    // if VideoIsPlaying

    if (videoIsPlaying) {
      if (bg_video) {
        // Set Background Style
        document.body.style.backgroundColor = "#000";
        document.body.style.backgroundImage = "";

        const audioState = loadAudioState();

        bg_video.current.onended = function (e) {
          /*Do things here!*/
          setTimeout(() => {
            console.log("video ended");
            props.handleVideoFinished();
          }, 500);
        }.bind(this);

        bg_video.current.ontimeupdate = function (e) {
          var currentTime = bg_video.current.currentTime;
          var duration = bg_video.current.duration;

          saveAudioState("video_brief_current", currentTime.toFixed(0));
          saveAudioState("video_brief_duration", duration.toFixed(0));
          //console.log('currentTime', currentTime)
          //console.log('duration', duration)
        };

        // Do not autoplay
        bg_video.current.autoplay = false;
        bg_video.current.loop = false;

        // Audio State
        const savedAudioState = audioState["audio_video_brief"];
        if (savedAudioState) bg_video.current.volume = savedAudioState;
        //console.log('AUDIO', savedAudioState)

        var playVideoBriefPromise = bg_video.current.play();
        if (playVideoBriefPromise !== undefined) {
          playVideoBriefPromise
            .then((_) => {
              // Automatic playback started!
              // Show playing UI.
              console.log("video brief is playing");
            })
            .catch((error) => {
              // Auto-play was prevented
              // Show paused UI.
              console.log("video error", error);
              props.handleVideoFinished();
            });
        }
      }
    } else {
      document.body.style.backgroundColor = this.state.settings.background_color
        ? this.state.settings.background_color
        : "#1e1d23";
      bg_video.current.load();
      bg_video.current.oncanplaythrough = function (e) {
        console.log("Can play through video without stopping", e);
      };
    }
  };

  const handleStopVideo = () => {
    if (bg_video) {
      bg_video.current.pause();
      bg_video.current.currentTime = 0;
    }
  };

  const handlePlayVideoClue = () => {
    if (video_clue_ref) {
      // Set Background Style
      document.body.style.backgroundColor = "#000";
      document.body.style.backgroundImage = "";

      const audioState = loadAudioState();

      video_clue_ref.current.onended = function (e) {
        video_clue_ref.current.pause();

        /*Do things here!*/
        setTimeout(() => {
          console.log("VIDEO CLUE ENDED");
          //props.handleVideoFinished();
          props.updateVideoClueOnScreen(null);
          setVideoClueDonePlaying(true);
          setVideoCluePlaying(false);
          setClueOnScreenVideo(null);
        }, 100);
      }.bind(this);

      video_clue_ref.current.ontimeupdate = function (e) {
        var currentTime = video_clue_ref.current.currentTime;
        var duration = video_clue_ref.current.duration;

        console.log("video clue playing:", currentTime);
        const obj = {
          currentTime,
          duration,
        };
        if (videoClueOnScreen) props.updateVideoClueCurrentTime(obj);

        // saveAudioState("video_brief_current", currentTime.toFixed(0));
        // saveAudioState("video_brief_duration", duration.toFixed(0));
        //console.log('currentTime', currentTime)
        //console.log('duration', duration)
      };

      // Do not autoplay
      video_clue_ref.current.autoplay = false;
      video_clue_ref.current.loop = false;

      // Audio State
      // const savedAudioState = audioState["audio_video_brief"];
      // if (savedAudioState) bg_video.current.volume = savedAudioState;
      //console.log('AUDIO', savedAudioState)

      var playVideoPromise = video_clue_ref.current.play();
      if (playVideoPromise !== undefined) {
        playVideoPromise
          .then((_) => {
            // Automatic playback started!
            // Show playing UI.
            console.log("video clue is playing");
          })
          .catch((error) => {
            // Auto-play was prevented
            // Show paused UI.

            if (
              error instanceof DOMException &&
              (error.code === DOMException.NOT_ALLOWED_ERR ||
                error.code === DOMException.INVALID_ACCESS_ERR)
            ) {
              console.error(
                "play() failed because the user didn't interact with the document first."
              );
              props.updateVideoClueOnScreen({
                error: error,
                type: "NOT_ALLOWED"
              });
              // You can display a message or take appropriate action to handle this scenario.
            } else {
              throw error; // Rethrow the error if it's not the expected type or code
            }

            //props.handleVideoFinished();
          });
      }
    }
  };

  const handleStopVideoClue = () => {
    if (bg_video) {
      bg_video.current.pause();
      bg_video.current.currentTime = 0;
    }
  };

  const bgStyle = () => {
    if (
      !videoIsPlaying &&
      background_image &&
      background_image.type.startsWith("image/")
    ) {
      return {
        background: "url(" + background_image.url + ")",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      };
    } else if (
      videoIsPlaying ||
      (background_image && background_image.type.startsWith("video/"))
    ) {
      return {
        background: "#000",
      };
    } else if (
      clueOnScreen &&
      clueOnScreen.type === "image" &&
      clueOnScreen.transparent_bg === false
    ) {
      return {
        background: "#000",
      };
    } else if (
      clueOnScreen &&
      clueOnScreen.type === "video" &&
      clueOnScreen.transparent_bg === false
    ) {
      return {
        background: "#000",
      };
    } else {
      return {
        background:
          settings.timer && settings.timer.bg_color
            ? settings.timer.bg_color
            : "#000",
      };
    }
  };

  console.log("Video on Screen?", videoClueOnScreen);

  return (
    <div
      id="timer-screen"
      className="container pd-0"
      style={{
        ...bgStyle(),
        maxWidth: "100%",
        height: "100%",
        //display: gameComplete ? 'none': 'flex',
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        position: "fixed",
      }}
    >
      <video
        width="100%"
        className="fade-in"
        style={{
          objectFit: "cover",
          position: "fixed",
          minWidth: "100%",
          minHeight: "100%",
          display: !anyVideoPlaying && !bgVideoPlaying ? "none" : null,
        }}
        muted={bgVideoPlaying && !videoIsPlaying ? true : false}
        preload="auto"
        key={videoKey}
        ref={bg_video}
      >
        {bgVideoPlaying && !videoIsPlaying && (
          <source
            key="bg-video"
            src={bg_video_src ? bg_video_src : null}
            type="video/mp4"
          />
        )}

        <source
          key="video-brief"
          src={video_brief_src ? video_brief_src : null}
          type="video/mp4"
        />
      </video>

      <video
        height="100%"
        className="fade-in"
        style={{
          objectFit: "cover",
          position: "fixed",

          minHeight: "100%",
          display: videoClueOnScreen && !videoClueDonePlaying ? null : "none",
        }}
        //muted={bgVideoPlaying && !videoIsPlaying ? true : false}
        preload="auto"
        key={videoClueOnScreen ? videoClueOnScreen.id : "video_clue"}
        ref={video_clue_ref}
      >
        {videoClueOnScreen && !videoClueDonePlaying && (
          <source
            key={videoClueOnScreen ? videoClueOnScreen.id : "video_clue_id"}
            src={
              videoClueOnScreen && videoClueOnScreen.file
                ? videoClueOnScreen.file.url
                : null
            }
            type="video/mp4"
          />
        )}
      </video>

      {clueOnScreen &&
      clueOnScreen.type !== "image" &&
      !videoIsPlaying &&
      !gameComplete ? (
        <FadeIn>
          <div
            id="timer-text"
            style={{
              position: "fixed",
              top: "2vw",
              right: "2vw",
              backgroundColor:
                settings && settings.timer && settings.timer.bg_color_hintTimer
                  ? settings.timer.bg_color_hintTimer
                  : "black",
              padding: "2vh 4vh",
              borderRadius: "3vh",
              display:
                settings && settings.timer && !settings.timer.show_hintTimer
                  ? "none"
                  : null,
            }}
          >
            {time ? (
              <h1 className={classNames(
                background_image ? "shadow-lg" : null,
              )} style={timerTextStyle("small")}>
                {time.m}:{time.s}
              </h1>
            ) : null}
          </div>
        </FadeIn>
      ) : null}

      {clueOnScreen ||
      videoIsPlaying ||
      gameComplete ||
      videoClueOnScreen ? null : (
        <FadeIn>
          <div
            id="timerBox"
            className="row"
            style={{
              marginRight: "0px",
              marginLeft: "0px",
              marginBottom:
                settings && settings.timer && settings.timer.show_hintIcons
                  ? "15vh"
                  : "5vh",
            }}
          >
            <div className="col-sm-12">
              <div
                className="u-mv-large text-center"
                style={{
                  marginBottom: "10px !important",
                  color: "#fafafa",
                }}
              >
                {time ? (
                  <React.Fragment>
                    <p
                      className="small-header-text"
                      style={{
                        fontSize: "3vh",
                        color:
                          settings.timer && settings.timer.color
                            ? settings.timer.color
                            : "#fafafa",
                        marginBottom: "0px !important",
                        display:
                          settings.timer &&
                          settings.timer.show_timeRemaining === false
                            ? "none"
                            : null,
                      }}
                    >
                      Time Remaining
                    </p>

                    <h1 style={timerTextStyle("main")}>
                      {time.m}:{time.s}
                    </h1>
                  </React.Fragment>
                ) : (
                  <div className="text-center">
                    <div
                      className="spinner-border"
                      style={{ color: "#fafafa" }}
                    ></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </FadeIn>
      )}

      {clueOnScreen &&
      clueOnScreen.type === "text" &&
      !videoIsPlaying &&
      !gameComplete &&
      !videoClueOnScreen ? (
        <FadeIn>
          <div
            id="clueBox"
            className="row"
            style={{
              marginRight: "0px",
              marginLeft: "0px",
              marginBottom: "10vh",
              padding: "0px 20vh",
            }}
          >
            <div className="col-sm-12">
              <div
                className="text-center"
                style={{
                  marginTop: "20px",
                  marginBottom: "10px !important",
                }}
              >
                <h1
                  style={{
                    //fontSize: "7vh",
                    fontSize:
                      settings.timer && settings.timer.size_hintText
                        ? convertPXtoVH(settings.timer.size_hintText)
                        : "7vh",
                    lineHeight:
                      settings.timer && settings.timer.size_hintText
                        ? convertPXtoVH(settings.timer.size_hintText)
                        : "7vh",
                    color:
                      settings.timer && settings.timer.hintColor
                        ? settings.timer.hintColor
                        : "#fafafa",
                    whiteSpace: "pre-wrap",
                    fontFamily:
                      settings.timer && settings.timer.hintFont
                        ? settings.timer.hintFont
                        : null,
                  }}
                >
                  {clueOnScreen.message}
                </h1>
              </div>
            </div>
          </div>
        </FadeIn>
      ) : null}

      {clueOnScreen &&
      clueOnScreen.type === "image" &&
      !videoIsPlaying &&
      !gameComplete &&
      !videoClueOnScreen ? (
        <FadeIn>
          <div
            id="imageClueBox"
            className="w-full h-full flex items-center justify-center"
          >
            <div className="h-screen w-screen flex items-center justify-center">
              <img
                src={clueOnScreen.file.url}
                className={classNames(
                  " object-cover max-h-screen max-w-screen"
                )}
                loading="lazy"
              ></img>
            </div>
          </div>
        </FadeIn>
      ) : null}

      {!videoIsPlaying &&
        !gameComplete &&
        settings &&
        settings.timer &&
        settings.timer.show_hintIcons &&
        clueOnScreen.type !== "image" &&
        !videoClueOnScreen && (
          <Hints
            isInfiniteClues={isInfiniteClues}
            clueOnScreenCount={clueOnScreenCount}
            settings={settings}
            customIcon={customIcon}
          />
        )}
    </div>
  );
}

// const mapStateToProps = (state) => {
//   return {
//     videoClueIsPlaying: state.timer.videoCluePlaying,
//   };
// };

const mapDispatchToProps = (dispatch) => {
  return {
    updateVideoCluePlayingControl: (boolean) =>
      dispatch(updateVideoCluePlayingControl(boolean)),
    updateVideoClueOnScreen: (clue) => dispatch(updateVideoClueOnScreen(clue)),
    updateVideoClueCurrentTime: (obj) =>
      dispatch(updateVideoClueCurrentTime(obj)),
  };
};

export default connect(null, mapDispatchToProps)(TimerScreen);
