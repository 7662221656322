import React, { useState } from "react";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";

import { Style } from './Exports'


type Props = {
  account: any,
  messages: any;
  messageObj: any;
  timer: any;
};


export const Message_Audio = (props: Props) => {
  const { messageObj, timer, messages, account } = props;

  const message = messageObj ? messageObj.message : "0";
  const clueOnScreen = timer ? timer.clueOnScreen : "0";

  console.log(messages);


  const isClueOnScreen = () => {
    if (message === clueOnScreen) return true;
  };

  const [hover, setHover] = useState(false);

  return (
    <div
      key={Math.random() * 1000}
      className="media"
      style={Style(hover)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {/* <div style={{ marginRight: "20px" }}>
        <h5>
          {messageObj.game_timestamp.m}:{messageObj.game_timestamp.s}
        </h5>
      </div> */}

      <div
        style={{
          width: "40px",
          height: "40px",
          borderRadius: "20px",
          background: "rgb(59 63 68)",
          marginRight: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <i
          className="icon ion-md-volume-high"
          style={{ fontSize: "22px", color: "#c9cece" }}
        ></i>
      </div>

      <div
        className="media-body"
        style={{ marginLeft: "10px", paddingRight: "20px" }}
      >
        <h5
          style={{
            fontWeight: "600",
            fontSize: "15px",
            whiteSpace: "pre-wrap",
            marginBottom: "3px",
          }}
        >
          {messageObj.puzzle && (
            <small
              className="d-block text-muted"
              style={{ 
                fontSize: "14px", 
                marginBottom: "3px",
                }}
            >
              <i
                className="icon ion-md-return-right"
                style={{
                  color: "rgb(114 124 128)",
                  marginTop: "-2px",
                  marginLeft: "5px",
                  marginRight: "5px",
                  transform: "rotateX(180deg)",
                  display: "inline-flex",
                }}
              ></i>
              {messageObj.puzzle}
            </small>
          )}
          {/* {messageObj.message} */}
          {account.title}{" "}
          <span
            className="text-muted"
            style={{ fontWeight: "500", fontSize: "12px", marginLeft: "8px" }}
          >
            {messageObj.game_timestamp.m}:{messageObj.game_timestamp.s} - {moment(messageObj.timestamp).fromNow()}
          </span>
        </h5>

        {/* <h5
          className="mg-t-2 mg-b-2"
          style={{
            fontWeight: "400",
            fontSize: "15px",
            whiteSpace: "pre-wrap",
          }}
        >
          {messageObj.message}
        </h5> */}

        <div style={{width:'100%', height:'75px', borderRadius:'8px', background: 'rgb(65 65 74)'}}>
            <div id="waveform"></div>
        </div>

      </div>

      {isClueOnScreen() ? (
        <Tooltip title="Hint on Screen" placement="left">
          <i
            className="icon ion-ios-eye"
            style={{ marginRight: "10px", float: "right", fontSize: "18px" }}
          ></i>
        </Tooltip>
      ) : null}
    </div>
  );
};
