const initState = {
    timerRunning: false,
    videoIsPlaying: false
}

const remoteReducer = (state = initState, action) => {
    switch (action.type) {

        case 'VIDEOBUTTON_LOADING': 
            return {
                ...state,
                videoButtonLoading: action.state
            };

        case 'ADJUST_TIMER_LOADING': 
            return {
                ...state,
                adjustTimerButtonLoading: action.state
            };
        
        case 'REMOTE_VIDEOPLAYING': 
            return ;

        case 'REMOTE_VIDEOPLAYING_ERROR': 
            return state;

        case 'REMOTE_TIMERRUNNING': 
            return {
                ...initState,
                timerRunning: action.state,
                videoButtonLoading: false
            };

        case 'REMOTE_TIMERRUNNING_ERROR': 
            return state;

        case 'REMOTE_SENDCLUE': 
            return {
                ...state,
                clueOnScreen: action.state
            };

        case 'REMOTE_SENDCLUE_ERROR': 
            return state;

        case 'REMOTE_UPDATE_CLUE_ON_SCREEN_COUNT':
            return state;

        case 'REMOTE_UPDATE_CLUE_ON_SCREEN_COUNT_ERROR':
            return state;

        case 'REMOTE_IS_INFINITE_CLUES':
            return state;

        case 'REMOTE_IS_INFINITE_CLUES_ERROR':
            return state;

        case 'REMOTE_ADJUST_TIMER':
            return {
                ...state,
                adjustTimerButtonLoading: false
            };

        case 'REMOTE_ADJUST_TIMER_ERROR':
            return state;
            

        case 'RESET_TIMER_ADJUST':
            return state;

        case 'RESET_TIMER_ADJUST_ERROR':
            return state;
             
        default:
            return state;
    }
}

export default remoteReducer;