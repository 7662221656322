import React, { useState } from 'react'
import { connect } from 'react-redux'


const AdjustTimer = (props) => {

    const { 
        handleAdjustTimer,
        //handleChangeTimerSpeed
    } = props;
    const [timer, setTimer] = useState(1);

    const buttonText = () => {
        if (props.remote.adjustTimerButtonLoading){
            return <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        } else {
            if (timer < 0) return 'Remove time'
            else return 'Add time'
        } 
    }




    return (
        <div id="adjust-timer-box" style={{padding:'5px 0px 20px 0px', borderBottom:'0px solid black'}}>
            {/* <h6 className="sidebar-header-title">Adjust Timer</h6> */}
        
        <div style={{marginBottom:"0px", marginTop:"0px", textAlign:"center"}}>
         <div className="card-body" style={{padding:"0px 0px 0px 0px"}}>
         {/* <div class="marker pos-absolute t-10 l-10">Adjust Timer</div> */}
         {/* <h6 className="card-title">Adjust Timer</h6> */}
         <div style={{display:"flex", flexDirection:"row", marginBottom:"0px"}}>


         <button onClick={() => setTimer(timer - 1)} type="button" className="button-outline-dark" style={{paddingTop:"0px", paddingBottom:"0px"}}>
            <i className="icon ion-md-remove"></i>
        </button>
            <input type="text" className="form-control align-self-center bd-0 focus:outline-none" style={{textAlign:"center", height:"25px", width:'50px', background:"none", border:"none", lineHeight:'0px', backgroundColor:'none'}} value={timer} 
            onChange={(e) => setTimer(e.target.value)}></input>

            
        <button onClick={() => setTimer(timer + 1)} type="button" className="button-outline-dark" style={{paddingTop:"0px", paddingBottom:"0px"}}>
            <i className="icon ion-md-add"></i>
        </button>

        <button onClick={() => handleAdjustTimer(Number(timer))} type="button" className="button-outline-dark w-100 text-xs" style={{marginLeft:"10px", marginTop:"0px"}}>

            {buttonText()}
         </button>

         </div>


         </div>
         


         
        
            
        </div>
        
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return{
        remote: state.remote
    }
}

export default connect(mapStateToProps, null)(AdjustTimer)
