import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";

export const ClueButton = (props) => {
  const {
    clueObj,
    loadedClue,
    handleLoadClueClick,
    puzzlesDone,
    selectedPuzzleObj,
    color,
    isSent,
    userPermissions,
    sortClueStatus,
  } = props;
  const [showControls, setShowControls] = useState();

  const includesId = selectedPuzzleObj ? selectedPuzzleObj.id : 0;

  const style = () => {
    if (isSent) {
      if (loadedClue.id === clueObj.id) {
        return {
          background: "rgb(23, 24, 28)",
          border: "2px solid #0168fa",
          opacity: "0.5",
        };
      } else {
        return {
          border: "2px solid transparent",
          opacity: "0.5",
        };
      }
    } else if (loadedClue.id === clueObj.id) {
      return {
        background: "rgb(23, 24, 28)",
        border: "2px solid #0168fa",
      };
    } else {
      return {
        border: "2px solid transparent",
      };
    }
  };

  const clueObject = {
    ...clueObj,
    color,
  };

  const clueRating = () => {
    if (clueObj.rating) {
      if (clueObj.rating === 1) {
        return (
          <div className="clue-rating-box">
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i className="icon ion-ios-radio-button-off text-gray-600"></i>
            <i className="icon ion-ios-radio-button-off text-gray-600"></i>
            <i className="icon ion-ios-radio-button-off text-gray-600"></i>
            <i className="icon ion-ios-radio-button-off text-gray-600"></i>
          </div>
        );
      } else if (clueObj.rating === 2) {
        return (
          <div className="clue-rating-box">
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i className="icon ion-ios-radio-button-off text-gray-600"></i>
            <i className="icon ion-ios-radio-button-off text-gray-600"></i>
            <i className="icon ion-ios-radio-button-off text-gray-600"></i>
          </div>
        );
      } else if (clueObj.rating === 3) {
        return (
          <div className="clue-rating-box">
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i className="icon ion-ios-radio-button-off text-gray-600"></i>
            <i className="icon ion-ios-radio-button-off text-gray-600"></i>
          </div>
        );
      } else if (clueObj.rating === 4) {
        return (
          <div className="clue-rating-box">
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i className="icon ion-ios-radio-button-off text-gray-600"></i>
            {/* <i className="icon ion-md-star" style={{color: 'rgb(236, 236, 88)'}}></i>
          <i className="icon ion-md-star" style={{color: 'rgb(236, 236, 88)'}}></i>
          <i className="icon ion-md-star" style={{color: 'rgb(236, 236, 88)'}}></i> */}
          </div>
        );
      } else if (clueObj.rating === 5) {
        return (
          <div className="clue-rating-box">
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
          </div>
        );
      }
    }
  };

  return (
    <button
      className="media"
      onClick={() => handleLoadClueClick(clueObject)}
      onMouseOver={() =>
        setShowControls(
          userPermissions.isAdmin || userPermissions.canEditHints ? true : false
        )
      }
      onMouseLeave={() => setShowControls(false)}
      style={{ ...style() }}
    >
      {isSent ? (
        <Tooltip title="Sent" placement="top">
          <i className="icon ion-md-send tx-16 text-gray-400"></i>
        </Tooltip>
      ) : (
        <Tooltip title="Text Hint" placement="top">
          {/* <i
          className="icon ion-ios-text tx-16"
          style={color !== 'transparent' ? { color: color, marginTop:'-2px' } : {marginTop:'-2px'}}
        ></i> */}

          {/* <i
            className="icon ion-ios-flash tx-16"
            style={{ color: "rgb(114 124 128)", marginTop: "-2px" }}
          ></i> */}

          <i
            className="fa-regular fa-message-text text-gray-400"
          ></i>
        </Tooltip>
      )}

      <div className="media-body mg-l-10" style={{ alignItems: "center" }}>
        <h6
          className="mg-b-0 text-gray-50 line-clamp-3"
          style={{
            fontStyle: isSent ? "italic" : "inherit",
          }}
        >
          {clueObj.message}
        </h6>

        <div
        className="flex items-center space-x-2 mt-1"
        >
          {clueObj.rating >= 1 && (
            <div
              className="text-xs"
              style={{ display: "flex", marginRight: "5px" }}
            >
              {clueObj.rating ? clueRating() : null}
            </div>
          )}

          {/* <small className="d-block text-muted" style={{ display: "flex" }}>
            {clueObj.puzzle}
          </small> */}
          <div className="flex items-center">
          {/* <i className="fa-regular fa-message-text text-gray-300 mr-1"></i> */}
          <p className="text-gray-400 text-xs line-clamp-1 mr-3">{clueObj.puzzle}</p>
          </div>
        </div>
        {/* <small className="d-block text-muted" style={{display:'flex'}}>
          {clueObj.rating ? clueRating(): null}
          {clueObj.puzzle}
        </small> */}

        {showControls ? (
          <Tooltip title="Edit" placement="right">
            <i
              className="icon ion-ios-more"
              // style={{fontSize:'18px', height:'16px', float:'right'}}
              style={{
                fontSize: "18px",
                position: "absolute",
                right: "20px",
                transform: "translate(0px, -16px)",
              }}
              href="#editDeleteClueModal"
              onClick={() => handleLoadClueClick(clueObj)}
              data-toggle="modal"
            ></i>
          </Tooltip>
        ) : null}
      </div>
    </button>
  );
};
