import React from 'react'
import { connect } from "react-redux";


const StartButtonNew = (props) => {

    const {timerRunning, videoIsPlaying, startTimer, pauseTimer, videoButtonLoading, gameComplete, gameIsReady } = props;

        const buttonText = () => {
            if (videoButtonLoading){
                return <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            } else {
                return (<span style={{fontSize:'14px', fontWeight:'600'}}>
                    <i className="fa-solid fa-play" 
                    style={{marginRight:"0px", color:'white', fontSize:'18px'}}></i>
                        </span>)
            } 
        }

        const dataUpload = () => {
            if (props.gameMaster.endgame_data_upload === 'loading') {
                return false
            } else {
                return true
            }    
        }

        if (gameComplete){
            return (
    <button disabled type="button" className="btn btn-dark w-100 disabled" style={{marginRight:'10px'}}>
            {buttonText()}
            </button>
            )
        } else if (!dataUpload()){
            return (
    <button disabled type="button" className="btn btn-dark w-100 disabled" style={{marginRight:'10px'}}>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </button>
            )
        
        } else if (timerRunning && !videoIsPlaying){
            return (
                <button disabled onClick={pauseTimer} type="button" className="btn btn-dark w-100" style={{marginRight:'10px'}}>
                    {buttonText()}
                </button>
            )
        } else if (!timerRunning && videoIsPlaying){
            return (
    <button disabled type="button" className="btn btn-dark w-100 disabled" style={{marginRight:'10px'}}>
            {buttonText()}
            </button>
            )
        } else if (timerRunning && videoIsPlaying){
            return (
        <button disabled onClick={pauseTimer} type="button" className="btn btn-dark w-100" style={{marginRight:'10px'}}>
                    {buttonText()}
                </button>
            )
        } else if (!timerRunning && !videoIsPlaying) {
            return (
    <button id="startButton" onClick={startTimer} type="button" className="btn btn-dark w-100" style={{marginRight:'10px'}}>
                    {buttonText()}
                </button>
            )
        }
}

const mapStateToProps = (state) => {
  
    return {
      gameMaster: state.gameMaster,
    };
  };

export default connect(mapStateToProps, null)(StartButtonNew)
