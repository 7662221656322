import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";

import Header from "./layout/Header";
import Dashboard from "./pages/dashboard/Dashboard3";
// import Report from "./components/_analytics/Report";

//Settings
//import Account from "./components/_settings/Account/Account";
// import GameSettings from "./components/_settings/Game/GameSettings";

// Sentry.io
import * as Sentry from "@sentry/react";

class App extends Component {
  state = {
    isLoading: true,
    trial_expired: false,
    subscription_status: null,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.account !== prevProps.account) {
      const { account } = this.props;

      setTimeout(() => {
        if (account && account.length > 0) {
          this.setState({
            isLoading: false,
          });
        }
        if (account && account.length === 0) {
          window.location.replace("/onboarding");
        }
      }, 1000);
    }
  }

  render() {
    const { auth, profile, account, signup_status } = this.props;
    const { isLoading, trial_expired, subscription_status } = this.state;
    if (!auth.uid) return <Redirect to="/login" />;

    if (signup_status === "creating_account") {
      return (
        <div className="content ht-100v pd-0">
          <div
            className="text-center"
            style={{
              left: "50%",
              marginLeft: "-2em",
              position: "absolute",
              top: "40%",
            }}
          >
            <h3>Creating User Account</h3>
            <div
              className="spinner-border"
              style={{ width: "5rem", height: "5rem" }}
            ></div>
          </div>
        </div>
      );
    }

    if (isLoading)
      return (
        <div className="content ht-100v pd-0">
          <div
            className="text-center"
            style={{
              left: "50%",
              marginLeft: "-2em",
              position: "absolute",
              top: "40%",
            }}
          >
            <div
              className="spinner-border"
              style={{ width: "5rem", height: "5rem" }}
            ></div>
          </div>
        </div>
      );

    Sentry.setUser({
      email: auth.email,
      id: auth.uid,
    });

    return (
      <BrowserRouter>
        <div className="min-h-full">
          <Header profile={profile} account={account} />

          <div className="pb-10">
            <main>
              <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                <Switch>
                  <Route
                    exact
                    path="/"
                    component={() => <Dashboard account={account} />}
                  />

                  {/* <Route exact path="/reports" component={() => <Analytics account={account} />}/>
    <Route exact path="/reports/:report_id" component={Report} /> */}

                  {/* <Route exact path="/settings/account" component={() => ( <Account account={account} profile={profile} />)}/>
    <Route exact path="/settings/game/:id" component={GameSettings} /> */}
                </Switch>

                <div className="fixed bottom-2 right-4 font-medium">
                  <p>Version {this.props.app.app_version}</p>
                </div>
              </div>
            </main>
          </div>
        </div>

        <div className="content ht-100v pd-0 hidden">
          <div className="row" style={{ height: "100%", marginRight: "0px" }}>
            <div
              style={{
                width: "100%",
                maxWidth: "1200px",
                padding: "55px 20px 0px",
                margin: "0 auto",
              }}
            >
              <Header profile={profile} account={account} />

              <Switch>
                <Route
                  exact
                  path="/"
                  component={() => <Dashboard account={account} />}
                />

                {/* <Route exact path="/reports" component={() => <Analytics account={account} />}/>
    <Route exact path="/reports/:report_id" component={Report} /> */}

                {/* <Route exact path="/settings/account" component={() => ( <Account account={account} profile={profile} />)}/>
    <Route exact path="/settings/game/:id" component={GameSettings} /> */}
              </Switch>

              <div style={{ position: "fixed", bottom: "0", right: "20px" }}>
                <p>Version {this.props.app.app_version}</p>
              </div>
              {/* <Footer /> */}
            </div>
          </div>
        </div>

        {/* <Drift appId="ipz3tcus5bg8" /> */}
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  const account_id = state.firebase.profile.account_id
    ? state.firebase.profile.account_id
    : null;
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    account: state.firestore.ordered.user_accounts
      ? state.firestore.ordered.user_accounts
      : null,
    uid: state.firebase.auth.uid ? state.firebase.auth.uid : "123",
    account_id: account_id,
    signup_status: state.auth.signup_status,
    app: state.app,
  };
};

export default compose(
  connect(mapStateToProps, null),
  firestoreConnect((props) => [
    {
      collection: "user_accounts",
      doc: props.account_id,
    },
  ])
)(App);
