import React from 'react'

function NoMobile() {
    return (
        <div style={{background:'black', height:'100vh', width:'100%', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>

            <div style={{position:'absolute', top:'10px', left:'10px'}}>
                <img src="/bolt-logo.svg" alt="Logo" width="125px" style={{filter:'invert(100%'}}></img>
            </div>

            <div style={{maxWidth:'800px', color:'white', padding:'20px', textAlign:'center'}}>
                <h2></h2>
                <i className="fa-light fa-mobile-notch" style={{fontSize:'68px', marginBottom:'20px'}}></i>
                <p style={{color:'white', fontSize:'18px'}}>Mobile devices are not supported.</p>
                <p style={{color:'white', fontSize:'16px'}}>To use Bolt, please use a modern desktop browser like <a href="https://www.google.com/chrome/" target="_blank">Google Chrome.</a></p>
            </div>

            {/* <div className="dark">
                <a href="https://www.google.com/chrome/" target="_blank" className="btn btn-purple">Download Google Chrome</a>
            </div> */}
            
            
        </div>
    )
}

export default NoMobile