export const loadStateFromLocalStorage = () => {
    try {
        const serializedState = localStorage.getItem('state')

        if (serializedState === null){
            return;
        }
        return JSON.parse(serializedState)

    } catch (err){
        
        console.log('LoadStateFromLocalStorage Error:', err)
        //return;
    }

}

export const getVideoClueFromLocalStorage = () => {
    try {
        const serializedState = localStorage.getItem('BOLT_videoClue')

        if (serializedState === null){
            return;
        }
        return JSON.parse(serializedState)

    } catch (err){
        
        console.log('LoadStateFromLocalStorage Error:', err)
        //return;
    }

}

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state)
        localStorage.setItem('state', serializedState)

    } catch (err){
        console.log('Save State Local Storage Error:', err)
        //Ignore write errors
    }
}


export const saveAudioState = (key, volume) => {
    try {

        const audioState = JSON.parse(localStorage.getItem('audio'))
    
        var obj = {
            ...audioState,
            [key] : volume
        }
        const serializedState = JSON.stringify(obj)
        localStorage.setItem('audio', serializedState)

    } catch (err){
        console.log('Save Audio State Local Storage Error:', err)
        //Ignore write errors
    }
}

export const loadAudioState = () => {
    try {
        const serializedState = localStorage.getItem('audio')

        if (serializedState === null){
            return localStorage.setItem('audio')
        }
        return JSON.parse(serializedState)

    } catch (err){
        console.log('Load Audio State Local Storage Error:', err)
    }

}