import React from "react";

function VictoryPreview(props) {

const { backgroundColor, backgroundImage, settings, game_data, textColor } = props;

  return (
    <div
      id="lw-bgimage"
      style={{
        width: "570px",
        height: "320px",
        display: "flex",
        justifyContent:'center',
        alignItems: "center",
        backgroundColor: backgroundColor,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundImage:
          backgroundImage &&
          backgroundImage.type &&
          backgroundImage.type.startsWith("image/")
            ? "url(" + backgroundImage.url + ")"
            : null,
        borderRadius: "8px",
        margin: "0 auto",
        position:'relative'
      }}
    >
      {backgroundImage &&
      backgroundImage.type &&
      backgroundImage.type.startsWith("video/") ? (
        <video
          style={{
            minWidth: "100%",
            borderRadius: "8px",
            height:'100%'
          }}
          muted
          preload="auto"
          //ref="vidRef"
          id="lw-bg-video"
          autoPlay={true}
          loop={true}
          src={backgroundImage ? backgroundImage.url : null}
          //type="video/mp4"
        ></video>
      ) : null}


      <div
        style={{
          margin: "0 auto",
          textAlign: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >

        <h1
          style={{
            color: textColor,
            fontSize:
              settings && settings.victory
                ? settings.victory.size * 3.4 + "px"
                : "75px",
            marginBottom: "0px",
            fontFamily: settings && settings.victory ? settings.victory.font : null,
            display: settings && settings.victory && settings.victory.show !== true ? "none" : "block",
          }}
        >

          {settings && settings.victory && settings.victory.text ? settings.victory.text : 'Success!'}

          
        </h1>

        <p
          style={{
            fontFamily: settings && settings.victory ? settings.victory.font : null,
            color: textColor,
            marginBottom: "0",
            fontSize: settings && settings.victory && settings.victory.size_timeRemaining ? settings.victory.size_timeRemaining * 3.4 + "px" : "30px",
            display: settings && settings.victory && settings.victory.show_timeRemaining !== true ? "none" : "block",
          }}
        >
          <b>21:45</b>
        </p>
      </div>
    </div>
  );
}

export default VictoryPreview;
