import React, { useState, useEffect } from "react";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";

import { Style, ProfilePic } from "./Exports";
import { classNames } from "../../../../utils/Utils";

type Props = {
  account: {
    account_email: string;
    admins: any;
    company_size: string;
    created_at: any;
    id: string;
    onboarding: { gm: boolean };
    permissions: any;
    room_usage: number;
    staff: any;
    subscription: {
      customer_id: string;
    };
    title: string;
  };
  profile: any;
  messages: any;
  messageObj: any;
  timer: any;
};

export const Message_Image = (props: Props) => {
  const { messageObj, timer, messages, account, profile } = props;

  const message = messageObj ? messageObj.message : "0";
  const clueOnScreen = timer ? timer.clueOnScreen : "0";

  console.log('game timestapm', messageObj.game_timestamp)

  // const style = () => {
  //   var style: any = {
  //       padding: "10px 20px",
  //       margin: "10px 0px",
  //       alignItems: "center"
  //   };

  //   if (hover) style = { ...style, background: "#2c2c35"}

  //   return style
  // };

  const isClueOnScreen = () => {
    if (messageObj.send_id === clueOnScreen.send_id) return true;
  };

  const [hover, setHover] = useState(false);
  const [loaded, setLoaded] = useState(true);
  // useEffect(()=> {
  //   setTimeout(() => {
  //     setLoaded(true)
  //   }, 700);
  // },[])


  return (
    <div className={classNames(isClueOnScreen() ? "" : null)}>
      {/* {isClueOnScreen() && (
        <div className="text-red-500 flex items-center mb-1 ml-[35px] mt-0">
          <i
            className="fa-light fa-eye w-4"
            style={{ marginRight: "10px", fontSize: "18px" }}
          ></i>
          <p className="text-xs">Visible to players</p>
        </div>
      )} */}

      <div
        key={messageObj.send_id}
        className={classNames(
          "message-feed-item px-4 py-2 duration-150 rounded-sm hover-bg-gray-700/40",
          !loaded ? "opacity-40" : "opacity-100"
        )}
        //style={Style(hover, clueOnScreen, messageObj)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div
          className="w-full"
          style={{
            marginLeft: "15px",
            paddingRight: "20px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "4px",
              }}
            >
              {/* <ProfilePic icon="flash" type="text" /> */}
              <div className="absolute">
                <img src={messageObj.file.url} className="w-[45px] h-[45px]  object-cover rounded mr-3" loading="lazy"></img>
              </div>


              <div className="w-full ml-[60px]">
                <p className="mb-0 whitespace-pre-wrap text-gray-100 line-clamp-1">
                <i className="fa-regular fa-image mr-2 text-gray-100"></i>
                  {messageObj.name}
                </p>

                <div className="flex items-center space-x-2 mt-0.5">
                  <p className="font-medium text-blue-600 mr-2 mb-0 leading-[0px]">
                    {messageObj.game_timestamp.m}:{messageObj.game_timestamp.s}
                  </p>
                  
                  <p className="text-gray-400 text-xs whitespace-nowrap mb-0">
                    {moment(messageObj.timestamp).format("LT")}
                  </p>
                  {/* <span className="text-gray-500">&#183;</span>
                  <span className="text-gray-500 text-xs">
                    Image
                  </span> */}
                  {messageObj.puzzle && (
                    <p className="text-gray-400 mb-0">&#183;</p>
                  )}
                  <p className="text-gray-400 text-xs line-clamp-1 mb-0">
                    {messageObj.puzzle && messageObj.puzzle}
                  </p>
                  {/* {isClueOnScreen() && (
                    <div className="ml-3 px-1 rounded-sm bg-red-600">
                      <p className="font-medium  text-gray-100 mb-0 text-xs">
                        <i className="fa-solid fa-eye w-3 mr-1"></i>
                        PLAYER WINDOW
                      </p>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};