import React from 'react'
import { Link } from "react-router-dom";

import UpdateCard from './modals/UpdateCard'
import InvoiceSettings from './modals/InvoiceSettings'

function AccountBillingMethod(props) {

  const { account } = props;

  const payment_method = account.subscription.payment_method ? account.subscription.payment_method : null;
  const stripe_id = account.subscription.customer_id ? account.subscription.customer_id : null;
    return (
        <div>
            <div class="billing-main-header">
            <div class="div-block-7">
              <h1 class="heading-2">Billing</h1>
            </div>
            <div class="billing-tabs-container">
              <div class="div-block-12">
                <Link to="/account/billing" class="paragraph-7">Overview</Link>
              </div>
              <div class="div-block-12">
                <Link to="/account/billing/history" class="paragraph-8">Billing History</Link>
              </div>
              <div class="div-block-11">
                <Link to="/account/billing/payment">Payment Method</Link>
              </div>
            </div>
          </div>
          <div class="payment-method-container">
           {payment_method && (
              <div class="div-block-15">
              <div class="div-block-16">
                <ion-icon name="card-outline" size="large"></ion-icon>
              </div>
              <div style={{marginLeft:'20px'}}>
                <p class="paragraph-13">Payment Method</p>
                <p class="paragraph-14">{payment_method.brand} ending in {payment_method.last4}</p>
                <p class="paragraph-14">{payment_method.exp_month}/{payment_method.exp_year}</p>
              </div>
              <div class="div-block-20">
              <form
                    method="POST"
                    action="https://us-central1-escaperoomwaiver-v2.cloudfunctions.net/stripeCustomerPortal"
                  >
                    <input
                      name="customer_id"
                      id="customer_id"
                      value={stripe_id}
                      style={{ display: "none" }}
                    />
                    <button
                      type="submit"
                      className="button-white button w-button"
                      style={{border: 'none'}}
                    >
                      Update card
                    </button>
                  </form>

                {/* <a href="#UpdateCardModal" data-toggle="modal" class="button-white button w-button">Update card</a> */}
              </div>
            </div>
           )}
            <div class="div-block-15">
              <div class="div-block-16">
                <ion-icon name="document-outline" size="large"></ion-icon>
              </div>
              <div style={{marginLeft:'20px'}}>
                <p class="paragraph-13">Invoice Settings</p>
                <p class="paragraph-14">Update your company name.</p>
                <p class="paragraph-14">Add an invoice address.</p>
              </div>
              <div class="div-block-20">
              <form
                    method="POST"
                    action="https://us-central1-escaperoomwaiver-v2.cloudfunctions.net/stripeCustomerPortal"
                  >
                    <input
                      name="customer_id"
                      id="customer_id"
                      value={stripe_id}
                      style={{ display: "none" }}
                    />
                    <button
                      type="submit"
                      className="button-white button w-button"
                      style={{border: 'none'}}
                    >
                      Edit invoice information
                    </button>
                  </form>

                {/* <a href="#InvoiceSettingsModal" data-toggle="modal" class="button-white button w-button">Edit invoice information</a> */}
              </div>
            </div>
          </div>

          <UpdateCard />
          <InvoiceSettings />
        </div>
    )
}

export default AccountBillingMethod
