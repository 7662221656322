import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Tooltip } from "@mui/material";


import { classNames, formatTime } from "../../../utils/Utils";

const History = (props) => {
  const { history_games } = props;

  function groupByDate(history_games) {
    return history_games.reduce((acc, game) => {
      const date = moment(game.created_at.seconds * 1000).format('dddd') + ", " + moment(game.created_at.seconds * 1000).format("LL");
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(game);
      return acc;
    }, {});
  }


  return (
    <div className="p-12 items-center justify-center absolute bottom-[40px] top-[80px] right-[300px] left-0 z-50">
      <div className="overflow-auto h-full w-full">
      <table className="w-100">
        <thead className="mb-4">
          <tr className="text-gray-100 border-b-[#374151] border-b-2">
            <th>#</th>
            <th>Status</th>
            <th>Game</th>
            <th>Duration</th>
            <th>Time</th>
            
            <th>Objectives</th>
            <th>Clues</th>
            <th>User</th>
          </tr>
        </thead>

        <tbody>
          {history_games && Object.keys(groupByDate(history_games)).map((date, index) => (
            <React.Fragment key={index}>
              <tr className="text-gray-100 h-12">
                <td colSpan={7} className="font-bold pt-4">
                  {date}
                </td>
              </tr>
              {groupByDate(history_games)[date].map((item, index) => (
                 <tr
                 key={index}
                 className="even:bg-[#2c2c35] odd:bg-transparent text-gray-100 h-8"
               >
                 <td className="pl-2">{groupByDate(history_games)[date].length - (index)}</td>
                 <td
                   className={classNames(
                     "font-bold",
                     item.didWinGame ? "text-green-500" : "text-red-500"
                   )}
                 >
                    <span className="mr-2">{item.didWinGame ? "Win" : "Loss"}</span>
                   {item.timeRemaining.m}:{item.timeRemaining.s}
                 </td>
                 <td>{item.game.name}</td>
                 <td>{formatTime((item.ended_at.seconds) - (item.started_at.seconds))}</td>
                    <td>{moment(item.started_at.seconds * 1000).format("LT")} - {moment(item.ended_at.seconds * 1000).format("LT")}</td>
                    
                 <td>{item.puzzles.length}</td>
                 <td>{item.totalMessagesSent}</td>

                 <td>
                   <Tooltip placement="top" title={item.user.fullName}>
                    <div className="w-6 h-6 rounded-full flex items-center justify-center bg-gray-700 cursor-default">
                        <p className="font-bold text-xs">{item.user.fullName.split(' ').map(word => word.charAt(0).replace(/[^a-zA-Z]/g, '')).join('')}</p>
                        </div>
                   </Tooltip>
                </td>
                 
               </tr>
              ))}
            </React.Fragment>
          ))}


        </tbody>
      </table>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    history_games: state.firestore.ordered.history,
  };
};

export default compose(
  connect(mapStateToProps, null),
  firestoreConnect((props) => [
    {
      collection: "user_accounts",
      doc: props.account_id,
      subcollections: [
        {
          collection: "gm_reports",
          where: ["game.id", "==", props.game_id],
          orderBy: ["created_at", "desc"],
          limit: 25,
        },
      ],
      storeAs: "history",
    },
  ])
)(History);
