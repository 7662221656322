import React, { useState, useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import FadeIn from "react-fade-in";

import { Style, ProfilePic } from "./Exports";
import { classNames } from "../../../../utils/Utils";
import {
  updateVideoClueOnScreen,
  updateVideoCluePlayingControl,
} from "../../../../redux/actions/timerActions";

type Props = {
  account: {
    account_email: string;
    admins: any;
    company_size: string;
    created_at: any;
    id: string;
    onboarding: { gm: boolean };
    permissions: any;
    room_usage: number;
    staff: any;
    subscription: {
      customer_id: string;
    };
    title: string;
  };
  profile: any;
  messages: any;
  messageObj: any;
  timer: any;
  duration: number;
  currentTime: number;
  videoClue_id: string;
  handleScreenClear: () => void;
  updateVideoCluePlayingControl: (bool: boolean) => void;
  updateVideoClueOnScreen: (obj?: any) => void;
};

const Message_Video = (props: Props) => {
  const { messageObj, timer, messages, account, profile } = props;

  const message = messageObj ? messageObj.message : "0";
  const clueOnScreen = timer ? timer.clueOnScreen : "0";

  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  console.log("CURRENT TIME", props.currentTime);

  useEffect(() => {
    if (props.currentTime !== null) {
      setCurrentTime(props.currentTime);
    } else {
      setCurrentTime(0);
    }

    if (props.duration !== null) {
      setDuration(props.duration);
    } else {
      setDuration(0);
    }

    if (props.currentTime === props.duration) {
      setCurrentTime(0);
      setDuration(0);
    }
  }, [props.currentTime]);

  const isClueOnScreen = () => {
    if (message === clueOnScreen) return true;
  };

  const [hover, setHover] = useState(false);
  const [loaded, setLoaded] = useState(true);
  // useEffect(()=> {
  //   setTimeout(() => {
  //     setLoaded(true)
  //   }, 700);
  // },[])

  const addDefaultSrc = (e: any) => {
    e.target.src = "/bolt_white_on_black.png";
  };

  const formatTime = (timeInSeconds: any) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const progressBarWidth = (currentTime / duration) * 100;

  return (
    <div className={classNames(isClueOnScreen() ? "" : null, "duration-150")}>
      {/* {isClueOnScreen() && (
        <div className="text-red-500 flex items-center mb-1 ml-[35px] mt-0">
          <i
            className="fa-light fa-eye w-4"
            style={{ marginRight: "10px", fontSize: "18px" }}
          ></i>
          <p className="text-xs">Visible to players</p>
        </div>
      )} */}

      <div
        key={messageObj.send_id}
        className={classNames(
          "message-feed-item px-4 py-2 duration-150 hover:bg-gray-700/40 group",
          !loaded ? "opacity-40" : "opacity-100"
        )}
        //style={Style(hover, clueOnScreen, messageObj)}
        // onMouseEnter={() => setHover(true)}
        // onMouseLeave={() => setHover(false)}
      >
        <div
          className="w-full"
          style={{
            marginLeft: "15px",
            paddingRight: "20px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div className="w-full">
            <div
              className="w-full"
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "4px",
              }}
            >
              <div className="absolute flex items-center justify-center mr-[15px] w-[45px] h-[45px] ">
                <button
                  className="absolute flex items-center justify-center focus:outline-none"
                  disabled={currentTime > 0 ? false : true}
                  onClick={() => {
                    // localStorage.setItem("BOLT_videoClue", null)
                    props.updateVideoClueOnScreen();
                    setCurrentTime(0);
                    setDuration(0);
                  }}
                >
                  <img
                    src={messageObj.thumbnail}
                    className={classNames(
                      "w-[45px] h-[45px]  object-cover rounded",
                      currentTime > 0 ? "group-hover:brightness-[.25]" : null
                    )}
                    loading="lazy"
                  ></img>

                  <svg
                    className={classNames(
                      "fill-white/50 group-hover:fill-white  absolute z-10 h-[15px] duration-100",
                      currentTime > 0 ? "hidden group-hover:inline" : "hidden"
                    )}
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 384 512"
                  >
                    <path d="M0 128C0 92.7 28.7 64 64 64H320c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" />
                  </svg>
                </button>
              </div>

              <div className="w-full ml-[60px]">
                <p className="mb-0 whitespace-pre-wrap text-gray-100 line-clamp-1">
                  <i className="fa-regular fa-clapperboard-play mr-2"></i>
                  {messageObj.name}
                </p>

                <div
                  className={classNames(
                    "w-full my-1 mt-1.5 flex items-center space-x-2",
                    currentTime > 0 && messageObj.send_id === props.videoClue_id
                      ? null
                      : "hidden"
                  )}
                >
                  <span className="font-medium text-gray-400 text-xs">
                    {formatTime(currentTime)}
                  </span>
                  <div className="w-full relative">
                    <div className="h-1 bg-gray-600 rounded">
                      <div
                        className="h-full bg-blue-500 transition-width rounded"
                        style={{ width: `${progressBarWidth}%` }}
                      ></div>
                    </div>
                    <div
                      className="absolute top-0 left-0 transform -translate-y-[2px] bg-blue-400 w-2 h-2 rounded-full"
                      style={{ left: `${progressBarWidth}%` }}
                    ></div>
                  </div>

                  <span className="text-gray-400 text-xs">
                    {formatTime(duration)}
                  </span>
                </div>

                <div className="mt-0.5">
                  <div
                    className={classNames(
                      "flex items-center space-x-2",
                      currentTime > 0 &&
                        messageObj.send_id === props.videoClue_id
                        ? "hidden"
                        : null
                    )}
                  >
                    <p className="font-medium text-blue-600 mb-0 mr-2 leading-[0px]">
                      {messageObj.game_timestamp.m}:
                      {messageObj.game_timestamp.s}
                    </p>

                    <p className="text-gray-400 text-xs mb-0 mt-[1px] whitespace-nowrap">
                      {moment(messageObj.timestamp).format("LT")}
                    </p>
                    {/* <p className="text-gray-500 mb-0 mt-[1px]">&#183;</p>
                  <p className="text-gray-500 text-xs mb-0 mt-[1px]">
                    Video
                  </p> */}
                    {messageObj.puzzle && (
                      <p className="text-gray-400 mb-0 mt-[1px]">&#183;</p>
                    )}
                    <p className="text-gray-400 text-xs mb-0 mt-[1px] line-clamp-1">
                      {messageObj.puzzle && messageObj.puzzle}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateVideoCluePlayingControl: (boolean: boolean) =>
      dispatch(updateVideoCluePlayingControl(boolean)),
    updateVideoClueOnScreen: (clue?: any) =>
      dispatch(updateVideoClueOnScreen(clue)),
  };
};

export default connect(null, mapDispatchToProps)(Message_Video);
