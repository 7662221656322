import React, { Fragment, useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { signOut } from "../redux/actions/authActions";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { classNames, extractFirstCharacters } from "../utils/Utils";
import moment from "moment";

type Props = {
  profile: any;
  account: any;
  auth: any;
  signOut: () => void;
};


const navigation = [{ name: "Dashboard", href: "/", current: true }];


function Header({ profile, account, auth, signOut }: Props) {
  const firstNameLetter = profile.firstName
    ? profile.firstName.charAt(0)
    : null;
  const lastNameLetter = profile.lastName ? profile.lastName.charAt(0) : null;
  const account_title = account ? account.title || account.company_title : null;

  const firstCharacters = account_title ? extractFirstCharacters(account_title) : null;

  // Updated 8/7/23
  const [trial, setTrial] = useState({
    trial_start:
      account && account.subscription && account.subscription.trial_start
        ? account.subscription.trial_start
        : null,
    trial_end:
      account && account.subscription && account.subscription.trial_end
        ? account.subscription.trial_end
        : null,
  });

  useEffect(() => {
    if (account) {
      setTrial({
        trial_start:
          account.subscription && account.subscription.trial_start
            ? account.subscription.trial_start
            : null,
        trial_end:
          account.subscription && account.subscription.trial_end
            ? account.subscription.trial_end
            : null,
      });
    }
  }, [account]);

  const isAdminUser = () => {
    const uid = auth.uid;
    const user_account = account ? account[0] : [];
    const isAdminUser = account ? user_account.admins.includes(uid) : false;
    return isAdminUser;
  };

  const isSubscribed = () => {
    const user_account = account ? account[0] : [];
    const status = user_account ? user_account.subscription.status : null;
    const product_id = user_account
      ? user_account.subscription.product_id
      : null;
    const isBeta = user_account ? user_account.isBeta : null;
    const pro_plan = "prod_IIlkUib4nS3RBH";
    const basic_plan = "prod_IIkAc15oZKrJPG";

    if (isBeta) return "Beta";
    if (status === "active" && product_id === pro_plan) return "Pro";
    if (status === "active" && product_id === basic_plan) return "Basic";
    if (status === "cancelled") return "Canceled";
    if (!status) return "Trial";
  };
  return (
    <div>

{trial.trial_end && (
    <div className="flex items-center gap-x-6 bg-blue-600 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
    <p className="text-sm leading-6 text-white">
      <p>
      <i className="fa-solid fa-sparkles mr-2"></i>
        <strong className="font-semibold">Trial ends in {moment(trial.trial_end * 1000).fromNow()}</strong>
        {/* <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
          <circle cx={1} cy={1} r={1} />
        </svg> */}
      </p>
    </p>
    <a
        href="/account"
        className="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
      >
        My Account <span aria-hidden="true">&rarr;</span>
      </a>
    {/* <div className="flex flex-1 justify-end">
      <button type="button" className="-m-3 p-3 focus-visible:outline-offset-[-4px]">
        <span className="sr-only">Dismiss</span>
        <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </button>
    </div> */}
  </div>
)}


      <Disclosure as="nav" className="border-b border-gray-200 bg-white">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 justify-between">
                <div className="flex">
                  <div className="flex flex-shrink-0 items-center mr-4">
                    <img
                      className="block h-5 w-auto lg:hidden"
                      src="/ers_light_v1.png"
                      alt="Escape Room Studio"
                    />
                    <img
                      className="hidden h-5 w-auto lg:block"
                      src="/ers_light_v1.png"
                      alt="Escape Room Studio"
                    />
                  </div>
                  <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current
                            ? "border-black text-black hover:text-black"
                            : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                          "inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:items-center">
                  {/* <button
                      type="button"
                      className="relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button> */}

                    <div className="flex items-center space-x-4">
                    <button onClick={() => {
                        // @ts-ignore
                        drift.api.toggleChat()
                    }} className="text-gray-700 hover:text-black font-medium focus:outline-none">
                        <p>Help</p>
                    </button>

                    <a href="/account/billing" className="text-gray-700 hover:text-black font-medium focus:outline-none">
                        <p>Account Settings</p>
                    </a>
                    </div>

                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none ">
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Open user menu</span>
                        <div className="h-10 w-10 rounded-full bg-gray-700 flex items-center justify-center">
                          {firstCharacters && (
                            <p className="text-white">
                            {firstCharacters}
                          </p>
                          )}
                        </div>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >

                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                          <p
                            className={classNames(
                              "block px-4 py-2 text-sm text-black font-medium line-clamp-1"
                            )}
                          >
                            {account_title}
                          </p>
                        </Menu.Item>
                        <Menu.Item>
                          <a
                            href="/account/billing"
                            className={classNames(
                              "block px-4 py-2 text-sm text-gray-700 hover:text-blue-500"
                            )}
                          >
                            Account & Billing
                          </a>
                        </Menu.Item>
                        <Menu.Item>
                          <button
                            onClick={() => signOut()}
                            className={classNames(
                              "block px-4 py-2 text-sm text-gray-700 hover:text-blue-500"
                            )}
                          >
                            Sign out
                          </button>
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
                <div className="-mr-2 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 pb-3 pt-2">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "border-indigo-500 bg-indigo-50 text-indigo-700"
                        : "border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800",
                      "block border-l-4 py-2 pl-3 pr-4 text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
              <div className="border-t border-gray-200 pb-3 pt-4">
                <div className="flex items-center px-4">
                  <div className="flex-shrink-0">
                    {/* <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" /> */}
                    <div className="h-10 w-10 rounded-full bg-gray-700 flex items-center justify-center">
                    {firstCharacters && (
                            <p className="text-white">
                            {firstCharacters}
                          </p>
                          )}
                    </div>
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-gray-800">
                    {account_title && (
                         <p className="text-black font-medium">
                         {account_title}
                       </p>
                     )}
                    </div>
                    <div className="text-sm font-medium text-gray-500">
                      {profile.email}
                    </div>
                  </div>
                  {/* <button
                    type="button"
                    className="relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button> */}
                </div>
                <div className="mt-3 space-y-1">
                    <Disclosure.Button
                      as="a"
                      href="/account"
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                    >
                      Account & Billing
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="button"
                      onClick={()=> signOut()}
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                    >
                      Sign out
                    </Disclosure.Button>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    authError: state.auth.authError,
    profile: state.firebase.profile,
    account: state.firestore.ordered.user_accounts[0],
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
