import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { updateVideoClue } from "../../../redux/actions/gmActions";

type Props = {
  loadedClue: {
    name: string;
    file: {
      url: string;
      name: string;
      size: number;
    };
    puzzle_doc_id: string;
    id: string;
    thumbnail: string;
    stop_timer: boolean;
    trigger_alert : boolean;
  };
  gm_id: string;
  updateVideoClue: (
    doc_id: string,
    puzzle_id: string,
    clue_obj: any,
    update: any,
    type: string
  ) => void;
};

const EditDeleteVideoClueModal = (props: Props) => {
  const { loadedClue, gm_id } = props;

  const [audio_id, setAudioId] = useState(0);

  const [update, setUpdate] = useState({
    name: "",
    file: {
      url: "",
      name: "",
      size: 0,
    },
    thumbnail: "",
    stop_timer: false,
    trigger_alert : false
  });

  useEffect(() => {
    setUpdate({
      name: loadedClue.name,
      file: loadedClue.file,
      thumbnail: loadedClue.thumbnail,
      stop_timer: loadedClue.stop_timer,
      trigger_alert : loadedClue.trigger_alert
    });
  }, [loadedClue]);

  const updateData = (e: any) => {
    console.log(e);
    setUpdate({
      ...update,
      [e.target.id]: e.target.value,
    });
  };

  const handleUpdateClue = () => {
    console.log("Update image!");
    props.updateVideoClue(
      gm_id,
      loadedClue.puzzle_doc_id,
      loadedClue,
      update,
      "update"
    );
    setUpdate({
      name: "",
      file: {
        url: "",
        name: "",
        size: 0,
      },
      thumbnail: "",
      stop_timer: false,
      trigger_alert : false
    });
  };

  const handleDelete = () => {
    props.updateVideoClue(
      gm_id,
      loadedClue.puzzle_doc_id,
      loadedClue,
      update,
      "delete"
    );
    setUpdate({
      name: "",
      file: {
        url: "",
        name: "",
        size: 0,
      },
      thumbnail: "",
      stop_timer: false,
      trigger_alert : false
    });
  };

  //   console.log("loaded clue", loadedClue);
  return (
    <div
      className="modal effect-scale"
      id="editDeleteVideoClueModal"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered wd-sm-650"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body">
            <p className="text-lg mb-1 text-white font-medium">
              Video settings
            </p>

            <div className="flex flex-col mb-3">
              <label className="label-dark">Name</label>
              {/* <input onChange={(e) => updateData("message", e.target.value)} type="text" className="form-control" value={update.message}></input> */}
              <input
                onChange={(e) => updateData(e)}
                type="text"
                id="name"
                className="input-dark"
                value={update.name}
              ></input>
            </div>

            <div className="flex items-center space-x-4 mb-3">
              <div className="flex items-center">
                <input
                  id="trigger_alert"
                  type="checkbox"
                  onChange={(e) =>
                    setUpdate({
                      ...update,
                      trigger_alert: e.target.checked,
                    })
                  }
                  checked={update.trigger_alert}
                  className="w-4 h-4 text-blue-600 rounded mr-2 focus:ring-0  bg-gray-700 border-gray-600"
                />
                <label className="label-dark mb-0 pb-0">
                  Trigger alert tone
                </label>
              </div>

              {/* <div className="flex items-center">
                <input
                  id="stop_timer"
                  type="checkbox"
                  onChange={(e) =>
                    setUpdate({
                      ...update,
                      stop_timer: e.target.checked,
                    })
                  }
                  checked={update.stop_timer}
                  className="w-4 h-4 text-blue-600 rounded mr-2 focus:ring-0  bg-gray-700 border-gray-600"
                />
                <label className="label-dark mb-0 pb-0">
                  Stop timer on play
                </label>
              </div> */}
            </div>
          </div>
          <div className="modal-footer-dark">
            <div className="flex justify-between">
              <button
                type="button"
                className="button-outline-dark"
                data-dismiss="modal"
                style={{ marginRight: "auto" }}
                onClick={() => handleDelete()}
              >
                Delete video
              </button>
              <div className="flex">
                <button
                  type="button"
                  className="button-outline-dark mr-2"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={() => handleUpdateClue()}
                  className="button-blue-dark"
                  data-dismiss="modal"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateVideoClue: (
      doc_id: string,
      puzzle_id: string,
      clue_obj: string,
      update: any,
      type: string
    ) => dispatch(updateVideoClue(doc_id, puzzle_id, clue_obj, update, type)),
  };
};

export default connect(null, mapDispatchToProps)(EditDeleteVideoClueModal);
