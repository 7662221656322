import React, {useEffect} from 'react';
import { connect } from "react-redux";

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


function SnackBar(props) {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
      if (props.game_save_success === 'save_success'){
        setOpen(true);

        setTimeout(() => {
            handleClose()
        }, 3000);
      }
  }, [props.game_save_success])


  useEffect(() => {
    if (props.lw_save === 'save_success'){
        setOpen(true);

        setTimeout(() => {
            handleClose()
        }, 3000);
    }
}, [props.lw_save])


const handleClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }

  setOpen(false);
};

const action = (
  <React.Fragment>
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  </React.Fragment>
);

 

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={props.message}
        action={action}
      />
    </div>
  );
}





const mapStateToProps = (state, ownProps) => {
    
    return {
      upload_progress: state.settings.upload_audio_progress,
      lw_settings: state.firestore.ordered.livewindow_settings ? state.firestore.ordered.livewindow_settings[0] : null,
      lw_save: state.settings.livewindow_save_success,
      game_save_success: state.settings.game_save_success
    };
  };

  export default connect(mapStateToProps)(SnackBar)