import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
import Slider from "@mui/material/Slider";
import { SketchPicker } from "react-color";
import uuid from "react-uuid";
import html2canvas from "html2canvas";

import { get, set, createStore, del } from "idb-keyval";
import {
  gameId,
  addToDB,
  deleteAsset,
  readBlob,
} from "../Functions/SettingsFunctions";

import TimerPreview from "./LiveScreens/TimerPreview";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});

function valuetext(value) {
  return `${value}°C`;
}

const marks = [
  {
    value: 10,
    label: "S",
  },
  {
    value: 16,
    label: "M",
  },
  {
    value: 22,
    label: "L",
  },
  {
    value: 32,
    label: "XL",
  },
];

const TimerSettings = (props) => {
  const classes = useStyles();
  const { game_data, lw_settings, game_id, game_assets, upload_progress } =
    props;

  gameId(game_id);

  const [timerData, setTimerData] = useState(game_data);
  useEffect(() => {
    setTimerData(game_data.timer);
  }, [game_data]);

  const [settings, setSettings] = useState(lw_settings);
  useEffect(() => {
    setSettings(lw_settings);
    setTextColor(
      lw_settings && lw_settings.timer ? lw_settings.timer.color : "#fff"
    );
    setBackgroundColor(
      lw_settings && lw_settings.timer ? lw_settings.timer.bg_color : "#000"
    );


  }, [lw_settings]);

  const changeSettings = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (e.target.id === "timer") {
      const time = {
        m: String(e.target.value / 60),
        s: "00",
      };

      const newData = {
        ...timerData,
        seconds: e.target.value,
        time,
        last_updated: new Date(),
      };
      

      setTimerData({ ...timerData, newData });
      props.saveGameSettings(game_data.id, "timer", newData);
    } else if (name === "show_timer" || name === "show_timeRemaining") {
      
      setTimerData({ ...timerData, [e.target.name]: e.target.checked });
      const newData = {
        ...timerData,
        last_updated: new Date(),
        [e.target.name]: e.target.checked,
      };
      props.saveGameSettings(game_data.id, "timer", newData);
    } else {
      
      setTimerData({ ...timerData, [e.target.name]: e.target.value });
      const newData = {
        ...timerData,
        last_updated: new Date(),
        [e.target.name]: e.target.value,
      };
      props.saveGameSettings(game_data.id, "timer", newData);
    }
  };

  const changeTimerLiveSettings = (e) => {
    if (e.target.name === "show_timer") {
      setSettings({
        ...settings,
        timer: { ...settings.timer, show: e.target.checked },
      });
      const newData = {
        ...settings,
        timer: {
          ...settings.timer,
          last_updated: new Date(),
          show: e.target.checked,
        },
      };

      props.saveLiveWindowSettings(game_id, newData);
    } else if (e.target.name === "show_timeRemaining") {
      setSettings({
        ...settings,
        timer: { ...settings.timer, show_timeRemaining: e.target.checked },
      });
      const newData = {
        ...settings,
        timer: {
          ...settings.timer,
          last_updated: new Date(),
          show_timeRemaining: e.target.checked,
        },
      };

      props.saveLiveWindowSettings(game_id, newData);
    } else if (e.target.name === "show_hintIcons") {
      setSettings({
        ...settings,
        timer: { ...settings.timer, show_hintIcons: e.target.checked },
      });
      const newData = {
        ...settings,
        timer: {
          ...settings.timer,
          last_updated: new Date(),
          show_hintIcons: e.target.checked,
        },
      };

      props.saveLiveWindowSettings(game_id, newData);
    } else {
      setSettings({
        ...settings,
        timer: { ...settings.timer, [e.target.name]: e.target.value },
      });
      const newData = {
        ...settings,
        timer: {
          ...settings.timer,
          last_updated: new Date(),
          [e.target.name]: e.target.value,
        },
      };

      props.saveLiveWindowSettings(game_id, newData);
    }
  };

  // Edit Text Size
  const [sizeValue, setSizeValue] = useState();
  const handleChange = (event, newValue) => {
    setSizeValue(newValue);

    if (sizeValue !== newValue) {
     
      setTimeout(() => {
        setSettings({
          ...settings,
          timer: { ...settings.timer, size: newValue },
        });
        const newData = {
          ...settings,
          timer: {
            ...settings.timer,
            last_updated: new Date(),
            size: newValue,
          },
        };

        props.saveLiveWindowSettings(game_id, newData);
      }, 500);
    }
  };

  // Edit Text Color
  const [editTextColor, setEditTextColor] = useState(false);
  const [textColor, setTextColor] = useState("#fff");
  const handleChangeTextColor = (color) => {
    setEditTextColor(!editTextColor);

    if (textColor !== settings.timer.color) {
      setSettings({
        ...settings,
        timer: { ...settings.timer, color: textColor },
      });
      const newData = {
        ...settings,
        timer: {
          ...settings.timer,
          last_updated: new Date(),
          color: textColor,
        },
      };

      props.saveLiveWindowSettings(game_id, newData);
    }
  };

  // Edit Background Color
  const [editBackgroundColor, setEditBackgroundColor] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("#fff");
  const handleChangeBackgroundColor = (color) => {
    setEditBackgroundColor(!editBackgroundColor);

    if (backgroundColor !== settings.timer.bg_color) {
      setSettings({
        ...settings,
        timer: { ...settings.timer, bg_color: backgroundColor },
      });
      const newData = {
        ...settings,
        timer: {
          ...settings.timer,
          last_updated: new Date(),
          bg_color: backgroundColor,
        },
      };

      props.saveLiveWindowSettings(game_id, newData);
    }
  };

  // Edit Background Image
  const [editBackgroundImage, setEditBackgroundImage] = useState(false);
  const [editVideoBrief, setEditVideoBrief] = useState(false);

  // Custom Icon
  const [customIcon, setCustomIcon] = useState()

  // IndexDB Asset Items
  const [backgroundImage, setBackgroundImage] = useState("");

  const [backgroundOpacity, setBackgroundOpacity] = useState(100);
  const handleChangeBGOpacity = (event, newValue) => {
    setBackgroundOpacity(newValue);
  };


  useEffect(() => {
    console.log('ASSETS', game_assets)
    if (game_assets && game_assets.background_image) {
      console.log('BG IMAGE', game_assets.background_image)
      setBackgroundImage(game_assets.background_image);
    }

    if (game_assets && game_assets.custom_icon) {
      setCustomIcon(game_assets.custom_icon);
    }

    return () => {
      setBackgroundImage();
      setCustomIcon();
    };
  }, [game_assets]);



  useEffect(() => {
   
    const assetsStore = createStore(game_id, "assets");

    // Get Background Image
    get("background_image", assetsStore).then((val) => {
      if (val) {
        const blob = readBlob(val);
        var url = URL.createObjectURL(blob);
        setBackgroundImage({ ...val, url });
      } else {
        setBackgroundImage()
      }
    });

    // Get Custom Icon
    // get("custom_icon", assetsStore).then((val) => {
    //   if (val) {
    //     const blob = readBlob(val);
    //     var url = URL.createObjectURL(blob);
    //     setCustomIcon({ ...val, url });
    //   } else {
    //     setCustomIcon()
    //   }
    // });
  }, [game_assets]);

  const uploadBackground = (file) => {
    const id = uuid();

    if (file.size < 1073741824) {
    // Create Blob
    addToDB(file, "background_image", setBackgroundImage, backgroundImage, id);

    // Upload to Database
    props.handleUploadFile(file, "background_image", id);

    } else {
      setBackgroundImage({error: 'File too large.'})
    }
  };

  const deleteBackground = () => {
    // Delete from database
    props.handleDeleteFile("background_image", backgroundImage);
    
    // Delete blob
    deleteAsset("background_image", backgroundImage, setBackgroundImage);


  };


  const [thumbnail, setThumbnail] = useState()
  const generateThumbnail = async () => {
    const timerPreview = document.getElementById('lw-bgimage');
    const canvas = await html2canvas(timerPreview, {
      scale: 1,
      width: 570, 
      height: 320,
      windowWidth: 570,
      windowHeight: 320,
      scrollY: 0,
      scrollX: 0,
      useCORS: true,
      logging: true
    });
    const image = canvas.toDataURL("image/jpeg", 1);



    const response = await fetch(image);
    const blob = await response.blob(); 
    const file = new File(
      [blob],
      "thumbnail.jpg",
      {
        type: "image/jpeg",
        lastModified: new Date()
      }
    );


      setThumbnail(file)
    // Now you can use the img variable as the source for an image element
  }

  console.log('thumbnail', thumbnail)

  return (
    <div style={{ marginTop: "20px", marginBottom: "50px" }}>
      {/* <h5 className="mg-b-20 tx-18"><b>Timer</b></h5> */}

      {thumbnail && (
        <div style={{ marginBottom: "20px" }}>
          <img
            src={URL.createObjectURL(thumbnail)}
            style={{ width: "100%" }}
          />
        </div>
      )
      }

      <form style={{ width: "100%" }}>
        {/* <label htmlFor="inputEmail3" className="col-form-label tx-18 mg-b-20">
            <b>Timer Settings</b>
          </label>


        <div className="form-group row pd-b-20">
          <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">
            <b>
              Time Limit
            </b>
          </label>
          <div className="col-sm-9">
            <select
              id="timer"
              name="timer"
              onChange={(e) => changeSettings(e)}
              className="form-control"
              defaultValue={game_data.timer.seconds}
            >
              <option value="7200">120 min</option>  
              <option value="5400">90 minutes</option>
              <option value="4500">75 minutes</option>
              <option value="4200">70 minutes</option>
              <option value="3600">60 minutes</option>
              <option value="2700">45 minutes</option>
              <option value="1800">30 minutes</option>
            </select>
          </div>
        </div> */}

        <div className="form-group row">
          <div className="col-sm-3">
            {/* <label htmlFor="inputEmail3" className="col-form-label tx-18">
            <b>Screen Appearance</b>
            <span className="badge badge-warning mg-l-10 tx-13"><b>BETA</b></span>
          </label>
          <p className='tx-13 mg-b-20'>Customize the look and feel of the timer screen.</p> */}

            <div className="custom-control custom-switch pd-b-10 mg-b-10 bd-b">
              <input
                name="show_timer"
                onInput={(e) => changeTimerLiveSettings(e)}
                defaultChecked={
                  settings && settings.timer ? settings.timer.show : null
                }
                type="checkbox"
                className="custom-control-input"
                id="customSwitch1"
              />
              <label className="custom-control-label" htmlFor="customSwitch1">
                <b>Show timer</b>
              </label>
            </div>

            <div className="custom-control custom-switch pd-b-10 mg-b-10 bd-b">
              <input
                name="show_timeRemaining"
                onInput={(e) => changeTimerLiveSettings(e)}
                defaultChecked={
                  settings && settings.timer
                    ? settings.timer.show_timeRemaining
                    : null
                }
                type="checkbox"
                className="custom-control-input"
                id="customSwitch2"
              />
              <label className="custom-control-label" htmlFor="customSwitch2">
                <b>Show time remaining label</b>
              </label>
            </div>

            <div className="custom-control custom-switch pd-b-10 mg-b-10 bd-b">
              <input
                name="show_hintIcons"
                onInput={(e) => changeTimerLiveSettings(e)}
                defaultChecked={
                  settings && settings.timer
                    ? settings.timer.show_hintIcons
                    : null
                }
                type="checkbox"
                className="custom-control-input"
                id="customSwitch3"
              />
              <label className="custom-control-label" htmlFor="customSwitch3">
                <b>Show clue icons</b>
              </label>
            </div>

            <div className="pd-b-10">
              <p className="mg-b-5">
                <b>Timer Color</b>
              </p>
              <div
                className="color-picker-swatch"
                onClick={() => setEditTextColor(!editTextColor)}
              >
                <div
                  className="color-picker-bubble"
                  style={{ background: textColor }}
                />
                <p className="mg-b-0">{textColor}</p>
              </div>

              {editTextColor ? (
                <div style={{ position: "absolute", zIndex: "2", top: "50px" }}>
                  <div
                    style={{
                      position: "fixed",
                      top: "50px",
                      right: "0px",
                      bottom: "0px",
                      left: "0px",
                    }}
                    onClick={() => handleChangeTextColor()}
                  />
                  <SketchPicker
                    color={textColor}
                    onChange={(e) => setTextColor(e.hex)}
                    onChangeComplete={(e) => setTextColor(e.hex)}
                    disableAlpha={false}
                    presetColors={['#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF', 'transparent']}
                  />
                </div>
              ) : null}
            </div>

            <div className="pd-t-10 pd-b-10 mg-t-10 bd-t">
              <p className="mg-b-5">
                <b>Timer Font</b>
              </p>
              <select
                name="font"
                onChange={(e) => changeTimerLiveSettings(e)}
                className="form-control"
                value={settings && settings.timer ? settings.timer.font : ''}
              >
                <option value="System">System (Default)</option>
                <option style={{ fontFamily: "Arial" }} value="Arial">
                  Arial
                </option>
                <option
                  style={{ fontFamily: "Arial Black" }}
                  value="Arial Black"
                >
                  Arial Black
                </option>
                <option
                  style={{ fontFamily: "Comic Sans MS" }}
                  value="Comic Sans MS"
                >
                  Comic Sans
                </option>
                <option
                  style={{ fontFamily: "Courier New" }}
                  value="Courier New"
                >
                  Courier New
                </option>
                <option style={{ fontFamily: "Georgia" }} value="Georgia">
                  Georgia
                </option>
                <option style={{ fontFamily: "Impact" }} value="Impact">
                  Impact
                </option>
                <option
                  style={{ fontFamily: "Lucida Console" }}
                  value="Lucida Console"
                >
                  Lucida Console
                </option>
                <option
                  style={{ fontFamily: "Lucida Sans Unicode" }}
                  value="Lucida Sans Unicode"
                >
                  Llucida Sans
                </option>
                <option
                  style={{ fontFamily: "Palatino Linotype" }}
                  value="Palatino Linotype"
                >
                  Palatino Linotype
                </option>
                <option style={{ fontFamily: "Tahoma" }} value="Tahoma">
                  Tahoma
                </option>
                <option
                  style={{ fontFamily: "Times New Roman" }}
                  value="Times New Roman"
                >
                  Times New Roman
                </option>
                <option style={{ fontFamily: "Verdana" }} value="Verdana">
                  Verdana
                </option>
              </select>
            </div>

            <div className="pd-t-10 pd-b-10 mg-t-10 bd-t">
              <p className="mg-b-5">
                <b>Timer Size</b>
              </p>
              <div className={classes.root}>
                <Slider
                  defaultValue={
                    settings && settings.timer ? settings.timer.size : 22
                  }
                  getAriaValueText={valuetext}
                  aria-labelledby="discrete-slider"
                  style={{ color: "#0168fa" }}
                  //valueLabelDisplay="auto"
                  step={null}
                  marks={marks}
                  min={8}
                  max={32}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="pd-t-10 mg-t-10 bd-t">
              <p className="mg-b-5">
                <b>Background Color</b>
              </p>
              <div
                className="color-picker-swatch"
                onClick={() => setEditBackgroundColor(!editBackgroundColor)}
              >
                <div
                  className="color-picker-bubble"
                  style={{ background: backgroundColor }}
                />
                <p className="mg-b-0">{backgroundColor}</p>
              </div>

              {editBackgroundColor ? (
                <div style={{ position: "absolute", zIndex: "2", top: "50px" }}>
                  <div
                    style={{
                      position: "fixed",
                      top: "50px",
                      right: "0px",
                      bottom: "0px",
                      left: "0px",
                    }}
                    onClick={() => handleChangeBackgroundColor()}
                  />
                  <SketchPicker
                    color={backgroundColor}
                    onChange={(e) => setBackgroundColor(e.hex)}
                    onChangeComplete={(e) => setBackgroundColor(e.hex)}
                    disableAlpha={false}
                    presetColors={['#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF', 'transparent']}
                  />
                </div>
              ) : null}
            </div>

            <div className="pd-t-10 mg-t-20 bd-t">
              <p className="mg-b-5">
                <b>Background Image/Video</b>
              </p>
              {backgroundImage &&
              !backgroundImage.error &&
              !editBackgroundImage ? (
                <div>
                  <div
                    style={{
                      display: "flex",
                      background: "#f5f6fa",
                      padding: "5px",
                      borderRadius: "5px",
                    }}
                  >
                    <p
                      style={{
                        marginBottom: "0px",
                        marginRight: "20px",
                        //marginTop: "5px",
                        padding: "5px",
                        display: "flex",
                      }}
                    >
                      {/* <ion-icon
                        name="open-outline"
                        style={{ marginRight: "5px", fontSize: "22px" }}
                      ></ion-icon> */}

                      <div
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "158px",
                        }}
                      >
                        
                        {upload_progress.type === "background_image" && upload_progress.progress > 0 ? (
                            <React.Fragment>
                            <b>
                              Uploading: {upload_progress.progress.toFixed(1)}%{" "}
                            </b>
                            <div
                              className="progress"
                              style={{
                                marginTop: "10px",
                                width: "100%",
                                height: "5px",
                              }}
                            >
                              <div
                                className="progress-bar"
                                style={{ width: upload_progress.progress + "%" }}
                                role="progressbar"
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <a href={backgroundImage.url} target="_blank">
                                <b>{backgroundImage.name}</b>
                                <i
                                  style={{ marginLeft: "5px" }}
                                  className="fa-regular fa-up-right-from-square"
                                ></i>
                              </a>
                            </React.Fragment>
                        )}
                      </div>
                    </p>

                    <button
                      className="btn btn-icon btn-outline-danger"
                      type="button"
                      style={{ marginLeft: "auto", padding: "0px 10px" }}
                      //onClick={() => props.handleDeleteFile("background_image")}
                      //onClick={() => deleteAsset('background_image', setBackgroundImage)}
                      onClick={() => deleteBackground()}
                    >
                      <i
                        className="icon ion-ios-trash tx-18"
                        style={{ padding: "0px 0px" }}
                      ></i>
                    </button>


                  </div>
                  <small>Reccomended: 1920x1080.</small>
                  <br />
                  <small>Max size: 1GB. File type: *.jpg, *.png, *.mp4</small>
                </div>
              ) : (
                <React.Fragment>
                  {backgroundImage && backgroundImage.error ? (
                    <p className="tx-danger mg-b-0">{backgroundImage.error}</p>
                  ) : null}
                  <div className="custom-file">
                    <input
                      // onChange={(e) =>
                      //   props.handleUploadFile(
                      //     e.target.files[0],
                      //     "background_image"
                      //   )
                      // }
                      // onChange={(e)=> addToDB(e.target.files[0], 'background_image', setBackgroundImage, backgroundImage)}
                      onChange={(e) => uploadBackground(e.target.files[0])}
                      accept=".jpg,.jpeg,.png,.mp4,"
                      name="background_image"
                      type="file"
                      className="custom-file-input"
                      id="background_image"
                    />

                    <label className="custom-file-label" htmlFor="customFile">
                      Choose file
                    </label>
                  </div>
                  <small>Max size: 1GB. File type: *.jpg, *.png, *.mp4</small>
                  <br />
                </React.Fragment>
              )}
            </div>
          </div>
          <div
            className="col-sm-9"
            style={{ display: "flex", alignItems: "start" }}
          >
            <TimerPreview
              backgroundColor={backgroundColor}
              backgroundImage={backgroundImage}
              textColor={textColor}
              settings={settings}
              game_data={game_data}
              upload_progress={upload_progress}
              customIcon={customIcon}
            />
          </div>
          <small className="tx-gray-500" style={{ marginLeft: "auto" }}>
            *This preview is a close approximation of the real player window.
          </small>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    settings: state.settings,
    upload_progress: state.settings.upload_audio_progress,
  };
};

export default connect(mapStateToProps, null)(TimerSettings);
