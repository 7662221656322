import React, { useState } from "react";
import { connect } from "react-redux";

const UpdateCard = (props) => {

  return (
    <div
      className="modal effect-scale"
      id="UpdateCardModal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{width:'400px'}}
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body pd-sm-t-30 pd-sm-b-20 pd-sm-x-30">
            <h4>Payment Information</h4>
            <p style={{color:'#7d829c'}}>Please enter your payment information</p>
            <div className="form-group">
              <label className="mg-b-5 mg-t-10">
                Credit card number
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="1234 1234 1234 1234"
              ></input>

            </div>

            <div className="form-group">
              <div className="row">
                  <div className="col-4">
                  <label className="mg-b-5 mg-t-10">
                        Expiration
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="MM/YY"
                    ></input>
                  </div>

                  <div className="col-4">
                  <label className="mg-b-5 mg-t-10">
                        CVC
                    </label>
                    <input
                        type="number"
                        className="form-control"
                        placeholder="CVC"
                    ></input>
                  </div>

                  <div className="col-4">
                  <label className="mg-b-5 mg-t-10">
                        ZIP
                    </label>
                    <input
                        type="number"
                        className="form-control"
                        placeholder="80524"
                    ></input>
                  </div>
              </div>

            </div>


          </div>

          <div className="modal-footer pd-x-20 pd-y-15">
            <button
              type="button"
              className="btn btn-white"
              data-dismiss="modal"
            >
              <b>Cancel</b>
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-dismiss="modal"
            >
              <b>Add Card</b>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// const mapDispatchToProps = (dispatch) => {
//   return {
//     addNewRoom: (name, timer) => dispatch(addNewRoom(name, timer)),
//   };
// };

export default connect(null, null)(UpdateCard);
