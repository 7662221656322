import React  from 'react'
import { connect } from 'react-redux'
//import uuid from 'react-uuid'

import { addNewClue } from '../../../redux/actions/gmActions'

const GameCompleteModal = (props) => {

   const { time, handleResetGame, puzzles, puzzlesDone, hintsUsed, addedMinutes } = props;


    return (
        <div 
        className="modal effect-scale" 
        id="gameCompleteModal" 
        tabIndex="-1" 
        role="dialog" 
        aria-hidden="true"
        >

      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30" style={{border:'none'}}>
            <a href="/" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </a>
            <div className="media">
              <span className="tx-color-03 d-none d-sm-block">
                {/* <i className="icon ion-ios-flash" style={{fontSize:"50px"}}></i> */}
                <i class="fa-solid fa-trophy-star" style={{fontSize:"40px"}}></i>
                
                </span>
                
              <div className="media-body mg-sm-l-20">
                <h4 className="tx-18 tx-sm-20 mg-b-2">Game Complete!</h4>
                <p style={{marginBottom:'0px'}}>The players ended with <b>{time ? time.m : '00'}:{time ? time.s: '00'}</b> remaining.</p>


                <div className="row" style={{ padding: "15px 0px 0px 0px" }}>
                <div className="col-4" style={{ padding: "0px 3px", textAlign:'center' }}>
                  <h4 style={{ marginBottom: "0" }}>
                    {puzzlesDone ? puzzlesDone.length : 0}/
                    {puzzles ? puzzles.length : 0}
                  </h4>
                  <small className="text-muted" style={{ fontWeight: "600" }}>
                    {/* <i className="icon ion-md-cube" style={{marginRight:"3px"}}></i> */}
                    Puzzles Done
                  </small>
                </div>
                <div className="col-4" style={{ padding: "0px 3px", textAlign:'center' }}>
                  <h4 style={{ marginBottom: "0" }}>{hintsUsed}</h4>
                  <small className="text-muted" style={{ fontWeight: "600" }}>
                    Messages Sent
                  </small>
                </div>
                <div className="col-4" style={{ padding: "0px 3px", textAlign:'center' }}>
                  <h4 style={{ marginBottom: "0" }}>
                    {/* <ElapsedTime time={timerStartedAt} /> */}
                    {addedMinutes}
                  </h4>
                  <small className="text-muted" style={{ fontWeight: "600" }}>
                    Time Adjusted
                  </small>
                </div>
              </div>


              </div>
            </div>
          </div>
          <div className="py-5 px-5 flex items-center justify-between">
            <button type="button" className="button-outline-dark" data-dismiss="modal"><b>Back</b></button>
            <div className='flex'>
              <button onClick={() => handleResetGame(false)} type="button" className="button-outline-dark mr-3" data-dismiss="modal"><b>Reset Without Saving</b></button>
              <button onClick={() => handleResetGame(true)} type="button" className="button-red-dark" data-dismiss="modal"><b>Save & Reset</b></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}


const mapDispatchToProps = (dispatch) => {
    return {
        addNewClue: (data, id) => dispatch(addNewClue(data, id))
    }
}

export default connect(null, mapDispatchToProps)(GameCompleteModal)
