import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Tooltip from "@mui/material/Tooltip";

import AddPuzzleModal from "../modals/AddPuzzleModal";
import PuzzleItem from "./components/PuzzleItemV2";
import { reorderPuzzles, deletePuzzle } from "../../../redux/actions/gmActions";

// React Sortable
import { ReactSortable } from "react-sortablejs";

const PropsSidebar = (props) => {
  const {
    //handleGreyOutClue,
    handleSelectPuzzle,
    puzzles,
    id,
    selectedPuzzle,
    puzzlesDone,
    markPuzzleAsDone,
    selectedPuzzleObj,
    userPermissions,
  } = props;

  const [sortablePuzzles, setSortablePuzzles] = useState(puzzles);
  useEffect(() => {
    setSortablePuzzles(puzzles);
  }, [puzzles]);

  const sortableChange = () => {
    sortablePuzzles.forEach((puzzle, index) => {
      props.reorderPuzzles(props.id, puzzle.id, index);
    });
  };

  const delete_puzzle = (id, selectedPuzzle) => {
    props.deletePuzzle(id, selectedPuzzle);
    handleSelectPuzzle();
  };

  const [puzzlesLocked, setPuzzlesLocked] = useState(false);

  return (
    <React.Fragment>
      <div
        className="chat-sidebar puzzle-sidebar"
        id="puzzles-sidebar"
        style={{ width: "250px", top: "40px", bottom: "42px" }}
      >
        <div className="box-header" style={{ paddingRight: "10px" }}>
          <h6 style={{ marginBottom: "0px" }}>
          Objectives</h6>
        </div>

        {/* <div className="chat-content-header" style={{ paddingRight: "10px" }}>
          <h6 style={{ marginBottom: "0px" }}>Puzzles</h6>
        </div> */}

        <div className="chat-sidebar-body" style={{ top: "34px" }}>
          <div
            className="flex-fill pd-y-20 pd-x-10"
            style={{
              paddingTop: "0px",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            <div
              id="chatDirectMsg"
              className="chat-msg-list"
              style={{ marginBottom: "40px" }}
            >
              {sortablePuzzles.length > 0 ? (
                <ReactSortable
                  disabled={
                    userPermissions.isAdmin || userPermissions.canEditPuzzles
                      ? false
                      : true
                  }
                  list={sortablePuzzles}
                  setList={setSortablePuzzles}
                  animation={200}
                  delayOnTouchStart={true}
                  delay={5}
                  onSort={() => sortableChange()}
                >
                  {sortablePuzzles.map((puzzleObj, idx) => {
                    var isDone = false;
                    //if (puzzlesDone.includes(puzzleObj.id)) isDone = true;
                    puzzlesDone.filter((donePuzzle) => { if (donePuzzle.id === puzzleObj.id) return isDone = true}); 

                    return (
                      <PuzzleItem
                        key={puzzleObj.id}
                        data-id={puzzleObj.id}
                        selectedPuzzle={selectedPuzzle}
                        puzzleObj={puzzleObj}
                        handleSelectPuzzle={handleSelectPuzzle}
                        isDone={isDone}
                        markPuzzleAsDone={markPuzzleAsDone}
                      />
                    );
                  })}
                </ReactSortable>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "60px",
                  }}
                >
                  <a
                    href="#createPuzzleModal"
                    data-toggle="modal"
                    className="btn btn-xs btn-primary"
                  >
                    <b>Add Puzzle</b>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* <div
          className="bolt-box-shadow"
          id="puzzles-action-bar"
          style={{
            display:
              userPermissions.isAdmin || userPermissions.canEditPuzzles
                ? null
                : "none",
            position: "absolute",
            bottom: "0px",
            padding: "3px 10px",
            width: "100%",
            background: "rgb(35 35 43)",
            borderTop: "1px solid black",
          }}
        >
          <Tooltip title="New Puzzle" placement="top">
            <IconButton
              aria-label="add"
              size="small"
              id="addPuzzleModal"
              style={{ marginRight: "5px" }}
            >
              <AddIcon
                fontSize="small"
                style={{ color: "#9f9793" }}
                href="#createPuzzleModal"
                data-toggle="modal"
              />
            </IconButton>
          </Tooltip>
        </div> */}

        <div
              style={{
                position: "absolute",
                bottom: "0px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "10px",
                justifyContent: "space-between",
                width: "100%",
                background: '#303038',
              }}
            >
              <Tooltip title="Add Objective" placement="top" arrow>
                <button
                  href={
                    userPermissions.isAdmin || userPermissions.canEditPuzzles
                      ? "#createPuzzleModal"
                      : "#"
                  }
                  id="addPuzzleModal"
                  data-toggle="modal"
                  className="bolt-icon-button group"
                >
                  {/* <i className="fa-solid fa-plus"></i> */}
                  {/* <i class="fa-solid fa-square-plus"></i> */}
                  <i class="fa-kit fa-regular-cube-circle-plus group-hover:text-gray-200"></i>
                </button>
              </Tooltip>

            </div>



      </div>

      <AddPuzzleModal id={id} />
      {/* <DeletePuzzleModal delete_puzzle={delete_puzzle} id={id} selectedPuzzle={selectedPuzzleObj} /> */}

      {/* <ReactTooltip place="top" type="dark" effect="solid" delayShow={1000}/> */}
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    reorderPuzzles: (doc_id, puzzle_id, index) =>
      dispatch(reorderPuzzles(doc_id, puzzle_id, index)),
    deletePuzzle: (doc_id, puzzle_id) =>
      dispatch(deletePuzzle(doc_id, puzzle_id)),
  };
};

export default connect(null, mapDispatchToProps)(PropsSidebar);
