import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { updateImageClue } from "../../../redux/actions/gmActions";

type Props = {
  loadedClue: {
    name: string;
    file: {
      url: string;
      name: string;
      size: number;
    };
    puzzle_doc_id: string;
    id: string;
    fullscreen: boolean;
    transparent_bg: boolean;
  };
  gm_id: string;
  updateImageClue: (
    doc_id: string,
    puzzle_id: string,
    clue_obj: any,
    update: any,
    type: string
  ) => void;
};

const EditDeleteImageClueModal = (props: Props) => {
  const { loadedClue, gm_id } = props;

  const [audio_id, setAudioId] = useState(0);

  const [update, setUpdate] = useState({
    name: "",
    file: {
      url: "",
      name: "",
      size: 0,
    },
    fullscreen: true,
    transparent_bg: false
  });

  useEffect(() => {
    setUpdate({
      name: loadedClue.name,
      file: loadedClue.file,
      fullscreen: loadedClue.fullscreen,
      transparent_bg: loadedClue.transparent_bg
    });

  }, [loadedClue]);

  const updateData = (e: any) => {
    console.log(e)
    setUpdate({
      ...update,
      [e.target.id]: e.target.value,
    });
  };



  const handleUpdateClue = () => {
    console.log('Update image!')
    props.updateImageClue(
      gm_id,
      loadedClue.puzzle_doc_id,
      loadedClue,
      update,
      "update"
    );
    setUpdate({
      name: "",
      file: {
        url: "",
        name: "",
        size: 0,
      },
      fullscreen: true,
      transparent_bg: false
    })
  };

  const handleDelete = () => {
    props.updateImageClue(
      gm_id,
      loadedClue.puzzle_doc_id,
      loadedClue,
      update,
      "delete"
    );
    setUpdate({
      name: "",
      file: {
        url: "",
        name: "",
        size: 0,
      },
      fullscreen: true,
      transparent_bg: false
    });
  };

  const updateFullscreen = (e: any) => {
    setUpdate({
      ...update,
      fullscreen: e.target.checked,
    });
  };

  const updateTransparentBG = (e: any) => {
    setUpdate({
      ...update,
      transparent_bg: e.target.checked,
    });
  };

  //   console.log("loaded clue", loadedClue);
  return (
    <div
      className="modal effect-scale"
      id="editDeleteImageClueModal"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered wd-sm-650"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body">
            <p className="text-lg mb-1 text-white font-medium">
              Image settings
            </p>

            <div className="flex flex-col mb-3">
              <label className="label-dark">Name</label>
              {/* <input onChange={(e) => updateData("message", e.target.value)} type="text" className="form-control" value={update.message}></input> */}
              <input
                onChange={(e) => updateData(e)}
                type="text"
                id="name"
                className="input-dark"
                value={update.name}
              ></input>
            </div>


            <div className="flex items-center space-x-4">
              {/* <div className="flex items-center">
                <input
                  id="fullscreen"
                  type="checkbox"
                  onChange={(e) => updateFullscreen(e)}
                  checked={update.fullscreen}
                  className="w-4 h-4 text-blue-600 rounded mr-2 focus:ring-0  bg-gray-700 border-gray-600"
                />
                <label className="label-dark mb-0 pb-0">Fullscreen</label>
              </div> */}

              <div className="flex items-center">
                <input
                  id="transparent_bg"
                  type="checkbox"
                  onChange={(e) => updateTransparentBG(e)}
                  checked={update.transparent_bg}
                  className="w-4 h-4 text-blue-600 rounded mr-2 focus:ring-0  bg-gray-700 border-gray-600"
                />
                <label className="label-dark mb-0 pb-0">Transparent background</label>
              </div>
            </div>
          </div>
          <div className="modal-footer-dark">
            <div className="flex justify-between">
              <button
                type="button"
                className="button-outline-dark"
                data-dismiss="modal"
                style={{ marginRight: "auto" }}
                onClick={() => handleDelete()}
              >
                Delete image
              </button>
              <div className="flex">
                <button
                  type="button"
                  className="button-outline-dark mr-2"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={() => handleUpdateClue()}
                  className="button-blue-dark"
                  data-dismiss="modal"
                >
                  Save image
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateImageClue: (
      doc_id: string,
      puzzle_id: string,
      clue_obj: string,
      update: any,
      type: string
    ) => dispatch(updateImageClue(doc_id, puzzle_id, clue_obj, update, type)),
  };
};

export default connect(null, mapDispatchToProps)(EditDeleteImageClueModal);