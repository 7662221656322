import React, { Component, useRef  } from "react";
import { connect } from "react-redux";
import {
  updateControlAfterVideo,
  updateTimer,
  updateThumbnail
} from "../../../redux/actions/timerActions";
import { getLiveWindowSettings } from "../../../redux/actions/gmActions";
import {
  loadStateFromLocalStorage,
  getVideoClueFromLocalStorage,
  loadAudioState,
} from "../../../localStorage";

import html2canvas from "html2canvas";


import { get, set, createStore, del } from "idb-keyval";
import { addToDB } from "../../_settings/Game/Functions/SettingsFunctions";

import FadeIn from "react-fade-in";

import VictoryScreen from "./Components/VictoryScreen";
import TimerScreen from "./Components/TimerScreen";

function readBlob(blob) {
  return new Blob([blob.buffer], { type: blob.type, name: blob.name });
}

export class LiveWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playVideoBrief: false,
      videoIsPlaying: false,
      backgroundImageSet: false,
      settings: {},
      backgroundImage: "",
      backgroundImageVictory: "",
      backgroundImageFail: "",
      videoBrief: "",
      isPlayerWindowOpen: true,
      customIcon: "",
      thumbnail: null,
      isFullscreen: false,
      videoClueOnScreen: null
    };
    this.localStorageUpdated = this.localStorageUpdated.bind(this);
    this.handleVideoFinished = this.handleVideoFinished.bind(this);
  }

  // Setup the `beforeunload` event listener
  setupBeforeUnloadListener = (ev) => {
    ev.preventDefault();
    this.props.updateTimer({
      ...this.props.timer,
      isPlayerWindowOpen: false,
    });

    return;
  };

  componentDidMount() {
    document.title = "Player Window";
    const localStorage = loadStateFromLocalStorage();
    this.setState({
      ...localStorage.timer,
    });

    // Add Timer Event Listener
    window.addEventListener("storage", this.localStorageUpdated);
    // Window Close - Update Timer
    window.addEventListener("beforeunload", this.setupBeforeUnloadListener);

    const game_id = this.props.match.params.id;
    this.props.getLiveWindowSettings(game_id);

    // Get IndexDB Store Assets
    const assetsStore = createStore(game_id, "assets");

    // Get Timer Background
    get("background_image", assetsStore).then((val) => {
      //console.log('background img val', val)
      if (val) {
        const blob = readBlob(val);
        var url = URL.createObjectURL(blob);
        this.setState({ backgroundImage: { ...val, url } });
      } else {
        console.log("no local bg image");
      }
    });

    

    // Get Video Brief
    get("video_brief", assetsStore).then((val) => {
      if (val){
        const blob = readBlob(val)
        var url = URL.createObjectURL(blob)
        this.setState({ videoBrief: { ...val, url }})
      }
    });

    // Get Victory Background
    get("background_image_victory", assetsStore).then((val) => {
      if (val) {
        const blob = readBlob(val);
        var url = URL.createObjectURL(blob);
        this.setState({ backgroundImageVictory: { ...val, url } });
      }
    });

    // Get Fail Background
    get("background_image_fail", assetsStore).then((val) => {
      if (val) {
        const blob = readBlob(val);
        var url = URL.createObjectURL(blob);
        this.setState({ backgroundImageFail: { ...val, url } });
      }
    });

    // Get Custom Icon
    get("custom_icon", assetsStore).then((val) => {
      if (val) {
        const blob = readBlob(val);
        var url = URL.createObjectURL(blob);
        this.setState({ customIcon: { ...val, url } });
      }
    });


    // setTimeout(async () => {
      
      
    //     const canvas = await html2canvas(document.getElementById('timer-screen'), {
    //       scale: 1,
    //       width: 1920, 
    //       height: 1080,
    //       windowWidth: 1920,
    //       windowHeight: 1080
    //     });
    //     const image = canvas.toDataURL("image/jpeg", 1);
    //     const response = await fetch(image);
    //     const blob = await response.blob(); 
    //     const file = new File(
    //       [blob],
    //       "thumbnail.jpg",
    //       {
    //         type: "image/jpeg",
    //         lastModified: new Date()
    //       }
    //     );


    //       this.setState({
    //         thumbnail: file
    //       })


    // }, 5000);


  }

  

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("storage", this.localStorageUpdated);
      window.removeEventListener(
        "beforeunload",
        this.setupBeforeUnloadListener
      );
    }
  }

  localStorageUpdated() {
    console.log('local storage updated')
    if (typeof localStorage !== 'undefined' && localStorage.getItem("state")) {
      const ls = loadStateFromLocalStorage();
      this.setState({
        ...this.state,
        ...ls.timer,
      });
    }

    if (typeof localStorage !== 'undefined' && localStorage.getItem("audio")) {
      const audioState = loadAudioState();
      this.setState({
        ...this.state,
        audio_volume: {
          ...audioState,
        },
      });
    }

    if (typeof localStorage !== 'undefined' && localStorage.getItem("BOLT_videoClue")) {
      const ls = getVideoClueFromLocalStorage();
      this.setState({
        ...this.state,
        videoClueOnScreen: ls
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    // if (this.state.videoCluePlaying !== prevState.videoCluePlaying) {
    //   console.log('video clue playing', this.props.videoCluePlaying)

    //   setTimeout(() => {
    //     if (!this.state.videoCluePlaying){
    //       this.setState({
    //         clueOnScreen: ""
    //       })
    //     }
    //   }, 500);
      
    // }


    if (this.props.livewindow_settings !== prevProps.livewindow_settings) {
      this.setState({
        settings: this.props.livewindow_settings,
      });
    }


    // Automatically Close window upon closing console window
    // if (loadStateFromLocalStorage().timer.isPlayerWindowOpen !== prevState.isPlayerWindowOpen) {
    //   if (loadStateFromLocalStorage().timer.isPlayerWindowOpen === false) {
    //     console.log("closing window");
    //     window.open("", "_self").close();
    //   }
    // }

    if (this.state.thumbnail !== prevState.thumbnail) {
      if (this.state.thumbnail) {
        this.props.updateThumbnail(this.props.match.params.id, this.state.thumbnail)
      }
    }

    // Check if background color exists
    if (this.state.settings !== prevState.settings) {
      const { timer, fail, victory } = this.state;
      if (timer && fail && victory) {
        this.setState({
          allAssetsLoaded: true,
        });
      }
    }

    // Check if Video needs to play
    if (this.state.videoIsPlaying !== prevState.videoIsPlaying) {
      if (this.state.videoIsPlaying) {
        this.handlePlayVideo();
      } else {
        console.log("VIDEO IS NOT PLAYING");
        this.handleStopVideo();
      }
    }

    // if (this.state.audio_volume !== prevState.audio_volume){
    //   if (this.state.video_brief && this.state.video_brief.url){
    //       console.log('setting audio volume')
    //       this.refs.vidRef.volume = this.state.audio_volume.audio_video_brief;

    //   }

    // }
  }

  handleUpdateTimer = () => {
    // const localStorage = loadStateFromLocalStorage();
    // this.setState({
    //   ...localStorage.timer,
    // });

    // if VideoIsPlaying
    if (this.state.videoIsPlaying) {
      document.body.style.backgroundColor = "#000";
      var playVideoBriefPromise = this.refs.vidRef.play();

      if (playVideoBriefPromise !== undefined) {
        playVideoBriefPromise
          .then((_) => {
            // Automatic playback started!
            // Show playing UI.
            console.log("video brief is playing");
          })
          .catch((error) => {
            // Auto-play was prevented
            // Show paused UI.
            console.log("video error", error);
            this.handleVideoFinished();
          });
      }
    } else {
      document.body.style.backgroundColor = this.state.settings.background_color
        ? this.state.settings.background_color
        : "#1e1d23";
      this.refs.vidRef.load();
      this.refs.vidRef.oncanplaythrough = function (e) {
        //console.log("Can play through video without stopping", e);
      };
    }
  };

  handlePlayVideo = () => {
    // if VideoIsPlaying
    if (this.state.videoIsPlaying) {
      if (this.refs.vidRef) {
        // Set Background Style
        document.body.style.backgroundColor = "#000";
        document.body.style.backgroundImage = "";

        const audioState = loadAudioState();
        // this.refs.vidRef.addEventListener(
        //   "ended",
        //   this.handleVideoFinished,
        //   true
        // );

        this.refs.vidRef.onended = function (e) {
          /*Do things here!*/
          console.log("video ended");
          this.handleVideoFinished();
        }.bind(this);

        // Do not autoplay
        this.refs.vidRef.autoplay = false;

        // Audio State
        const savedAudioState = audioState["audio_video_brief"];
        if (savedAudioState) this.refs.vidRef.volume = savedAudioState;
        //console.log('AUDIO', savedAudioState)

        var playVideoBriefPromise = this.refs.vidRef.play();
        if (playVideoBriefPromise !== undefined) {
          playVideoBriefPromise
            .then((_) => {
              // Automatic playback started!
              // Show playing UI.
              console.log("video brief is playing");
              document.title = "Video Playing - Live Window";
            })
            .catch((error) => {
              // Auto-play was prevented
              // Show paused UI.
              console.log("video error", error);
              this.handleVideoFinished();
              document.title = "Live Window";
            });
        }
      }
    } else {
      document.body.style.backgroundColor = this.state.settings.background_color
        ? this.state.settings.background_color
        : "#1e1d23";
      this.refs.vidRef.load();
      this.refs.vidRef.oncanplaythrough = function (e) {
        console.log("Can play through video without stopping", e);
      };
    }
  };

  handleStopVideo = () => {
    if (this.refs.vidRef) {
      this.refs.vidRef.pause();
      this.refs.vidRef.currentTime = 0;
    }
  };

  handleVideoFinished = () => {
    // // Re-set Background Color
    // document.body.style.backgroundColor = this.state.settings.background_color;

    // // Re-set Background Image if exists
    // if (this.state.background_image && this.state.background_image.url){
    //   const background = this.state.background_image.url;
    //   document.body.style.backgroundImage = "url(" + background + ")";
    //   document.body.style.backgroundRepeat = 'no-repeat';
    //   document.body.style.backgroundSize = 'cover';
    // }

    if (this.state.videoIsPlaying) {
      document.title = "Live Window";
      console.log("updating control after video");
      this.props.updateControlAfterVideo({
        videoIsPlaying: false,
      });

      setTimeout(() => {
        this.setState({
          playVideoBrief: false,
          videoIsPlaying: false,
        });
      }, 2000);
    }
  };



  handleVideoClueFinished = () => {
    console.log('VIDEO CLUE FINISHED')
    this.props.updateControlAfterVideoClue(false)
  }

  toggleFullScreen = () => {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
        this.setState({isFullscreen: true})
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
        this.setState({isFullscreen: true})
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
        this.setState({isFullscreen: true})
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();

      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();

      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();

      }
    }
  };

  showVideo = () => {
    if (!this.state.videoIsPlaying) return "none";
  };

  render() {
    const {
      time,
      clueOnScreen,
      clueOnScreenCount,
      gameComplete,
      gameSuccess,
      gameFail,
      endingText,
      videoIsPlaying,
      isInfiniteClues,
      victory,
      fail,
      timer,
      background_image,
      timerRunning,
      audio_volume,
      customIcon
    } = this.state;

    const video_brief = this.state.video_brief
      ? this.state.video_brief.url
      : null;

    const video_brief_type = this.state.video_brief
      ? this.state.video_brief.type
      : null;

    const victoryBGImage =
      background_image && background_image.victory
        ? background_image.victory
        : null;
    const timerBGImage =
      background_image && background_image.timer
        ? background_image.timer
        : null;


    const showClues = () => {
      if (!isInfiniteClues) {
        return (
          <React.Fragment>
            {clueOnScreenCount
              ? Object.keys(clueOnScreenCount).map((clue, index) => {
                  if (clueOnScreenCount[clue] === true) {
                    return (
                      <FadeIn>
                        <i
                          className="icon ion-ios-flash"
                          style={{ color: "rgb(228, 246, 28)" }}
                        ></i>
                      </FadeIn>
                    );
                  } else {
                    return (
                      <FadeIn>
                        <i
                          className="icon ion-md-close"
                          style={{ color: "rgb(221, 55, 55)" }}
                        ></i>
                      </FadeIn>
                    );
                  }
                })
              : null}
          </React.Fragment>
        );
      } else {
        return (
          <FadeIn>
            <i
              className="icon ion-md-infinite"
              style={{ color: "rgb(228, 246, 28)" }}
            ></i>
          </FadeIn>
        );
      }
    };

    const timerTextStyle = (position) => {
      const { settings } = this.state;
      var style = {};

      if (position === "main") {
        style = {
          fontSize:
            settings.timer && settings.timer.size
              ? settings.timer.size + "vh"
              : "22vh",
          fontWeight: "bold",
          color:
            settings.timer && settings.timer.color
              ? settings.timer.color
              : "#fafafa",
          display:
            settings.timer && settings.timer.show === false ? "none" : null,
          fontFamily:
            settings.timer && settings.timer.font ? settings.timer.font : null,
        };
      }

      if (position === "small") {
        style = {
          fontSize: "8vh",
          fontWeight: "bold",
          color:
            settings.timer && settings.timer.color
              ? settings.timer.color
              : "#fafafa",
          fontFamily:
            settings.timer && settings.timer.font ? settings.timer.font : null,
        };
      }

      return {
        ...style,
      };
    };

    const bgColorStyle = () => {
      const { settings, gameSuccess, gameFail, background_image } = this.state;

      if (
        !gameComplete &&
        background_image &&
        background_image.timer &&
        background_image.timer.type.startsWith("video/")
      ) {
        return {
          background: "transparent",
        };
      }
      if (gameComplete && gameSuccess) {
        console.log("Victory Screen");

        if (
          background_image &&
          background_image.victory &&
          background_image.victory.type.startsWith("image/")
        ) {
          return {
            background: "url(" + background_image.victory.url + ")",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          };
        } else {
          return {
            background:
              settings.victory && settings.victory.bg_color
                ? settings.victory.bg_color
                : "#000",
          };
        }
      } else if (gameComplete && gameFail) {
        console.log("Fail Screen");

        return {
          background:
            settings.fail && settings.fail.bg_color
              ? settings.fail.bg_color
              : "#000",
        };
      } else if (!gameComplete && !videoIsPlaying) {
        //console.log('Timer Screen')

        return {
          background:
            settings.timer && settings.timer.bg_color
              ? settings.timer.bg_color
              : "#000",
        };
      } else if (videoIsPlaying) {
        //console.log('Video Screen')
        return { background: "#000" };
      } else {
        //console.log('Fail Screen')
        return {
          background:
            settings.timer && settings.timer.bg_color
              ? settings.timer.bg_color
              : "#000",
        };
      }
    };

    const showView = () => {
      if (gameComplete) {
        return (
          <VictoryScreen
            settings={this.state.settings}
            gameFail={gameFail}
            gameSuccess={gameSuccess}
            time={time}
          />
          // <div
          //   id="successBox"
          //   className="row"
          //   style={{
          //     marginRight:'0px',
          //     marginLeft:'0px',
          //     marginBottom:'10vh'
          //   }}
          // >
          //   <div className="col-sm-12">
          //     <div
          //       className="text-center"
          //       style={{ marginTop: "20px", marginBottom: "10px !important" }}
          //     >
          //       <FadeIn>
          //         {this.state.gameSuccess ? (
          //           <React.Fragment>
          //             <h1
          //                 style={victoryTextStyle()}
          //               >
          //                 {endScreenText()}
          //             </h1>
          //             <h1 style={{ fontSize: "7vh", color: "#fafafa" }}>
          //               {time.m}:{time.s}
          //             </h1>
          //           </React.Fragment>
          //         ) : (
          //           <React.Fragment>
          //             <h1
          //                 style={failTextStyle()}
          //             >
          //                 {endScreenText()}
          //             </h1>
          //             <h1 style={{ fontSize: "7vh", color: "#fafafa" }}>
          //               {time.m}:{time.s}
          //             </h1>
          //           </React.Fragment>
          //         )}
          //       </FadeIn>
          //     </div>
          //   </div>
          // </div>
        );
      } else if (!gameComplete && !videoIsPlaying) {
        return (
          <React.Fragment>
            {clueOnScreen ? (
              <FadeIn>
                <div style={{ position: "fixed", top: "2vw", right: "2vw" }}>
                  {time ? (
                    <h1 style={timerTextStyle("small")}>
                      {time.m}:{time.s}
                    </h1>
                  ) : null}
                </div>
              </FadeIn>
            ) : null}

            {clueOnScreen ? null : (
              <FadeIn>
                <div
                  id="timerBox"
                  className="row"
                  style={{
                    marginRight: "0px",
                    marginLeft: "0px",
                    marginBottom: "20vh",
                  }}
                >
                  <div className="col-sm-12">
                    <div
                      className="u-mv-large text-center"
                      style={{
                        marginBottom: "10px !important",
                        color: "#fafafa",
                      }}
                    >
                      {time ? (
                        <React.Fragment>
                          <p
                            className="small-header-text"
                            style={{
                              fontSize: "3vh",
                              marginBottom: "0px !important",
                              display:
                                this.state.settings.timer &&
                                this.state.settings.timer.show === false
                                  ? "none"
                                  : null,
                            }}
                          >
                            Time Remaining
                          </p>

                          <h1 style={timerTextStyle("main")}>
                            {time.m}:{time.s}
                          </h1>
                        </React.Fragment>
                      ) : (
                        <div className="text-center">
                          <div
                            className="spinner-border"
                            style={{ color: "#fafafa" }}
                          ></div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </FadeIn>
            )}

            {clueOnScreen ? (
              <FadeIn>
                <div
                  id="clueBox"
                  className="row"
                  style={{
                    marginRight: "0px",
                    marginLeft: "0px",
                    marginBottom: "10vh",
                  }}
                >
                  <div className="col-sm-12">
                    <div
                      className="text-center"
                      style={{
                        marginTop: "20px",
                        marginBottom: "10px !important",
                      }}
                    >
                      <h1
                        style={{
                          fontSize: "7vh",
                          color: "#fafafa",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {clueOnScreen}
                      </h1>
                    </div>
                  </div>
                </div>
              </FadeIn>
            ) : null}
          </React.Fragment>
        );
      }
    };

    const bgVideoSrc = () => {
      if (!videoIsPlaying && !gameComplete) {
        return background_image && background_image.timer
          ? background_image.timer.url
          : null;
      } else if (!videoIsPlaying && gameComplete && gameSuccess) {
        return background_image && background_image.victory
          ? background_image.victory.url
          : null;
      }
    };


    return (
        <div
        id="pw"
        className="live-window"
        onClick={() => this.toggleFullScreen()}
        style={{ display: "flex", alignItems: "center" }}
      >

        {/* <div className="absolute top-0 left-0 z-10">
          <img src="/bolt_white_on_black.png" style={{width: "9vh"}}></img>
        </div> */}

        {/* <div className="absolute top-0 bottom-0 z-10">
          <div className="bg-yellow-300 w-screen h-screen flex items-center justify-center">
            <div className="w-full h-full player-window-notify"></div>
          </div>
        </div> */}

{/* <div className="absolute top-0 bottom-0 z-10">
          <div className=" w-screen h-screen flex items-center justify-center">
            <div className="w-full h-full  player-window-notify"></div>
          </div>
        </div> */}

{!this.state.isFullscreen && (
          <div className="absolute right-0 left-0 z-10">
          <div className="w-full flex items-center justify-center">
          <button onClick={()=> this.toggleFullScreen()} className="bg-black bg-opacity-80 py-3 px-4 rounded text-white flex flex-col items-center justify-center focus:outline-none">
            <p className="text-3xl font-bold">Click to go fullscreen</p>
          </button>
          </div>

        </div>
)}


        <TimerScreen
          gameComplete={gameComplete}
          settings={this.state.settings}
          clueOnScreen={clueOnScreen}
          time={time}
          video_brief={this.state.videoBrief}
          videoIsPlaying={videoIsPlaying}
          audio_volume={audio_volume}
          handleVideoFinished={this.handleVideoFinished}
          isInfiniteClues={isInfiniteClues}
          clueOnScreenCount={clueOnScreenCount}
          //background_image={timerBGImage}
          background_image={this.state.backgroundImage}
          customIcon={customIcon}
          handleVideoClueFinished={this.handleVideoClueFinished}
          clueOnScreen_Video={this.state.videoClueOnScreen}
        />

        <VictoryScreen
          gameComplete={gameComplete}
          settings={this.state.settings}
          gameFail={gameFail}
          gameSuccess={gameSuccess}
          time={time}
          videoIsPlaying={videoIsPlaying}
          background_image={this.state.backgroundImageVictory}
          background_image_victory={this.state.backgroundImageVictory}
          background_image_fail={this.state.backgroundImageFail}
          customIcon={customIcon}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {

  return {
    gameMaster: state.gameMaster,
    timer: state.timer,
    livewindow_settings: state.gameMaster.livewindow_settings,
    clueOnScreen: state.timer.clueOnScreen,
    videoCluePlaying: state.timer.videoCluePlaying
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateControlAfterVideo: (update) =>
      dispatch(updateControlAfterVideo(update)),
    getLiveWindowSettings: (game_id) =>
      dispatch(getLiveWindowSettings(game_id)),
    updateTimer: (timer) => dispatch(updateTimer(timer)),
    updateThumbnail: (game_id, file) => dispatch(updateThumbnail(game_id, file)),
    updateControlAfterVideoClue: () => dispatch(updateControlAfterVideoClue())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveWindow);
