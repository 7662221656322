import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { audio_control } from "../../../redux/actions/gmActions";
import moment from "moment";
import {
  updateTimer,
  completeGame,
  updateClueOnScreenCount,
} from "../../../redux/actions/timerActions";
import { loadStateFromLocalStorage } from "../../../localStorage";
import {
  remoteEditTimerIsRunning,
  remoteAdjustTimer,
} from "../../../redux/actions/remoteActions";

// Sentry.io
import * as Sentry from "@sentry/react";

import GameCompleteModal from "../modals/GameCompleteModal";
import AdjustTimer from "./AdjustTimer";
import StartButton from "../buttons/StartButton";
import StartButtonNew from "../buttons/StartButtonNew";
import StopButtonNew from "../buttons/StopButtonNew";

import { get, createStore } from "idb-keyval";


import ElapsedTime from "../other/ElapsedTime";
import { Tooltip } from "@mui/material";

export class CountdownTimer extends Component {
  constructor(props) {
    super();

    // REQUIRED INIT STATE
    this.state = {
      time: {
        m: "00",
        s: "00",
      },
      seconds: 0,
      startingSeconds: 0,
      timerRunning: false,
      gameOver: false,
      timerSpeed: 1000,
      gameComplete: false,
      videoIsPlaying: false,
      loopAudioSoundtrack: true,
      isVideoBrief: false,
      estimatedFinshTime: "",
      clueOnScreenCount: { 0: true, 1: true, 2: true },
      isInfiniteClues: false,
      interval: 1000, // was 1000 as of July 3, 2021
      expected: Date.now() + 1000,
      gameIsReady: true,
      videoBriefExists: false,
      timerStartedAt: null,
      puzzlesDoneLength: 0,
    };

    //this.liveWindow = null;
    this.timer = 0;
    this.expected = Date.now() + 1000;
    this.expected_end = "";
    this.adjusted_end = "";
    this.startTimer = this.startTimer.bind(this);
    this.pauseTimer = this.pauseTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this.handleAdjustTimer = this.handleAdjustTimer.bind(this);
    this.handleStartVideoBrief = this.handleStartVideoBrief.bind(this);
    this.handleCompleteGame = this.handleCompleteGame.bind(this);
    this.handleFailGame = this.handleFailGame.bind(this);
    this.handleResetGame = this.handleResetGame.bind(this);
    this.videoHasFinished = this.videoHasFinished.bind(this);
    this.countDown2 = this.countDown2.bind(this);
  }

  togglePlaySoundtrack = () => {
    if (this.state.audio_soundtrack) {
      this.state.audio_soundtrack.volume = 0.5;
      this.state.audio_soundtrack.play();

      if (this.state.loopAudioSoundtrack) {
        this.state.audio_soundtrack.addEventListener(
          "ended",
          this.togglePlaySoundtrack,
          true
        );
      }
    }
  };

  secondsToTime(secs) {
    // let hours = Math.floor(secs / (60 * 60));
    let divisor_for_minutes = Math.floor(secs % (120 * 60));

    //console.log('divisor_secs', secs, divisor_for_minutes)

    let minutes = Math.floor(divisor_for_minutes / 60);
    if (minutes < 10) {
      minutes = "0" + minutes;
    }

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    //let seconds = Math.floor(divisor_for_seconds);
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    let obj = {
      m: minutes,
      s: seconds,
    };
    return obj;
  }

  componentDidMount() {
    this.setState({
      timerRunning: false,
    });

    const game_id = this.props.id;
    const assetsStore = createStore(game_id, "assets");

    // if (this.props.game_assets.video_brief){

    //   this.props.updateTimer({
    //     ...this.props.timer,
    //     videoBriefExists: true,
    //   })
    // }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log(prevProps);
    if (this.props.timer !== prevProps.timer) {
      this.setState({
        ...this.props.timer,
      });

      //T-870 Build Automation system
      //this.check_automation()
    }

    if (this.props.timer.video_brief !== prevProps.timer.video_brief) {
      if (this.props.timer.video_brief.url) {
        this.props.updateTimer({
          ...this.props.timer,
          videoBriefExists: true,
        });
      } else {
        this.props.updateTimer({
          ...this.props.timer,
          videoBriefExists: false,
        });
      }
    }

    if (this.props.gameMaster.assets !== prevProps.gameMaster.assets) {
      this.props.updateTimer({
        ...this.props.timer,
        video_brief: this.props.gameMaster.assets.video_brief,
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    Sentry.configureScope((scope) => scope.clear());
  }

  // Build Automation System
  check_automation = () => {
    const { time } = this.state;
    const currentTime = time.m + ":" + time.s;

    const { assets } = this.props.gameMaster;

    const automations = [
      { time: "59:57", type: "send_message", message: "Hello" },
      { time: "59:55", type: "send_message", message: "Okay" },
      { time: "59:53", type: "send_message", message: "Awesome" },
      { time: "59:50", type: "play_sound", sound_id: "audio_alert" },
      { time: "59:48", type: "send_message", message: "Radical" },
      { time: "59:46", type: "send_message", message: "epic" },
      { time: "59:44", type: "send_message", message: "no way" },
      { time: "59:30", type: "send_message", message: "last one" },
    ];

    const rules = [
      {
        trigger_type: "timer",
        operator: "===",
        target: "59:57",
        event_payload: {
          type: "send_hint",
          message: "59 Minutes Left...",
        },
      },
      {
        trigger_type: "onCompleteGame",
        event_payload: {
          type: "play_audio",
          destination:
            "https://firebasestorage.googleapis.com/v0/b/escaperoomwaiver-v2.appspot.com/o/gamemaster%2Ft7NnbAu4lokByhzZr85X%2Fhudztester-room-3-b3156d-1ba%2FEarthquake%2C%20Breaking%20Buildings%2C%20Rocks%20Falling.mp3?alt=media&token=2bfe3c9a-ea9d-4fbb-b4fe-a2c4423997bf",
        },
      },
      {
        trigger_type: "onCompleteGame",
        event_payload: {
          type: "set_background_color",
          destination: "#fff",
        },
      },
    ];

    rules.forEach((rule) => {
      if (rule.trigger_type === "timer") {
        console.log("TRIGGER: Timer");
        const { operator, target, event_payload } = rule;

        if (target === currentTime) {
          if (event_payload.type === "send_hint") {
            this.props.pushToMessagesFeed("automation", rule.message);
          }
        }
      }

      if (rule.trigger_type === "onCompleteGame") {
        console.log("TRIGGER - Game Complete");
        if (this.state.gameComplete) {
          const { event_payload } = rule;
          if (event_payload.type === "set_background_color") {
            console.log("Background set to", event_payload.destination);
          }
        }
      }

      // if (automation.time === currentTime){
      //     if (automation.type === "send_message"){
      //       this.props.pushToMessagesFeed('automation', automation.message)
      //     } else if (automation.type === "play_sound"){
      //         assets[automation.sound_id].sound.play()
      //     }
      // }
    });
  };

  startTimer() {
    if (!this.props.remoteLiveWindow) {
      if (!this.state.timerRunning) {
        if (this.timer >= 0 && this.props.timer.seconds > 0) {
          //this.timer = setInterval(this.countDown, 1000);

          this.expected = Date.now() + this.state.interval;
          this.expected_end = Date.now() + this.state.seconds * 1000;
          this.timer = setTimeout(this.countDown2, this.state.interval);

          if (
            this.props.timer.time.m === this.props.timer.startingTime.m &&
            this.props.timer.time.s === this.props.timer.startingTime.s
          ) {
            this.props.handleStartTimerMessage("timer-start");
          }

          if (
            this.props.gameMaster.assets.audio_soundtrack.sound &&
            !this.props.gameMaster.assets.audio_soundtrack.sound.playing()
          ) {
            this.props.gameMaster.assets.audio_soundtrack.sound.play();
          }

          this.setState({
            ...this.state,
            timerStartedAt: Date.now(),
          });

          this.props.updateTimer({
            ...this.props.timer,
            timerStartedAt: Date.now(),
          });
        }
      }
      this.setState({
        timerRunning: true,
        gameIsReady: true,
      });
      this.props.updateTimer({
        ...this.props.timer,
        timerRunning: true,
      });
    } else {
      if (this.timer >= 0 && this.state.seconds > 0) {
        this.timer = setInterval(this.countDown, 1000);

        if (
          this.props.gameMaster.assets.audio_soundtrack.sound &&
          !this.props.gameMaster.assets.audio_soundtrack.sound.playing()
        ) {
          this.props.gameMaster.assets.audio_soundtrack.sound.play();
        }
      }

      this.props.remoteEditTimerIsRunning(true, this.props.id);
    }
  }

  pauseTimer() {
    if (!this.props.remoteLiveWindow) {
      this.setState({ timerRunning: false });
      this.props.updateTimer({
        ...this.props.timer,
        timerRunning: false,
      });
      //clearInterval(this.timer);
      clearTimeout(this.timer);
    } else {
      this.props.remoteEditTimerIsRunning(false, this.props.id);
      clearInterval(this.timer);
    }
  }

  countDown2() {
    const interval = 1000;
    this.adjusted_end = Date.now() + (this.state.seconds - 1) * 1000;
    //console.log('adjusted ', moment(this.adjusted_end).format('LTS'))
    //console.log('expected ', moment(this.expected_end).format('LTS'))

    var dt = 0; // the drift (positive for overshooting)
    if (this.adjusted_end > this.expected_end) {
      //console.log('Adjust > Expected')
      if (this.adjusted_end - this.expected_end > 100)
        dt = this.adjusted_end - this.expected_end;
      //console.log('DRIFTAGE', dt)
    }

    let milliseconds = this.state.seconds * 1000;
    let timeWithDrift = milliseconds - dt;

    let seconds = (timeWithDrift - interval) / 1000;
    //let seconds = this.state.seconds - 1;
    //console.log('adjusted seconds', seconds)
    if (seconds < 1) seconds = 0;

    const currentTime = new Date();
    let minutes = seconds / 60;

    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
      // estimatedFinshTime: currentTime.setMinutes(
      //   currentTime.getMinutes() + minutes
      // ),
    });

    this.props.updateTimer({ ...this.state });

    document.title =
      this.state.time.m +
      ":" +
      this.state.time.s +
      " - " +
      this.props.game_name;

    // Check if we're at zero.
    if (seconds < 1) {
      //clearInterval(this.timer);
      clearTimeout(this.timer);
      this.setState({ timerRunning: false, gameOver: true });
      this.props.updateTimer({ ...this.state });
      this.handleFailGame();
    } else {
      //this.expected += interval;
      //this.timer = setTimeout(this.countDown2, Math.max(0, interval - dt))
      this.timer = setTimeout(this.countDown2, interval - dt);
    }
  }

  countDown() {
    if (!this.props.remoteLiveWindow) {
      let seconds = this.state.seconds - 1;
      const currentTime = new Date();
      let minutes = seconds / 60;

      this.setState({
        time: this.secondsToTime(seconds),
        seconds: seconds,
        estimatedFinshTime: currentTime.setMinutes(
          currentTime.getMinutes() + minutes
        ),
      });

      this.props.updateTimer({ ...this.state });

      // Check if we're at zero.
      if (seconds <= 0) {
        clearInterval(this.timer);
        this.setState({ timerRunning: false, gameOver: true });
        this.props.updateTimer({ ...this.state });
        this.handleFailGame();
      }
    } else {
      let seconds = this.state.seconds - 1;
      const currentTime = new Date();
      let minutes = seconds / 60;

      this.setState({
        time: this.secondsToTime(seconds),
        seconds: seconds,
        estimatedFinshTime: currentTime.setMinutes(
          currentTime.getMinutes() + minutes
        ),
      });

      // Check if we're at zero.
      if (seconds <= 0) {
        clearInterval(this.timer);
        //this.setState({endingText: this.state.endingTextFail})
        this.handleFailGame();
      }
    }
  }

  handleAdjustTimer = (adjusted_time) => {
    const newTime = Number(adjusted_time);
    const newTimeToSeconds = newTime * 60;
    const seconds = Number(this.state.seconds) + newTimeToSeconds;

    this.props.handleAddedMinutes(newTime);

    this.expected_end = Date.now() + seconds * 1000;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    if (this.props.remoteLiveWindow) {
      this.props.remoteAdjustTimer(newTime, this.props.id);
    } else {
      this.props.updateTimer({
        ...this.props.timer,
        time: this.secondsToTime(seconds),
        seconds: seconds,
      });

      var message;
      if (newTime >= 0) {
        const unit = newTime === 1 ? "minute" : "minutes";
        message = "Added " + newTime + " " + unit;
      } else {
        const unit = newTime >= -1 ? "minute" : "minutes";
        message = "Removed " + newTime + " " + unit;
      }
      this.props.handleTimerAdjustMessage("timer-adjust", message, newTime);
    }
  };

  handleChangeTimerSpeed = (speed) => {
    this.setState({ speed: speed });
  };

  handleCompleteGame = (e) => {
    const { assets } = this.props.gameMaster;

    // Stop Soundtrack
    if (assets.audio_soundtrack.sound) assets.audio_soundtrack.sound.stop();
    // Play Success Track
    if (assets.audio_success.sound) assets.audio_success.sound.play();

    // this.setState({
    //   timerRunning: false,
    //   gameComplete: true,
    //   gameSuccess: true,
    //   gameFail: false,
    //   didWinGame: true });

    clearTimeout(this.timer);

    // Send Game Complete Notification
    this.props.handleEndGameMessage("game-ended", "complete");

    // Update Timer Props
    this.props.updateTimer({
      ...this.props.timer,
      time: this.state.time,
      seconds: this.state.seconds,
      estimatedFinshTime: this.state.estimatedFinshTime,
      timerRunning: false,
      gameComplete: true,
      gameSuccess: true,
      gameFail: false,
      endingText: "Success!",
      didWinGame: true,
      gameIsReady: false,
      timerStartedAt: "",
    });
  };

  handleFailGame = () => {
    const { assets } = this.props.gameMaster;

    // Stop Soundtrack
    if (assets.audio_soundtrack.sound) assets.audio_soundtrack.sound.stop();
    // Play Fail Track
    if (assets.audio_fail.sound) assets.audio_fail.sound.play();

    // this.setState({
    //   timerRunning: false,
    //   gameComplete: true,
    //   gameSuccess: false,
    //   gameFail: true,
    //   didWinGame: false
    // });

    clearTimeout(this.timer);

    // Send Game Complete Notification
    this.props.handleEndGameMessage("game-ended", "fail");

    this.props.updateTimer({
      ...this.props.timer,
      time: this.state.time,
      seconds: this.state.seconds,
      estimatedFinshTime: this.state.estimatedFinshTime,
      timerRunning: false,
      gameComplete: true,
      gameSuccess: false,
      gameFail: true,
      endingText: "Game Over",
      didWinGame: false,
      gameIsReady: false,
    });
  };

  handleResetGame = (saveToDatabase) => {
    // Reset Timer
    clearInterval(this.timer);

    const localStorage = loadStateFromLocalStorage();
    // this.setState({
    //   time: localStorage.timer.startingTime,
    //   seconds: localStorage.timer.startingSeconds,
    //   timerRunning: false,
    //   gameOver: false,
    //   timerSpeed: 1000,
    //   gameComplete: false,
    //   gameSuccess: false,
    //   gameFail: false,
    //   estimatedFinshTime: null,
    //   didWinGame: false
    // });

    this.props.updateTimer({
      ...this.props.timer,
      time: localStorage.timer.startingTime,
      seconds: localStorage.timer.startingSeconds,
      timerRunning: false,
      gameOver: false,
      timerSpeed: 1000,
      gameComplete: false,
      gameSuccess: false,
      gameFail: false,
      clueOnScreen: "",
      estimatedFinshTime: null,
      didWinGame: false,
      timerStartedAt: "",
      //gameIsReady: true
    });

    this.props.handleResetChat();
    this.props.handleResetSentClues();
    // this.props.updateClueOnScreenCount({
    //   0: true,
    //   1: true,
    //   2: true,
    // });
    this.props.updateClueOnScreenCount(this.props.timer.clueOnScreenCount);

    //Save game data
    if (navigator.onLine && saveToDatabase) {
      if (this.state.gameComplete) {
        const save_data = {
          timeRemaining: this.state.time,
          didWinGame: this.state.didWinGame,
        };
        this.props.handleSaveGameData(save_data);
      }
    }

    const { assets } = this.props.gameMaster;

    if (assets) {
      // Stop Soundtrack
      if (assets.audio_soundtrack.sound) assets.audio_soundtrack.sound.stop();
      // Stop Fail
      if (assets.audio_fail.sound) assets.audio_fail.sound.stop();
      // Stop Alert
      if (assets.audio_alert.sound) assets.audio_alert.sound.stop();
      // Stop Success
      if (assets.audio_success.sound) assets.audio_success.sound.stop();
    }

    // Reset Start Button
    // setTimeout(() => {
    //   this.setState({
    //     gameIsReady: true
    //   })
    // }, 1000);
  };

  handleStartVideoBrief = () => {
    if (this.props.gameMaster.assets.audio_soundtrack.sound) {
      this.props.gameMaster.assets.audio_soundtrack.sound.pause();
    }

    if (this.props.gameMaster.assets.audio_fail.sound) {
      this.props.gameMaster.assets.audio_fail.sound.pause();
    }

    if (this.props.gameMaster.assets.audio_success.sound) {
      this.props.gameMaster.assets.audio_success.sound.pause();
    }

    //this.props.handlePlayVideoMessage("video-play");

    this.props.updateTimer({
      ...this.props.timer,
      videoIsPlaying: true,
    });

    this.setState({
      videoIsPlaying: true,
      timerStartedAt: Date.now(),
    });

    window.addEventListener("storage", this.videoHasFinished);
  };

  videoHasFinished(e) {
    const localStorage = JSON.parse(e.storageArea.state);

    if (!localStorage.timer.videoIsPlaying) {
      this.setState({
        videoIsPlaying: false,
      });

      this.props.updateTimer({
        ...this.props.timer,
        videoIsPlaying: false,
      });

      this.startTimer();

      window.removeEventListener("storage", this.videoHasFinished);
    }
  }

  handleStopVideoBrief = () => {
    window.removeEventListener("storage", window);

    this.props.updateTimer({
      ...this.props.timer,
      videoIsPlaying: false,
    });

    this.setState({
      videoIsPlaying: false,
    });
  };

  openLiveWindow = () => {
    const { id } = this.props;
    var params = "width=" + (window.screen.width - 200);
    params += ", height=" + (window.screen.height - 200);
    params += ", left=1000";
    params += ", menubar=1";

    this.props.updateTimer({
      ...this.props.timer,
      isPlayerWindowOpen: true,
    });

    this.liveWindow = window.open("/r/" + id + "/live", "windowname4", params);
  };

  render() {
    const {
      timerRunning,
      time,
      gameComplete,
      videoIsPlaying,
      video_brief,
      gameIsReady,
      timerStartedAt,
      //estimatedFinshTime
      puzzlesDoneLength,
    } = this.state;

    const { messages, puzzlesDone, puzzles, hintsUsed, account } = this.props;

    Sentry.setContext("countdown timer state", {
      ...this.props.timer,
    });

    const calculateLength = () => {
      //const startingSeconds = this.state.startingSeconds;
      //const seconds = this.state.seconds;

      const done = puzzlesDone ? puzzlesDone.length : 0;
      const total = puzzles ? puzzles.length : 0;

      //const calculation = (seconds / startingSeconds) * 100;
      const calculation = (done / total) * 100;
      return calculation.toFixed(0);
    };

    const showCompleteButton = () => {
      if (timerRunning && calculateLength() > 99) {
        return (
          <a
            id="completeGameButton"
            href="#gameCompleteModal"
            data-toggle="modal"
            onClick={this.handleCompleteGame}
            className="button-green-dark py-2 w-full block text-center"
          >
            <i class="fa-regular fa-bolt mr-2"></i>
            Complete game
          </a>
        );
      } else if (timerRunning) {
        return (
          <a
            id="completeGameButton"
            href="#gameCompleteModal"
            data-toggle="modal"
            onClick={this.handleCompleteGame}
            className="button-green-dark py-2 w-full block text-center"
          >
            <i class="fa-regular fa-bolt mr-2 text-white"></i>
            Complete game
          </a>
        );
      } else if (!timerRunning && gameComplete) {
        return (
          <button
            id="completeGameButton"
            type="button"
            disabled
            className="button-green-dark py-2 w-full block text-center"
          >
            <i class="fa-regular fa-bolt mr-2 text-white"></i>
            Comeplete game
          </button>
        );
      } else if (!timerRunning) {
        return (
          <button
            id="completeGameButton"
            type="button"
            disabled
            className="button-gray-dark py-2 w-full block text-center"
          >
            {/* <i
              className="icon ion-ios-flash"
              style={{ marginRight: "10px" }}
            ></i> */}
            <i class="fa-regular fa-bolt mr-2 text-white"></i>
            Complete game
          </button>
        );
      }
    };

    const ShowVideoButton = (props) => {
      const { videoBriefExists } = props.state;
      console.log("Video Existss", videoBriefExists);

      if (account.subscription.status !== "active") {
        return (
          <Tooltip
            title={
              <div className="flex items-center space-x-2">
                <p>Play briefing video</p>
                {account.subscription.status !== "active" && (
                  <div className="bg-green-600 border border-green-400 px-2 rounded">
                    <i class="fa-regular fa-lock mr-1"></i>Premium
                  </div>
                )}
              </div>
            }
            placement="bottom"
            arrow
          >
            <a
              href="#proFeatureOnly"
              data-toggle="modal"
              className="button-outline-dark opacity-50 hover:opacity-100 text-center cursor-pointer"
            >
              <i class="fa-regular fa-lock mr-2"></i>
              Briefing Video
            </a>
          </Tooltip>
        );
      } else if (gameComplete) {
        return (
          <button disabled type="button" className="button-outline-dark ">
            <i class="fa-sharp fa-regular fa-play mr-2"></i>
            Briefing Video
          </button>
        );
      } else if (videoBriefExists && video_brief && !videoIsPlaying) {
        return (
          <button
            onClick={this.handleStartVideoBrief}
            type="button"
            className="button-gray-dark"
          >
            <i class="fa-sharp fa-regular fa-play mr-2"></i>
            Briefing Video
          </button>
        );
      } else if (videoBriefExists && video_brief && videoIsPlaying) {
        return (
          <button
            onClick={this.handleStopVideoBrief}
            type="button"
            className="button-red-dark"
          >
            <i class="fa-sharp fa-solid fa-stop mr-2"></i>
            Stop
          </button>
        );
      } else if (!videoBriefExists || !video_brief) {
        return (
          <button disabled type="button" className="button-outline-dark ">
            <i class="fa-sharp fa-regular fa-play mr-2"></i>
            Briefing Video
          </button>
        );
      }
    };

    const showResetButton = () => {
      if (gameComplete) {
        return (
          <button
            onClick={this.handleResetGame}
            type="button"
            className="button-outline-dark w-full"
          >
            Reset game
          </button>
        );
      } else if (timerRunning) {
        return (
          <button
            onClick={this.handleResetGame}
            type="button"
            disabled
            className="button-outline-dark w-full"
          >
            Reset game
          </button>
        );
      } else {
        return (
          <button
            onClick={this.handleResetGame}
            type="button"
            className="button-outline-dark w-full"
          >
            Reset game
          </button>
        );
      }
    };

    return (
      <React.Fragment>
        <div
          className="bottom-border"
          style={{
            marginBottom: "0px",
            padding: "20px 15px 20px 15px",
          }}
        >
          <div className="w-full">{showCompleteButton()}</div>

          <div className="my-4 text-center">
            <div className="row" style={{ marginBottom: "0px" }}>
              <div className="col-4" style={{ padding: "0px 3px" }}>
                <p className="text-lg text-white" style={{ marginBottom: "0" }}>
                  {puzzlesDone ? puzzlesDone.length : 0}/
                  {puzzles ? puzzles.length : 0}
                </p>
                <p className="text-xs text-gray-500">Puzzles Done</p>
              </div>
              <div className="col-4" style={{ padding: "0px 3px" }}>
                <p className="text-lg text-white">{hintsUsed}</p>
                <p className="text-xs text-gray-500">Messages Sent</p>
              </div>
              <div className="col-4" style={{ padding: "0px 3px" }}>
                <p className="text-lg text-white">{this.props.addedMinutes}</p>
                <p className="text-xs text-gray-500">Time Adjusted</p>
              </div>
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <button
              id="openLiveWindow"
              onClick={this.openLiveWindow}
              type="button"
              className="button-grayBlue-dark"
              style={{ marginBottom: "0px" }}
            >
              Open player window
              <i
                className="icon ion-md-open"
                style={{ marginLeft: "8px", color: "white" }}
              ></i>
            </button>

            <ShowVideoButton state={this.state} />
          </div>

          {/* <div
            className="btn-group btn-group-sm w-100"
            role="group"
            aria-label="Basic example"
            style={{ marginBottom: "0px" }}
          >
            <button
              id="openLiveWindow"
              onClick={this.openLiveWindow}
              type="button"
              className="button-gray-dark"
              style={{
                fontSize: "12px",
                padding: "0px 10px",
                height: "25px",
              }}
            >
              <b>Open Player Window</b>
              <i
                className="icon ion-md-open"
                style={{ marginLeft: "5px", color: "white" }}
              ></i>
            </button>

            {showResetButton()}
          </div> */}
        </div>

        <div
          id="timer-box"
          style={{
            textAlign: "center",
            marginBottom: "10px",
            padding: "15px",
            borderBottom: "1px solid #404040",
          }}
        >
          {/* <div style={{textAlign:'left'}}>
  <p className="text-gray-300">Timer</p>
</div> */}

          <div className="card-body" style={{ padding: "0px" }}>
            <div style={{ textAlign: "center" }}>
              <div
                className="mb-2"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {timerRunning ? (
                  <h1
                    style={{
                      fontSize: "75px",
                      padding: "0px 0px 0px 0px",
                      marginBottom: "0px",
                      letterSpacing: "-3px",
                      color: this.state.seconds <= 179 ? "#ff1930" : "#d8d8d8",
                      fontWeight: this.state.seconds <= 179 ? "700" : "500",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {/* <i class="fa-regular fa-hourglass" style={{fontSize:'40px', marginRight:'5px'}}></i> */}
                    {time.m}:{time.s}
                  </h1>
                ) : (
                  <h1
                    style={{
                      fontSize: "75px",
                      padding: "0px 0px 0px 0px",
                      marginBottom: "0px",
                      opacity: "0.5",
                      letterSpacing: "-3px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {/* <i class="fa-regular fa-hourglass" style={{fontSize:'40px', marginRight:'5px'}}></i> */}
                    {time ? time.m : "00"}:{time ? time.s : "00"}
                  </h1>
                )}
              </div>

              {/* <div
                  style={{ 
                    marginBottom: "10px",
                    display:'flex',
                    flexDirection:'row'
                  }}
                >

    <StartButtonNew
            timerRunning={timerRunning}
            videoIsPlaying={videoIsPlaying}
            gameIsReady={gameIsReady}
            startTimer={this.startTimer}
            pauseTimer={this.pauseTimer}
            gameComplete={gameComplete}
          />    

          <StopButtonNew
            timerRunning={timerRunning}
            videoIsPlaying={videoIsPlaying}
            gameIsReady={gameIsReady}
            startTimer={this.startTimer}
            pauseTimer={this.pauseTimer}
            gameComplete={gameComplete}
          />                
                </div> */}

              <AdjustTimer
                handleAdjustTimer={this.handleAdjustTimer}
                handleChangeTimerSpeed={this.handleChangeTimerSpeed}
              />

              <div
                className="flex flex-col space-y-2"
                style={{ marginBottom: "5px", textAlign: "center" }}
              >
                <StartButton
                  timerRunning={timerRunning}
                  videoIsPlaying={videoIsPlaying}
                  gameIsReady={gameIsReady}
                  startTimer={this.startTimer}
                  pauseTimer={this.pauseTimer}
                  gameComplete={gameComplete}
                />

                {/* <ShowVideoButton state={this.state} /> */}

                {showResetButton()}

                {/* <div
                  className="btn-group btn-group-sm w-100"
                  role="group"
                  aria-label="Basic example"
                  style={{ marginBottom: "0px" }}
                >
                  {!this.props.remoteLiveWindow ? (
                    <StartButton
                      timerRunning={timerRunning}
                      videoIsPlaying={videoIsPlaying}
                      gameIsReady={gameIsReady}
                      startTimer={this.startTimer}
                      pauseTimer={this.pauseTimer}
                      gameComplete={gameComplete}
                    />
                  ) : (
                    <StartButton
                      timerRunning={this.props.remote.timerRunning}
                      videoIsPlaying={this.props.remote.videoIsPlaying}
                      startTimer={this.startTimer}
                      pauseTimer={this.pauseTimer}
                      gameComplete={gameComplete}
                      videoButtonLoading={this.props.remote.videoButtonLoading}
                    />
                  )}
                  <ShowVideoButton />
                </div> */}

                {/* <div style={{marginTop:'10px'}}>
{showCompleteButton()}
</div> */}

                {/* <div className='col-6' style={{paddingLeft:'5px'}}>
                    <ShowVideoButton />
                  </div> */}
              </div>
            </div>
          </div>
        </div>

        {/* <AdjustTimer
          handleAdjustTimer={this.handleAdjustTimer}
          handleChangeTimerSpeed={this.handleChangeTimerSpeed}
        /> */}

        {/* <div style={{ 
          marginBottom: "10px", 
          padding:'10px 20px 10px 20px', 
          borderBottom:'1px solid black' 
          }}>

        {showCompleteButton()}
          
        </div> */}

        {/* <div style={{ marginBottom: "10px", borderBottom:'1px solid black', padding:'0px 20px 10px 20px' }}>{showCompleteButton()}</div> */}

        {/* <AdjustTimer
          handleAdjustTimer={this.handleAdjustTimer}
          handleChangeTimerSpeed={this.handleChangeTimerSpeed}
        /> */}

        <GameCompleteModal
          time={time}
          handleResetGame={this.handleResetGame}
          messages={messages}
          puzzles={puzzles}
          puzzlesDone={puzzlesDone}
          hintsUsed={hintsUsed}
          addedMinutes={this.props.addedMinutes}
        />

        {/* <VideoAssetsBox /> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log(state)

  // const gm = state.firestore;
  // const id = gm ? gm[0].id : null

  return {
    gameMaster: state.gameMaster,
    timer: state.timer,
    remote: state.remote,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTimer: (timer) => dispatch(updateTimer(timer)),
    audio_control: (type, audio) => dispatch(audio_control(type, audio)),
    completeGame: (state) => dispatch(completeGame(state)),
    remoteEditTimerIsRunning: (bool, doc) =>
      dispatch(remoteEditTimerIsRunning(bool, doc)),
    remoteAdjustTimer: (string, doc) =>
      dispatch(remoteAdjustTimer(string, doc)),
    updateClueOnScreenCount: (clueCountArray) =>
      dispatch(updateClueOnScreenCount(clueCountArray)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CountdownTimer);
