import React from 'react'

const ExitToDashboardModal = (props) => {

   //const { id, puzzles, handleAddNewClue } = props;


    return (
<div className="modal effect-scale" id="ExitToDashboardModal" tabIndex="-1" role="dialog" aria-hidden="true">

      <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
        <div className="modal-content">

            
          <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
            <a href="/" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </a>
            <div className="media align-items-center">
              <span className="tx-color-03 d-none d-sm-block">
                <i className="icon ion-ios-exit" style={{fontSize:"30px"}}></i></span>
              <div className="media-body mg-sm-l-20">
                <h4 className="tx-18 tx-sm-20 mg-b-2">Are you sure you want to exit?</h4>
              </div>
            </div>
          </div>

          {/* <ul className="nav" id="myTab" role="tablist">
        <li className="nav-item">
            <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">
                <i className="icon ion-ios-send" style={{marginRight:"5px"}}></i>
                Send</a>
        </li>
        <li className="nav-item">
            <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">
            <i className="icon ion-md-options" style={{marginRight:"5px"}}></i>
                Audio</a>
        </li>
        <li className="nav-item">
            <a className="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">
            <i className="icon ion-ios-videocam" style={{marginRight:"5px"}}></i>
            Video</a>
        </li>
        </ul> */}


         
          <div className="modal-footer pd-x-20 pd-y-15">
            <button type="button" className="button-outline-dark" data-dismiss="modal"><b>Cancel</b></button>
            <a href="/" className="button-blue-dark"><b>Yes</b></a>
          </div>
        </div>
      </div>
    </div>
    )
}


// const mapDispatchToProps = (dispatch) => {
//     return {
//         addNewClue: (data, id) => dispatch(addNewClue(data, id))
//     }
// }

// export default connect(null, mapDispatchToProps)(AddClueModal)
export default ExitToDashboardModal