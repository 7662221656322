import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { classNames } from "../../../../utils/Utils";
import { RadioGroup } from "@headlessui/react";

const plans = [
  { name: "I have a company", type: "company" },
  { name: "I'm an individual", type: "individual" },
];

export default function CompanyInfo(props) {
  const { companyInfo, setCompanyInfo, selected, setSelected } = props;

  const [loading, setLoading] = useState(false);
  const [locationData, setLocationData] = useState();

  let autocomplete;

  useEffect(() => {
    autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("autocomplete"),
      {types: ['(regions)']}
    );
    // autocomplete.addListener("place_changed", handlePlaceSelect())
    google.maps.event.addListener(autocomplete, "place_changed", function () {
      let addressObject = autocomplete.getPlace();
      let address = addressObject.address_components;
      console.log('address', addressObject)
      setLocationData({
        city: address[0].long_name,
        location: addressObject.formatted_address,
        googleMapLink: addressObject.url,
        place_id: addressObject.place_id
      });
    });
  }, []);

  useEffect(()=> {

    setCompanyInfo({
        ...companyInfo,
        ...locationData
    })

  }, [locationData])

  const handleChange = (e) => {
    setCompanyInfo({ ...companyInfo, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // this.props.dispatch(addParlor(this.state))
    // this.clearForm()
  };


  return (
    <div>
      <p className="text-2xl font-medium mb-4">Company information</p>

      <form onSubmit={() => handleSubmit()}>
        <div className="mb-3">
          <p className="mb-1">Company Name *</p>
          <input
            name={"name"}
            className="input-light auth-input shadow-sm w-full"
            placeholder="Escape Room NYC"
            //   onChange={(e) =>
            //     setCompanyInfo({ ...companyInfo, name: e.target.value })
            //   }
            onChange={(e) => handleChange(e)}
            value={companyInfo.name}
          ></input>
        </div>

        <div className="mb-3">
          <p className="mb-1">Company Website</p>
          <input
            name={"website"}
            className="input-light auth-input shadow-sm w-full"
            placeholder="https://your-website.com"
            //   onChange={(e) =>
            //     setCompanyInfo({ ...companyInfo, name: e.target.value })
            //   }
            onChange={(e) => handleChange(e)}
            value={companyInfo.website}
          ></input>
        </div>

        <div className="mb-3">
        <p className="mb-1">Where are you located? *</p>
            <input
              id="autocomplete"
              name={"location"}
              className="input-light auth-input shadow-sm w-full"
              placeholder="New York City, NY"
            //   onChange={(e) =>
            //     setCompanyInfo({ ...companyInfo, city: e.target.value })
            //   }
              onChange={(e) => handleChange(e)}
              value={companyInfo.location}
            ></input>
        </div>

        <div className="grid grid-cols-2 space-x-3 mb-3">
          <div className="col-span-1">
            <p className="mb-1">Type of escape room *</p>
            <select
                name={"type"}
                className="input-light auth-input shadow-sm w-full"
                placeholder="USA"
                // onChange={(e) =>
                //   setCompanyInfo({ ...companyInfo, country: e.target.value })
                // }
                onChange={(e) => handleChange(e)}
                value={companyInfo.type}
            >
                <option>Select option</option>
                <option>Brick and mortar</option>
                <option>Mobile escape room</option>
            </select>
          </div>
          <div className="col-span-1">
          <p className="mb-1">How many rooms? *</p>
            <select
                name={"size"}
                className="input-light auth-input shadow-sm w-full"
                placeholder="USA"
                // onChange={(e) =>
                //   setCompanyInfo({ ...companyInfo, country: e.target.value })
                // }
                onChange={(e) => handleChange(e)}
                value={companyInfo.size}
            >
                <option>Select option</option>
                <option>1-2</option>
                <option>3-5</option>
                <option>5-10</option>
                <option>10+</option>
            </select>
          </div>
        </div>

      </form>
      <div className="mt-4 flex space-x-4">
      <button
                  onClick={()=> window.history.back()}
                  className="button-outline-light"
                >
        
                  <span>Back</span>
                </button>

        {companyInfo.name && companyInfo.type && companyInfo.size && companyInfo.location ? (
            <Link
            to={"?step=3&type=" + selected.type}
            //disabled={!companyInfo.name ? true : false}
            className={classNames(
              "auth-button duration-150 float-right flex justify-center disabled:opacity-50",
              !companyInfo.name && !companyInfo.location && !companyInfo.size
                ? "pointer-events-none bg-gray-100 border border-gray-300 text-gray-300 hover:text-black"
                : "bg-black border border-black text-white hover:bg-gray-500 hover:text-black"
            )}
          >
            <svg
              className={classNames(
                !loading ? "hidden" : "w-[22px] fill-gray-300  animate-spin"
              )}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M256 64c106 0 192 86 192 192h64C512 114.6 397.4 0 256 0V64zM448 256c0 35-9.4 67.8-25.7 96L477.7 384c21.8-37.7 34.3-81.5 34.3-128H448z" />
            </svg>
  
            <span className={classNames(loading ? "hidden" : null)}>Next</span>
          </Link>
        ) : (
            <button

        disabled
          className={classNames(
            "auth-button duration-150 float-right flex justify-center opacity-50 auth-button",
            !companyInfo.name && !companyInfo.location && !companyInfo.size
              ? "pointer-events-none bg-gray-100 border border-gray-300 text-gray-300 hover:text-black"
              : "bg-black border border-black text-white hover:bg-gray-500 hover:text-black"
          )}
        >
          <svg
            className={classNames(
              !loading ? "hidden" : "w-[22px] fill-gray-300  animate-spin"
            )}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M256 64c106 0 192 86 192 192h64C512 114.6 397.4 0 256 0V64zM448 256c0 35-9.4 67.8-25.7 96L477.7 384c21.8-37.7 34.3-81.5 34.3-128H448z" />
          </svg>

          <span className={classNames(loading ? "hidden" : null)}>Next</span>
        </button>
        )}
      </div>
    </div>
  );
}