import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import {updateUserPermissions} from '../../../redux/actions/settingsActions'

const UpdateUser = (props) => {

    const { selectedUser, account } = props;
    
    const [data, setData] = useState({ 
        isAdmin: true,
        role: 'Admin',
        canEditHints: true,
        canEditPuzzles: true,
        canEditLiveWindow: true
    })

    const [isAccountOwner, setIsAccountOwner] = useState(false)

    useEffect(() => {

        if (selectedUser && selectedUser.permissions !== []){
            setData(selectedUser ? selectedUser.permissions : { 
                isAdmin: true,
                role: 'Admin',
                canEditHints: true,
                canEditPuzzles: true,
                canEditLiveWindow: true
            })
        }

        if (selectedUser){
            setIsAccountOwner(selectedUser.user.email === account.account_email ? true : false)
        }
        
    }, [selectedUser])

    const setAdmin = (e) => {
        if (e === 'Admin') {
            setData({
                ...data,
                isAdmin: true,
                role: 'Admin',
                canEditHints: true,
                canEditPuzzles: true,
                canEditLiveWindow: true
            })
        } else if (e === 'Staff') {
            setData({
                ...data,
                isAdmin: false,
                role: 'Staff'
            })
        }
    }

    const setPuzzles = (e) => {
        if (e === 'true') {
            setData({
                ...data,
                canEditPuzzles: true
            })
        } else if (e === 'false') {
            setData({
                ...data,
                canEditPuzzles: false
            })
        }
    }

    const setHints = (e) => {
        if (e === 'true') {
            setData({
                ...data,
                canEditHints: true
            })
        } else if (e === 'false') {
            setData({
                ...data,
                canEditHints: false
            })
        }
    }

    const setLiveWindow = (e) => {
        if (e === 'true') {
            setData({
                ...data,
                canEditLiveWindow: true
            })
        } else if (e === 'false') {
            setData({
                ...data,
                canEditLiveWindow: false
            })
        }
    }



    console.log('selected', selectedUser)
    console.log('accoutn owner?', isAccountOwner)

  return (
    <div
      className="modal effect-scale"
      id="EditUserModal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{width:'400px'}}
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body pd-sm-t-30 pd-sm-b-20 pd-sm-x-30">
            <h4>Edit User</h4>
            <div className="form-group">
              <label className="mg-b-5 mg-t-10">
                Email Address
              </label>
              <input
                disabled
                type="text"
                className="form-control"
                value={selectedUser ? selectedUser.user.email : null}
              ></input>

            </div>

            <div className="form-group">

            <div className="row">
                <div className="col-6">
                <label className="mg-b-5 mg-t-10">
                    Role
                </label>
                </div>

                <div className="col-6">
                <select
                    disabled={isAccountOwner ? true : false}
                    onChange={(e) => setAdmin(e.target.value)}
                    class="custom-select"
                    value={data ? data.role : null}
                    >
                        <option value="Admin">Admin</option>  
                        <option value="Staff">Staff</option>
                        
                </select>
                    </div>
            </div>

        </div>

        {!data.isAdmin && (
            <div className="form-group">
            <div className="row">
                <div className="col-6">
                    <label className="mg-b-5 mg-t-10">
                        Puzzles
                    </label>
                </div>

                <div className="col-6">
                    <select
                        onChange={(e) => setPuzzles(e.target.value)}
                        class="custom-select"
                        value={data.canEditPuzzles}
                        >
                        <option value={true}>Read / Write</option>  
                        <option value={false}>Read Only</option> 
                    </select>
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <label className="mg-b-5 mg-t-10">
                        Hints
                    </label>
                </div>

                <div className="col-6">
                    <select
                        onChange={(e) => setHints(e.target.value)}
                        class="custom-select"
                        value={data.canEditHints}
                        >
                        <option value={true}>Read / Write</option>  
                        <option value={false}>Read Only</option> 
                    </select>
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <label className="mg-b-5 mg-t-10">
                        Live Window Settings
                    </label>
                </div>

                <div className="col-6">
                    <select
                        onChange={(e) => setLiveWindow(e.target.value)}
                        class="custom-select"
                        value={data.canEditLiveWindow}
                        >
                        <option value={true}>Read / Write</option>  
                        <option value={false}>Read Only</option> 
                    </select>
                </div>
            </div>
            </div>
        )}




          </div>

          <div className="modal-footer pd-x-20 pd-y-15">
          {/* <button
            disabled={isAccountOwner ? true : false}
              type="button"
              className="btn btn-white"
              data-dismiss="modal"
              style={{marginRight:'auto'}}
              onClick={() => props.updateUserPermissions(data, selectedUser.user.id, 'delete')}
            >
              <b>Delete User</b>
            </button> */}
            <button
              type="button"
              className="btn btn-white"
              data-dismiss="modal"
            >
              <b>Cancel</b>
            </button>
            <button
                disabled={isAccountOwner ? true : false}
              type="button"
              className="btn btn-primary"
              data-dismiss="modal"
              onClick={() => props.updateUserPermissions(data, selectedUser.user.id)}
            >
              <b>Save</b>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserPermissions: (newPermissions, uid, type) => dispatch(updateUserPermissions(newPermissions, uid, type)),
  };
};

export default connect(null, mapDispatchToProps)(UpdateUser);
