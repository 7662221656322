const initState = {
    endgame_data_upload: false,
    assets: {
        audio_alert: {
            sound: null,
            vol: null
        },
        audio_soundtrack: {
            sound: null,
            vol: null
        },
        audio_success: {
            sound: null,
            vol: null
        },
        audio_fail: {
            sound: null,
            vol: null
        },
        video_brief: null
    },
    new_assets: {
        audio_alert: {
            sound: null,
            vol: null
        },
        audio_soundtrack: {
            sound: null,
            vol: null
        },
        audio_success: {
            sound: null,
            vol: null
        },
        audio_fail: {
            sound: null,
            vol: null
        },
        video_brief: null
    },
    livewindow_settings: {},
    add_new_puzzle: '',
}

const gmReducer = (state = initState, action) => {
    switch (action.type) {
        
        case 'UPDATE_ASSETS':
            //console.log("Updated assets!", action.assets)
            return {
                ...state,
                ...action.assets,
            }

        case 'ADD_NEW_CLUE':
            //console.log("ADD_NEW_CLUE")
            return state;

        case 'ADD_NEW_CLUE_ERROR':
            //console.log("ADD_NEW_CLUE ERROR", action.error)
            return state;

        case 'ADD_NEW_PUZZLE':
            //console.log("ADD_NEW_PUZZLE")
            return {
                ...state,
                add_new_puzzle: action.status
            }

        case 'ADD_NEW_PUZZLE_ERROR':
            //console.log("ADD_NEW_PUZZLE ERROR", action.error)
            return state;

        case 'UPDATE_PUZZLE_NAME':
            //console.log("Updated Puzzle Name")
            return state;


        case 'ADD_NEW_ROOM':
            //console.log("ADD_NEW_ROOM")
        return state;

        case 'ADD_NEW_ROOM_ERROR':
            //console.log("ADD_NEW_ROOM ERROR", action.error)
            return state;

        case 'DELETE_ROOM':
            //console.log("Delete Room")
        return state;

        case 'DELETE_ROOM_ERROR':
            //console.log("Delete Room ERROR", action.error)
            return state;

        case 'AUDIO_CONTROL':
            //console.log("Audio Control")
        return state;

        case 'UPLOAD_FILES':
            //console.log("File Uploaded")
        return state;

        case 'UPLOAD_FILES_ERROR':
            //console.log("File Upload Error", state.err)
        return state;

        case 'COMPLETE_DATA_UPLOAD':
            //console.log("Game Data Saved")
        return {
            ...state,
            endgame_data_upload: action.status
        }

        case 'COMPLETE_DATA_UPLOAD_ERROR':
            //console.log("Game Data Save Error", state.err)
        return state;

        case 'GET_ASSETS_DATA':
            //console.log("NEW assets!", action.new_assets)
            return {
                ...state,
                new_assets: action.new_assets,
            }
        
        case 'GET_LIVEWINDOW_SETTINGS':
            //console.log("NEW assets!", action.new_assets)
            return {
                ...state,
                livewindow_settings: action.settings,
            }


        default:
            return state;
    }
}

export default gmReducer