import React from 'react'
import { useRouteMatch } from 'react-router-dom';
import SubsModal from '../modals/SubsModal'

function NotActiveSubBlock(props) {

    const {stripe_id, handleCheckoutClick, subscription_status} = props;

    const checkUserPermissions = () =>{
      const account = props.account;
      const permissions = account && account.permissions ? account.permissions: null;
  
      function search(id, myArray){
          for (var i=0; i < myArray.length; i++) {
              if (myArray[i].user_id === id) {
                  return myArray[i];
              }
          }
      }
      
      if (permissions){
          const gm_permissions = search(props.uid, permissions)
          if (gm_permissions) return gm_permissions
      } else {
          return { isAdmin: true }
      }
  
  }

    return (
        <div class="billing-subscription-block">
              <div class="div-block-13">
                <h2 class="heading-5" style={{paddingBottom:'0px'}}>You're not subscribed</h2>
                
                {checkUserPermissions().role === 'Admin' || checkUserPermissions().isAdmin ? (<button
                  href="#subsModal"
                  data-toggle="modal"
                  style={{border:'none'}}
                  className="account-button-blue w-button"
                >
                  <b>Add a Plan</b>
                </button>) : null}
                  
              </div>
              {/* <ul role="list">
                <li>
                    {plan_type() === 'Starter Plan' && (<p class="paragraph-9">1 game rooms</p>)}
                    {plan_type() === 'Grow Plan' && (<p class="paragraph-9">5 game rooms</p>)}
                    {plan_type() === 'Scale Plan' && (<p class="paragraph-9">10 game rooms</p>)}
                </li>
                <li>
                  <p class="paragraph-10">${subscription_amount}/mo</p>
                </li>
              </ul> */}
              {/* <p class="paragraph-11">Your next payment is on <strong>{current_period_end}</strong></p> */}

              <SubsModal handleCheckoutClick={handleCheckoutClick} stripe_id={stripe_id} subscription_status={subscription_status} />
            </div>
    )
}

export default NotActiveSubBlock
