import React from "react";

import Hints from './Hints'

function HintPreview(props) {

    const { backgroundColor, backgroundImage, settings, game_data, textColor, hintSize, hintTimerBGColor, hintPadding, hintTimerTextColor, customIcon } = props;

  return (
    <div
      id="lw-bgimage"
      style={{
        width: "570px",
        height: "320px",
        display: "flex",
        justifyContent:'center',
        alignItems: "center",
        backgroundColor: backgroundColor,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundImage:
          backgroundImage &&
          backgroundImage.type &&
          backgroundImage.type.startsWith("image/")
            ? "url(" + backgroundImage.url + ")"
            : null,
        borderRadius: "8px",
        margin: "0 auto",
        position:'relative'
      }}
    >
      {backgroundImage &&
      backgroundImage.type &&
      backgroundImage.type.startsWith("video/") ? (
        <video
          style={{
            minWidth: "100%",
            borderRadius: "8px",
          }}
          muted
          preload="auto"
          //ref="vidRef"
          id="lw-bg-video"
          autoPlay={true}
          loop={true}
          src={backgroundImage ? backgroundImage.url : null}
          //type="video/mp4"
        ></video>
      ) : null}



<div style={{ 
                    position: "absolute", 
                    top: "12px", 
                    right: "12px",
                    background: hintTimerBGColor,
                    padding: '10px 15px',
                    borderRadius:'10px',
                    display: settings && settings.timer && !settings.timer.show_hintTimer ? 'none' : null,
                  }}
                >

                    <h1 style={{
                        fontSize: "22px", 
                        fontWeight: "bold",
                        color: hintTimerTextColor,
                        fontFamily: settings && settings.timer && settings.timer.font ? settings.timer.font : null,
                        marginBottom:'0'}}>
                      22:43
                    </h1>
                </div>



      <div
        style={{
          margin: "0 auto",
          textAlign: "center",
          display: settings && settings.timer && settings.timer.show !== true ? "none" : "block",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width:'100%'
        }}
      >


                <div
                  id="clueBox"
                  className="row"
                  style={{
                    marginRight:'0px',
                    marginLeft:'0px',
                    padding: '0px ' + hintPadding + 'px'
                  }}
                >
                  <div className="col-sm-12">
                    <div
                      className="text-center"
                      style={{
                        marginBottom: "10px !important",
                      }}
                    >
                      <h1 style={{ 
                        fontSize: hintSize +'px',
                        color: textColor, 
                        whiteSpace: 'pre-wrap',
                        fontFamily: settings && settings.timer && settings.timer.hintFont ? settings.timer.hintFont : null
                         }}>
                        Mother knows best.
                      </h1>
                    </div>
                  </div>
                </div>

      </div>

      {settings && settings.timer && settings.timer.show_hintIcons && (
        <Hints clueOnScreenCount={[ true, true, true ]} settings={settings} customIcon={customIcon} />
      )}


    </div>
  );
}

export default HintPreview;
