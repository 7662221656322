import React, { useState } from "react";
import { connect } from "react-redux";

const InvoiceSettings = (props) => {

  return (
    <div
      className="modal effect-scale"
      id="InvoiceSettingsModal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{width:'400px'}}
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body pd-sm-t-30 pd-sm-b-20 pd-sm-x-30">
            <h4>Invoice Settings</h4>
            <div className="form-group">
              <label className="mg-b-5 mg-t-10">
                Company Name
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter company name"
              ></input>

            </div>

            <div className="form-group">
              <label className="mg-b-5 mg-t-10">
                Company Address
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Company Address"
              ></input>

            </div>

            <div className="form-group">
              <label className="mg-b-5 mg-t-10">
                Billing Email
              </label>
              <input
                disabled
                type="text"
                className="form-control"
                value="hudson@escapequestdc.com"
              ></input>

            </div>

            <div className="form-group">
              <label className="mg-b-5 mg-t-10">
                VAT Number
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Value Added Tax Number"
              ></input>

            </div>



          </div>

          <div className="modal-footer pd-x-20 pd-y-15">
            <button
              type="button"
              className="btn btn-white"
              data-dismiss="modal"
            >
              <b>Cancel</b>
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-dismiss="modal"
            >
              <b>Save</b>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// const mapDispatchToProps = (dispatch) => {
//   return {
//     addNewRoom: (name, timer) => dispatch(addNewRoom(name, timer)),
//   };
// };

export default connect(null, null)(InvoiceSettings);
