import { loadStripe } from "@stripe/stripe-js";

// Check is User is Subscribed
export const isSubscribed = (account) => {
  const plan_type = account ? account.subscription.product_id : null;

  if (plan_type === "prod_JDMsnc2gCLWWkA" || plan_type === undefined)
    return false;
  if (plan_type === "prod_JDMvOig4rtxg0U") return true;
  if (plan_type === "prod_JDMw9iECHFJwCs") return true;
};

export const handleCheckoutClick = async (customer_id, price_id, subscriptionStatus) => {
  const stripePromise = loadStripe(
    "pk_live_51IalDdGI8KW4Qd3J28lgIKcaZ3LzTul98KOFNm2DJZZsrRPT9AyIf8mohCscZWAgGUQRfDs4ywwNQtM0lUcXmVbS00is33DZeV"
  );
  console.log("Handle Checkout", customer_id, price_id);

  const stripe = await stripePromise;

  return fetch(
    "https://us-central1-escaperoomwaiver-v2.cloudfunctions.net/stripe_CheckoutSession",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        line_items: [
          {
            price: price_id,
            quantity: 1,
          },
        ],
        customer_id: customer_id,
        subscriptionStatus: subscriptionStatus
      }),
    }
  )
    .then(function (result) {
      return result.json();
    })
    .then((data) => {
      stripe
        .redirectToCheckout({
          sessionId: data.sessionId,
        })
        .then((result) => console.log("success", result));
    });
};

export const checkBrowswer = () => {
  if (
    (navigator.userAgent.indexOf("Opera") ||
      navigator.userAgent.indexOf("OPR")) !== -1
  ) {
    return "Opera";
  } else if (navigator.userAgent.indexOf("Edg") !== -1) {
    return "Edge";
  } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
    return "Chrome";
  } else if (navigator.userAgent.indexOf("Safari") !== -1) {
    return "Safari";
  } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    return "Firefox";
  } else if (
    navigator.userAgent.indexOf("MSIE") !== -1 ||
    !!document.documentMode === true
  ) {
    //IF IE > 10
    return "IE";
  } else {
    return "unknown";
  }
};

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const generateVideoThumbnail = (file) => {
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    const video = document.createElement("video");

    // this is important
    video.autoplay = true;
    video.muted = true;
    video.src = URL.createObjectURL(file);

    video.onloadeddata = () => {
      if (canvas == null) return;
      let ctx = canvas.getContext("2d");
      if (ctx == null) return; // context may be null
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      video.pause();
      return resolve(canvas.toDataURL("image/png"));
    };
  });
};


export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const formatTime = (timeInSeconds) => {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = Math.floor(timeInSeconds % 60);
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};


export function generateColorBasedOnBackground(backgroundColor, brightnessChange) {
  // Parse the background color
  const bg = new Color(backgroundColor);

  // Adjust the brightness based on the input value (brightnessChange)
  bg.adjustBrightness(brightnessChange);

  // Return the resulting color in hex format
  return bg.toHexString();
}

class Color {
  constructor(hex) {
    // Convert the hex color to RGB
    this.r = parseInt(hex.slice(1, 3), 16);
    this.g = parseInt(hex.slice(3, 5), 16);
    this.b = parseInt(hex.slice(5, 7), 16);

    // Convert RGB to HSL
    const { h, s, l } = this.rgbToHsl(this.r, this.g, this.b);
    this.h = h;
    this.s = s;
    this.l = l;
  }

  // Function to adjust the hue
  adjustHue(angle) {
    this.h = (this.h + angle) % 360;
  }

  // Function to convert RGB to HSL
  rgbToHsl(r, g, b) {
    r /= 255;
    g /= 255;
    b /= 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
      }
      h /= 6;
    }

    return { h: h * 360, s: s * 100, l: l * 100 };
  }

  // Function to convert HSL to hex
  toHexString() {
    const { r, g, b } = this.hslToRgb(this.h / 360, this.s / 100, this.l / 100);
    return `#${this.componentToHex(r)}${this.componentToHex(g)}${this.componentToHex(b)}`;
  }

  componentToHex(c) {
    const hex = Math.round(c * 255).toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }

  hslToRgb(h, s, l) {
    let r, g, b;

    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      const hue2rgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };

      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;

      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    return {
      r: Math.round(r * 255),
      g: Math.round(g * 255),
      b: Math.round(b * 255)
    };
  }
}

// Update the Color class with a new method to adjust brightness
Color.prototype.adjustBrightness = function (brightnessChange) {
  // Adjust the lightness value by the specified brightness change (positive for lighter, negative for darker)
  this.l += brightnessChange;

  // Ensure the lightness value stays within the valid range [0, 100]
  this.l = Math.min(Math.max(this.l, 0), 100);
};

export function extractFirstCharacters(inputString) {
  // Split the input string into an array of words
  const words = inputString.split(' ');

  // Initialize an empty array to store the first characters
  const firstCharacters = [];

  // Loop through the words and extract the first character of each word
  for (const word of words) {
    if (word.length > 0) {
      firstCharacters.push(word[0]);
    }
  }

   // Take the first two characters from the array
   const result = firstCharacters.slice(0, 2).join('');

  return result.toUpperCase();
}