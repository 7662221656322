import React, { useState, useEffect } from "react";
import uuid from "react-uuid";

import { get, set, createStore, del } from "idb-keyval";
import {
  gameId,
  addToDB,
  deleteAsset,
  readBlob,
} from "../Functions/SettingsFunctions";

//Check if Subscribed
import { isSubscribed, checkBrowswer } from "../../../../utils/Utils";

import WhyChromeModal from "../Modals/WhyChromeModal";


function VideoBrief(props) {
  const { game_id, account, upload_progress, assets } = props;

  //IndexDB Items
  const [videoBrief, setVideoBrief] = useState("");
  const [editVideoBrief, setEditVideoBrief] = useState(false);
  const [availableSpace, setAvailableSpace] = useState()
  const [browser, setBrowser] = useState(checkBrowswer())

  useEffect(() => {
    if (assets && assets.video_brief) {
      setVideoBrief(assets.video_brief);

    }

    return () => {
      setVideoBrief("");
    };
  }, [assets]);

  useEffect(() => {
    const assetsStore = createStore(game_id, "assets");

    // Get Video Brief
    // get("video_brief", assetsStore).then((val) => {
    //   if (val) {
    //     const blob = readBlob(val);
    //     var url = URL.createObjectURL(blob);
    //     setVideoBrief({ ...val, url });
    //   }
    // });
    
  }, []);


  const uploadVideoBrief = (file) => {
    const id = uuid();
    
    if (file.size < 1073741824) {
      // Create Blob
      addToDB(file, "video_brief", setVideoBrief, videoBrief, id);

      // Upload to Database
      props.handleUploadFile(file, "video_brief", id);
    } else {
      setVideoBrief({error: "File too large."})
    }

  };

  const deleteVideoBrief = (file) => {

    // Upload to Database
    props.handleDeleteFile("video_brief", videoBrief)

    // Create Blob
    deleteAsset("video_brief", videoBrief, setVideoBrief)

  };

  console.log('video brief', videoBrief)
  console.log('browser', browser)

  return (
    <div className="bd-t pd-b-20 pd-t-20">
      {!isSubscribed(account) && (
        <div
          className="alert alert-outline alert-dark d-flex align-items-center"
          role="alert"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-alert-circle mg-r-10"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="12" y1="8" x2="12" y2="12"></line>
            <line x1="12" y1="16" x2="12" y2="16"></line>
          </svg>{" "}
          Upgrade your account to upload a video.{" "}
          <a style={{ marginLeft: "5px" }} href="/account/billing">
            {" "}
            Upgrade now.
          </a>
        </div>
      )}

{browser !== 'Chrome' && (
        <div
          className="alert alert-outline alert-dark d-flex align-items-center"
          role="alert"
        >
          <i class="fa-brands fa-chrome" style={{marginRight:'10px'}}></i>
          Please use <a href="https://www.google.com/chrome/downloads/" target="_blank" style={{marginRight:'5px', marginLeft:'5px'}}>Google Chrome</a> to upload videos. <a href="#whyChrome" data-toggle="modal" style={{marginLeft:'10px'}}>Learn why <i class="fa-regular fa-circle-question"></i></a>
        </div>
      )}

      <div className="form-group row">
        <div
          className="col-sm-3"
          style={{ opacity: isSubscribed(account) ? "1" : "0.5" }}
        >
          <label htmlFor="inputEmail3" className="col-form-label tx-18">
            <b>
              {/* <ion-icon
                name="image-outline"
                style={{ marginRight: "10px" }}
              ></ion-icon> */}
              Video Brief
            </b>
          </label>
          <p className="tx-13">
            Upload your own video to start the game. The timer will
            automatically start once the video has finished.
          </p>


          {videoBrief && !videoBrief.error && !editVideoBrief ? (
            <div>
              <div
                style={{
                  display: "flex",
                  background: "#f5f6fa",
                  padding: "5px",
                  borderRadius: "5px",
                }}
              >
                <p
                  style={{
                    marginBottom: "0px",
                    marginRight: "20px",
                    //marginTop: "5px",
                    padding: "5px",
                    display: "flex",
                  }}
                >
                  {/* <ion-icon
                    name="open-outline"
                    style={{ marginRight: "5px", fontSize: "22px" }}
                  ></ion-icon> */}

                  <div
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "155px",
                    }}
                  >
                    {upload_progress.type === "video_brief" && upload_progress.progress > 0 ? (
                            <React.Fragment>
                            <b>
                              Uploading: {upload_progress.progress.toFixed(1)}%{" "}
                            </b>
                            <div
                              className="progress"
                              style={{
                                marginTop: "10px",
                                width: "100%",
                                height: "5px",
                              }}
                            >
                              <div
                                className="progress-bar"
                                style={{ width: upload_progress.progress + "%" }}
                                role="progressbar"
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <a href={videoBrief.url} target="_blank">
                                <b>{videoBrief.name}</b>
                                <i
                                  style={{ marginLeft: "5px" }}
                                  className="fa-regular fa-up-right-from-square"
                                ></i>
                              </a>
                            </React.Fragment>
                        )}
                  </div>
                </p>

                <button
                  disabled={isSubscribed(account) ? false : true}
                  className="btn btn-icon btn-outline-danger"
                  type="button"
                  style={{ marginLeft: "auto", padding: "0px 10px" }}
                  // onClick={() => props.handleDeleteFile("video_brief")}
                  onClick={() => deleteVideoBrief()}
                >
                  <i
                    className="icon ion-ios-trash tx-18"
                    style={{ padding: "0px 0px" }}
                  ></i>
                </button>
              </div>

              <small>Max size: 1GB. File type: *.mp4</small>
            </div>
          ) : (
            <React.Fragment>
              {videoBrief && videoBrief.error ? (
                <p className="tx-danger mg-b-0">{videoBrief.error}</p>
              ) : null}
              <div className="custom-file">
                <input
                  disabled={isSubscribed(account) && browser === "Chrome" ? false : true}
                  onChange={(e)=> uploadVideoBrief(e.target.files[0])}
                  accept=".mp4"
                  name="video_brief"
                  type="file"
                  className="custom-file-input"
                  id="video_brief"
                />
                <label className="custom-file-label" htmlFor="customFile">
                  Upload file
                </label>
              </div>
              <small>Max size: 1GB. File type: *.mp4</small>
            </React.Fragment>
          )}



        </div>
        <div className="col-sm-9 pd-t-20">
          <div style={{ display: "flex" }}>
            {videoBrief && !videoBrief.error ? (
              <video
                style={{
                  maxWidth: "570px",
                  borderRadius: "8px",
                  margin: "0 auto",
                }}
                controls
                preload="auto"
                //autoPlay={true}
                //loop={true}
                src={videoBrief ? videoBrief.url : null}
                type="video/mp4"
              ></video>
            ) : (
              <div
                style={{
                  width: "570px",
                  height: "320px",
                  background: "#eee",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "0 auto",
                }}
              >
                <p className="tx-16 mg-b-0" style={{ opacity: "0.5" }}>
                  <b>No video brief.</b>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <WhyChromeModal />
    </div>
  );
}

export default VideoBrief;
