import React from 'react'

function AccountProfile() {
    return (
        <div>
            <h1>Account Profile</h1>
        </div>
    )
}

export default AccountProfile
