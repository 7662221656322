import React, { useRef, useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";

import { formatTime } from "../../../utils/Utils";

type Props = {
  handleAddMessage: any;
  handleLoadClue: any;
  handleAddMessageKeyDown: any;
  loadedClue: {
    message: string;
    type: string;
    puzzle: string;
    name: string;
    file: { name: string; url: string; duration: number };
    thumbnail?: string;
  };
  hintsUsed: number;
  addedMinutes: number;
  togglePlayAudioClue: any;
  handleAddAudioClue: any;
  handleLoadPreviousClue: any;
  previousMessage: any;
  handleClearLoadedClue: any;
  handleSendImageClue: any;
  handleSendVideoClue: any;
};

const MessageInput = (props: Props) => {
  const {
    handleAddMessage,
    handleLoadClue,
    handleAddAudioClue,
    handleAddMessageKeyDown,
    handleLoadPreviousClue,
    previousMessage,
    loadedClue,
    hintsUsed,
    //avgCluePerMinute,
    addedMinutes,
    togglePlayAudioClue,
    handleClearLoadedClue,
    handleSendImageClue,
    handleSendVideoClue,
  } = props;

  const textArea = useRef<HTMLTextAreaElement>(null);
  const [loaded, setLoaded] = useState(loadedClue);
  const [isMac, setIsMac] = useState();
  const [focusTextArea, setFocusTextArea] = useState(false);

  useEffect(() => {
    // Clear text box when game resets
    if (!loadedClue) {
      if (null !== textArea.current) {
        textArea.current.value = "";
      }
    }
    setLoaded(loadedClue);
    if (loadedClue.type !== "image" && loadedClue.type !== "video") {
      if (null !== textArea.current) {
        textArea.current.autofocus = true;
      }
    }
  }, [loadedClue]);

  console.log("loaded clue", loaded);

  const handleMessage = (type: string) => {
    if (type === "image") {
      handleSendImageClue(loadedClue);
    }

    if (type === "video") {
      handleSendVideoClue(loadedClue);
    }

    if (!type || type === "text") {
      handleAddMessage(loaded);
      if (null !== textArea.current) {
        textArea.current.value = "";
      }
    }
  };

  const joinMessage = (type: string) => {
    if (!type || type === "text") {
      // handleAddMessage(loadedClue, 'join'); OLD
      handleAddMessage(loaded, "join"); // NEW
      if (null !== textArea.current) {
        textArea.current.value = "";
      }
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13 && e.keyCode <= 90 && !e.shiftKey) {
      e.preventDefault();
      //handleAddMessage(loadedClue); OLD
      handleAddMessage(loaded); // NEW
      if (null !== textArea.current) {
        textArea.current.value = "";
      }
    }

    // if (loaded && loaded.puzzle && !loaded.message.trim().length){
    //   console.log('EMPTY')
    //   handleClearLoadedClue()
    // }
  };

  const onInputChange = (e: any) => {
    if (e.target.value) {
      setLoaded({ ...loadedClue, message: e.target.value });
    } else {
      handleClearLoadedClue();
    }
  };

  const EnterButton = (disabled: boolean) => {
    if (!disabled) {
      return (
        <button
          type="button"
          disabled
          className="button-blue-dark"
          style={{ fontSize: "14px", height: "35px", marginBottom: "0" }}
          onClick={() => handleAddMessage(loaded)}
        >
          <i
            className="icon ion-md-send"
            style={{ color: "white", marginRight: "10px" }}
          ></i>
          Send
        </button>
      );
    }

    if (loadedClue.type && loadedClue.type === "image") {
      return (
        <button
          type="button"
          className="button-green-dark"
          style={{ fontSize: "14px", height: "35px", marginBottom: "0" }}
          onClick={() => handleMessage(loadedClue.type)}
        >
          <div className="flex items-center">
            <svg
              className="fill-white mr-2 mt-[0px]"
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 512 512"
            >
              <path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" />
            </svg>
            Send image
          </div>
        </button>
      );
    } else if (loadedClue.type && loadedClue.type === "video") {
      return (
        <button
          type="button"
          className="button-green-dark"
          style={{ fontSize: "14px", height: "35px", marginBottom: "0" }}
          onClick={() => handleMessage(loadedClue.type)}
        >
          <div className="flex items-center">
            <svg
              className="fill-white mr-2 mt-[0px]"
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 576 512"
            >
              <path d="M0 128C0 92.7 28.7 64 64 64H320c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128zM559.1 99.8c10.4 5.6 16.9 16.4 16.9 28.2V384c0 11.8-6.5 22.6-16.9 28.2s-23 5-32.9-1.6l-96-64L416 337.1V320 192 174.9l14.2-9.5 96-64c9.8-6.5 22.4-7.2 32.9-1.6z" />
            </svg>
            Send video
          </div>
        </button>
      );
    } else {
      return (
        <button
          type="button"
          className="button-blue-dark"
          style={{ fontSize: "14px", height: "35px", marginBottom: "0" }}
          onClick={() => handleMessage("text")}
        >
          <div className="flex items-center">
            <svg
              className="fill-white mr-2 mt-[0px]"
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 512 512"
            >
              <path d="M49.9 27.8C15.1 12.7-19.2 50.1-1.2 83.5L68.1 212.2c4.4 8.3 12.6 13.8 21.9 15c0 0 0 0 0 0l176 22c3.4 .4 6 3.3 6 6.7s-2.6 6.3-6 6.7l-176 22s0 0 0 0c-9.3 1.2-17.5 6.8-21.9 15L-1.2 428.5c-18 33.4 16.3 70.8 51.1 55.7L491.8 292.7c32.1-13.9 32.1-59.5 0-73.4L49.9 27.8z" />
            </svg>
            Send message
          </div>
        </button>
      );
    }
  };

  // const NotifyPlayersOfClue = (bool) => {
  //   setNotifyPlayers(bool);
  //   localStorage.setItem(
  //     "BOLT_preferences",
  //     JSON.stringify({
  //       ...JSON.parse(localStorage.getItem("BOLT_preferences")),
  //       notifyPlayers: bool,
  //     })
  //   );
  // };

  return (
    <div
      id="messageBox"
      style={{
        position: "absolute",
        bottom: "0px",
        right: "20px",
        left: "20px",
      }}
    >
      {/* {loaded.puzzle ? (
        <div
          style={{
            opacity: "0.5",
            paddingLeft: "5px",
            paddingBottom: "5px",
          }}
        >
          <small style={{ marginRight: "20px" }}>
            <b>Puzzle:</b> {loadedClue.puzzle}
          </small>
        </div>
      ) : null} */}

      <div
        className="controlsBox card"
        style={{
          background: "#27282e",
          // border: '1px solid #46484b'
          border:
            loadedClue.message || focusTextArea || loadedClue.file
              ? "2px solid rgb(1, 104, 250)"
              : "1px solid #46484b",
          transition: "0.10s",
          display: "flex",
        }}
      >
        <div
          className="pd-10 h-full flex flex-col justify-between"
          style={{ paddingTop: "5px" }}
        >
          {loadedClue.type !== "image" && loadedClue.type !== "video" && (
            <textarea
              key="MessageInput"
              ref={textArea}
              autoFocus
              className="form-control align-self-center bd-0 hint-message-input scrollbar-hide bg-gray-900"
              placeholder="Type a message here..."
              style={{
                resize: "none",
                maxHeight: "80px",
                minHeight: "40px",
                height: "100%",
                fontSize: "16px",
                fontWeight: "400",
                border: "none",
                color: "#c9cece",
                // border: loadedClue.message
                //   ? "2px solid rgb(1, 104, 250)"
                //   : "1px solid black",
                transition: "0.2s",
              }}
              value={loaded.message}
              onChange={(e) => onInputChange(e)}
              onKeyDown={(e) => handleKeyDown(e)}
              onFocus={() => setFocusTextArea(true)}
              onBlur={() => setFocusTextArea(false)}
            ></textarea>
          )}

          {loadedClue.type === "image" && (
            <div className="flex justify-start pt-2 px-2 group">
              <div className="absolute items-center justify-center mr-3 w-[45px] h-[45px] ">
                <img
                  src={loadedClue.file.url}
                  className="absolute w-[45px] h-[45px]  object-cover rounded"
                  loading="lazy"
                ></img>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="overflow-hidden ml-[60px]">
                  <p className="text-gray-200 line-clamp-1 mb-0.5 cursor-default">
                    {loadedClue.name}
                  </p>
                  {loadedClue.puzzle && (
                    <p className="text-xs text-gray-400 line-clamp-1 cursor-default">
                      {loadedClue.puzzle}
                    </p>
                  )}
                </div>

                <div className="items-center hidden group-hover:flex">
                <Tooltip title="Clear" placement="top" arrow>
                  <button
                    onClick={handleClearLoadedClue}
                    type="button"
                    className="button-outline-dark"
                    style={{
                      fontSize: "10px",
                      padding: "5px 10px",
                    }}
                  >
                    <i className="fa-solid fa-x" style={{ height: "10px" }}></i>
                  </button>
                  </Tooltip>
                </div>
              </div>
            </div>
          )}

          {loadedClue.type === "video" && (
            <div className="flex justify-start pt-2 px-2 group">
              <div className="absolute items-center justify-center mr-3 w-[45px] h-[45px] ">
                <img
                  src={loadedClue.thumbnail}
                  className="absolute w-[45px] h-[45px]  object-cover rounded"
                  loading="lazy"
                ></img>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
              <div className="overflow-hidden ml-[60px]">
                <p className="text-gray-200 line-clamp-1 mb-0.5 cursor-default">
                  {loadedClue.name}
                </p>
                <div className="flex items-center space-x-3">
                  {loadedClue.file.duration && (
                    <p className="text-xs text-gray-400 line-clamp-1 cursor-default">
                      {formatTime(loadedClue.file.duration)}
                    </p>
                  )}
                  {loadedClue.puzzle && (
                    <p className="text-xs text-gray-400 line-clamp-1 cursor-default">
                      {loadedClue.puzzle}
                    </p>
                  )}
                </div>
              </div>

              <div className="items-center hidden group-hover:flex">
                <Tooltip title="Clear" placement="top" arrow>
                  <button
                    onClick={handleClearLoadedClue}
                    type="button"
                    className="button-outline-dark"
                    style={{
                      fontSize: "10px",
                      padding: "5px 10px",
                    }}
                  >
                    <i className="fa-solid fa-x" style={{ height: "10px" }}></i>
                  </button>
                  </Tooltip>
                </div>

                </div>
            </div>
          )}

          <div className="flex items-center justify-end">
            <button
              onClick={() => togglePlayAudioClue("alert-button")}
              type="button"
              className="button-outline-dark"
              style={{ marginRight: "10px", fontSize: "14px", height: "35px" }}
            >
              <i className="fa-solid fa-bell mr-2"></i>
              Alert Tone
              {/* <i
                  className="icon ion-md-volume-high"
                  style={{ marginLeft: "8px" }}
                ></i> */}
            </button>

            {loaded.message || loadedClue.file
              ? EnterButton(true)
              : EnterButton(false)}
          </div>
        </div>
      </div>

      {loadedClue.message ? (
        <div
          style={{
            position: "absolute",
            bottom: "0",
            right: "10px",
            opacity: "0.5",
          }}
        >
          <small style={{ marginRight: "20px" }}>
            <b>Return</b> to send
          </small>
          <small>
            <b>Shift + Return</b> to add a new line
          </small>
        </div>
      ) : null}
    </div>
  );
};

export default MessageInput;
