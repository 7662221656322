import React from 'react'
import { Link } from "react-router-dom";

function AccountBillingHistory() {
    return (
        <div>
            <div class="billing-main-header">
            <div class="div-block-7">
              <h1 class="heading-2">Billing</h1>
            </div>
            <div class="billing-tabs-container">
              <div class="div-block-12">
                <Link to="/account/billing" class="paragraph-7">Overview</Link>
              </div>
              <div class="div-block-11">
                <Link to="/account/billing/history">Billing History</Link>
              </div>
              <div>
                <Link to="/account/billing/payment" class="paragraph-8">Payment Method</Link>
              </div>
            </div>
          </div>
          <div class="billing-container">
           
          </div>
        </div>
    )
}

export default AccountBillingHistory
