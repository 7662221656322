import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { classNames } from "../../../utils/Utils";

export const ImageClueButton = (props) => {
  const {
    account,
    clueObj,
    loadedClue,
    handleLoadClueClick,
    puzzlesDone,
    selectedPuzzleObj,
    color,
    isSent,
    userPermissions,
    sortClueStatus,
  } = props;
  const [showControls, setShowControls] = useState();

  const includesId = selectedPuzzleObj ? selectedPuzzleObj.id : 0;

  const style = () => {
    if (isSent) {
      if (loadedClue.id === clueObj.id) {
        return {
          background: "rgb(23, 24, 28)",
          border: "2px solid #0168fa",
          opacity: "0.5",
        };
      } else {
        return {
          border: "2px solid transparent",
          opacity: "0.5",
        };
      }
    } else if (loadedClue.id === clueObj.id) {
      return {
        background: "rgb(23, 24, 28)",
        border: "2px solid #0168fa",
      };
    } else {
      return {
        border: "2px solid transparent",
      };
    }
  };

  const clueObject = {
    ...clueObj,
    color,
  };

  const clueRating = () => {
    if (clueObj.rating) {
      if (clueObj.rating === 1) {
        return (
          <div className="clue-rating-box">
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i className="icon ion-ios-radio-button-off"></i>
            <i className="icon ion-ios-radio-button-off"></i>
            <i className="icon ion-ios-radio-button-off"></i>
            <i className="icon ion-ios-radio-button-off"></i>
          </div>
        );
      } else if (clueObj.rating === 2) {
        return (
          <div className="clue-rating-box">
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i className="icon ion-ios-radio-button-off"></i>
            <i className="icon ion-ios-radio-button-off"></i>
            <i className="icon ion-ios-radio-button-off"></i>
          </div>
        );
      } else if (clueObj.rating === 3) {
        return (
          <div className="clue-rating-box">
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i className="icon ion-ios-radio-button-off"></i>
            <i className="icon ion-ios-radio-button-off"></i>
          </div>
        );
      } else if (clueObj.rating === 4) {
        return (
          <div className="clue-rating-box">
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i className="icon ion-ios-radio-button-off"></i>
            {/* <i className="icon ion-md-star" style={{color: 'rgb(236, 236, 88)'}}></i>
          <i className="icon ion-md-star" style={{color: 'rgb(236, 236, 88)'}}></i>
          <i className="icon ion-md-star" style={{color: 'rgb(236, 236, 88)'}}></i> */}
          </div>
        );
      } else if (clueObj.rating === 5) {
        return (
          <div className="clue-rating-box">
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
          </div>
        );
      }
    }
  };

  return (
    <button
      className={classNames("media")}
      disabled={account.subscription.status !== "active" ? true : false}
      onClick={() => handleLoadClueClick(clueObject)}
      onMouseOver={() =>
        setShowControls(
          userPermissions.isAdmin || userPermissions.canEditHints ? true : false
        )
      }
      onMouseLeave={() => setShowControls(false)}
      style={{ ...style() }}
    >
      {account.subscription.status !== "active" && (
        <div className="absolute right-0 left-0 h-[50px] flex items-center justify-center text-white z-10">
          <a
            href="#proFeatureOnly"
            data-toggle="modal"
            className="button-green-dark py-1 px-2 rounded text-xs"
          >
            <i class="fa-sharp fa-regular fa-lock mr-2"></i>Premium
          </a>
        </div>
      )}

      <div
        className={classNames(
          "flex w-full",
          account.subscription.status !== "active"
            ? "brightness-50 blur-[1px]"
            : "opacity-100"
        )}
      >
        <div className="contents">
          <img
            src={clueObj.file.url}
            className="w-[50px] h-[50px]  object-cover rounded"
            loading="lazy"
          ></img>
          {/* <i className="fa-solid fa-image mt-[3px] text-gray-400"></i> */}
        </div>

        <div
          className="media-body mg-l-10 w-[75%]"
          style={{ alignItems: "center" }}
        >
          <h6
            className="mg-b-0 break-words overflow-hidden text-ellipsis line-clamp-2 text-gray-50"
            style={{
              fontStyle: isSent ? "italic" : "inherit",
            }}
          >
            {clueObj.name}
          </h6>

          <div className="flex items-center center space-x-2 pt-1">
            {/* {clueObj.rating >= 1 && (
            <small
            className="d-block text-muted"
            style={{ display: "flex", marginRight: "10px" }}
          >
            {clueObj.rating ? clueRating() : null}
          </small>
          )} */}

            <i className="fa-regular fa-image text-gray-400"></i>
            <p className="text-gray-400 text-xs">Image</p>

            {/* <p className="text-gray-500 text-xs">
            {clueObj.file.name}
          </p> */}
          </div>

          {showControls ? (
            <Tooltip title="Edit" placement="right">
              <i
                className="icon ion-ios-more"
                // style={{fontSize:'18px', height:'16px', float:'right'}}
                style={{
                  fontSize: "18px",
                  position: "absolute",
                  right: "20px",
                  transform: "translate(0px, -16px)",
                }}
                href="#editDeleteImageClueModal"
                onClick={() => handleLoadClueClick(clueObj)}
                data-toggle="modal"
              ></i>
            </Tooltip>
          ) : null}
        </div>
      </div>
    </button>
  );
};
