import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { classNames, formatBytes } from "../../../utils/Utils";

import { addNewImage, uploadImage } from "../../../redux/actions/gmActions";

type Props = {
  id: string;
  puzzles: any;
  handleAddNewClue: (e: any) => void;
  selectedPuzzleObj: any;
  game_id: string;
  addNewImage: (data: any, id: string, puzzle_id: string) => void;
  uploadImage: (file: any, game_doc: any) => void;
  upload_image_clue_progress: number;
  uploaded_image_clue_data: any;
};

const AddImageClueModal = (props: Props) => {
  const {
    id,
    puzzles,
    handleAddNewClue,
    selectedPuzzleObj,
    game_id,
    upload_image_clue_progress,
    uploaded_image_clue_data,
    uploadImage,
  } = props;

  const [error, setError] = useState("");

  const [data, setData] = useState({
    name: "",
    file: {
      name: "",
      url: null,
      duration: 0,
      size: 0,
    },
    fullscreen: true,
    transparent_bg: false,
  });

  const updateData = (name: string, value: any) => {
    const newObj = {
      ...data,
      [name]: value,
    };
    setData(newObj);
  };

  useEffect(() => {
    if (uploaded_image_clue_data) {
      setData({
        ...data,
        name: !data.name ? uploaded_image_clue_data.name : data.name,
        file: uploaded_image_clue_data,
        fullscreen: true,
        transparent_bg: false,
      });
    }
  }, [uploaded_image_clue_data]);

  const title = selectedPuzzleObj ? selectedPuzzleObj.name : "";

  const uploadFile = (file: any) => {
    console.log("file", file);
    setError("");
    // Check if size is less than 100MB
    if (file.size <= 100000000 ) {
      uploadImage(file, game_id);
    } else {
      setError("File is too big. Max size is 100MB.");
    }
  };

  console.log("DATA", uploaded_image_clue_data);

  return (
    <div
      className="modal effect-scale"
      id="addImageClueModal"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered max-w-[355px]"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body">
            <div className="mb-4">
              <p className="text-lg mb-1 text-white font-medium">
                Upload Image Clue
              </p>
              {/* <p>Upload a custom image clue</p> */}
            </div>

            <div className="flex flex-col">
              {/* <label className="label-dark">File</label> */}
              <div
                className={classNames(
                  "flex items-center justify-center w-full",
                  uploaded_image_clue_data ? "hidden" : null
                )}
              >
                <label
                  //for="dropzone-file"
                  className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer dark:hover:bg-bray-800 bg-v1_dark-hover hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                >
                  <div className="flex flex-col items-center text-center justify-center">
                    {upload_image_clue_progress > 0 ? (
                      <div>
                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                          <span className="font-semibold">
                            Uploading: {upload_image_clue_progress.toFixed(0)}%
                          </span>
                        </p>
                        <div className="h-1 bg-gray-600">
                          <div
                            className="h-full bg-blue-500 transition-width"
                            style={{
                              width: `${upload_image_clue_progress.toFixed(
                                0
                              )}%`,
                            }}
                          ></div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <i className="fa-regular fa-upload text-2xl mb-2"></i>
                        <p className="mb-2 text-sm text-white dark:text-gray-500 font-medium">
                          Drag and drop an image file to upload
                        </p>
                        <p className="text-xs text-gray-500 dark:text-gray-500">
                          .jpg, .jpeg, .png (100MB max)
                        </p>
                      </div>
                    )}

                    {error && (
                      <p className="text-red-500 font-medium mt-3">
                        {error && error}
                      </p>
                    )}
                  </div>
                  <input
                    onChange={(e: any) => uploadFile(e.target.files[0])}
                    id="dropzone-file"
                    type="file"
                    className="hidden"
                    accept=".jpg, .jpeg, .png"
                  />
                </label>
              </div>

              {uploaded_image_clue_data && (
                <div className={classNames("w-full flex flex-col space-y-2")}>
                  {/* <i className="fa-solid fa-file-check text-green-500 h-8 mr-3"></i> */}
                  <img
                    src={uploaded_image_clue_data && uploaded_image_clue_data.url}
                    className="w-full h-auto mb-2"
                  ></img>
                  <div className="w-[83%]">
                    {/* <p className="text-white whitespace-nowrap overflow-hidden text-ellipsis w-full">{uploaded_image_clue_data.name}</p> */}
                    <div className="flex flex-col mb-3">
                      <label className="label-dark">Clue Name</label>
                      <input
                        value={data.name}
                        onChange={(e) => updateData("name", e.target.value)}
                        type="text"
                        className="input-dark"
                        placeholder="Name"
                      ></input>
                    </div>
                    <p className="text-sm text-gray-500">
                        {uploaded_image_clue_data
                          ? formatBytes(uploaded_image_clue_data.size)
                          : "0MB"}
                      </p>
                  </div>
                </div>
              )}
            </div>

            <div className="text-center mt-4">
              <p className="text-gray-500 text-xs">
                By submitting your images to Bolt, you acknowledge that you
                agree to Bolt's{" "}
                <a
                  className="hover:text-white text-blue-500 duration-100"
                  target="_blank"
                  href="https://www.boltgamemaster.com/terms-of-service"
                >
                  Terms of Service.
                </a>
              </p>
            </div>


          </div>

          <div className="modal-footer-dark">
            {data.file.url ? (
              <button
                onClick={() => {
                  props.addNewImage(data, game_id, selectedPuzzleObj.id);
                  setTimeout(() => {
                    setData({
                      name: "",
                      file: {
                        name: "",
                        url: null,
                        duration: 0,
                        size: 0,
                      },
                      fullscreen: true,
                      transparent_bg: false,
                    });
                  }, 1000);
                }}
                type="button"
                className="button-blue-dark w-full"
                data-dismiss="modal"
              >
                Add image clue
              </button>
            ) : (
              <button
                disabled
                type="button"
                className="button-blue-dark opacity-50 w-full"
                data-dismiss="modal"
              >
                Add image clue
              </button>
            )}
            {/* <button
              type="button"
              className="button-outline-dark ml-2"
              data-dismiss="modal"
            >
              Cancel
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
    upload_image_clue_progress: state.settings.upload_image_clue_progress,
    uploaded_image_clue_data: state.settings.uploaded_image_clue_data,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addNewImage: (data: any, id: string, puzzle_id: string) =>
      dispatch(addNewImage(data, id, puzzle_id)),
    uploadImage: (file: any, game_doc: string) =>
      dispatch(uploadImage(file, game_doc)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddImageClueModal);
