import React from "react";

const CreateUserFromInvite = (props) => {

  const { params, invite_data } = props;

  const account_title = invite_data ? invite_data.account_title : 'Team';

  return (
    <div className="wd-100p" style={{ maxWidth: "460px", margin: "0 auto" }}>
      <div style={{ marginBottom: "40px" }}>
        <h1 className="mg-b-20 text-center">Join {account_title}</h1>
      </div>

      {/* <button
        onClick={() => props.signInWithGoogle()}
        className="btn btn-white btn-block mg-b-50 tx-16"
      >
        <i className="icon ion-logo-google" style={{ marginRight: "10px" }}></i>
        Register With Google
      </button>

      <div className="divider-text tx-11">or, register with your email</div> */}

      <div
        style={{ paddingBottom: "0px", fontWeight: "bold", maxWidth: "300px" }}
      >
        {props.authError ? <p style={{ color: "red" }}>{props.authError}</p> : null}
      </div>

      <form onSubmit={props.handleSubmit}>
        <div className="form-group">
          <label className="signup-label" htmlFor="fullName">
            Full Name
          </label>
          <input
            type="text"
            id="fullName"
            className="form-control tx-16"
            placeholder="First and last name"
            onChange={props.handleChange}
            required
          ></input>
        </div>


        <div className="form-group">
          <label className="signup-label" htmlFor="company">
            Email Address
          </label>
          <input
            disabled={params.email ? true : false}
            type="email"
            id="email"
            className="form-control tx-16"
            placeholder="you@yourcompany.com"
            onChange={props.handleChange}
            value={params.email ? params.email : null}
            required
          ></input>
        </div>

        <div className="form-group">
          <div className="d-flex justify-content-between mg-b-5">
            <label htmlFor="password" className="mg-b-0-f signup-label">
              Password
            </label>
          </div>
          <input
            type="password"
            id="password"
            className="form-control tx-16"
            placeholder="Password (at least 8 characters)"
            onChange={props.handleChange}
            required
          ></input>
          {/* <small className="text-muted">Password (at least 8 characters)</small> */}
        </div>

        <div className="form-group">
          <div className="d-flex justify-content-between mg-b-5">
            <label htmlFor="password" className="mg-b-0-f signup-label">
              Re-enter Password
            </label>
          </div>
          <input
            type="password"
            id="password_2"
            className="form-control tx-16"
            placeholder="Re-enter your password"
            onChange={props.handleChange}
            required
          ></input>
        </div>
        {props.passwordError ? (
          <p className="tx-danger">
            <b>{props.passwordError}</b>
          </p>
        ) : null}


        <button className="btn btn-primary w-100 mg-b-20 tx-16">
          <b>Join Team</b>
          <i
            className="icon ion-md-arrow-round-forward"
            style={{ marginLeft: "10px" }}
          ></i>
        </button>

        <p className="tx-16">
          By clicking "Register" you agree to our {" "}
          <a href="https://hintspace.io/terms-conditions">Terms of Service</a> and{" "}
          <a href="https://hintspace.io/privacy-policy">Privacy Policy</a>.
        </p>
      </form>
    </div>
  );
};

export default CreateUserFromInvite;
