export const initializeState = () => {
    return (dispatch, getState) => {

        dispatch({type: 'INITIALIZE_STATE'})

    }
}

export const updateTimer = (timer) => {
    return (dispatch, getState) => {

        dispatch({type: 'UPDATE_TIMER', timer})

    }
}

export const updateThumbnail = (game_id, file) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const db = getFirestore();
        const firebase = getFirebase();
        const user_account = getState().firebase.profile.account_id;
    
        // Create a root reference
        var storageRef = firebase.storage().ref();

        // Create the file metadata
        var metadata = {
          cacheControl: "private,max-age=86400",
        
        };
    
        const fileName = file.name;

        // const doesFileExist = storageRef.child("gamemaster/" + user_account + "/" + game_id + "/" + fileName)
        // doesFileExist.getDownloadURL()

        db.collection("gamemaster").doc(game_id).get()
          .then((doc) => {

                if (doc.data().thumbnail) {

                  if (doc.data().thumbnail.last_updated.toDate().getTime() > new Date().getTime() - 86400000) {
                    console.log('THUMBNAIL EXISTS AND IS NOT EXPIRED')
                    return
                  }


                } else {
                  console.log("File does not exist");


                  var uploadTask = storageRef
                  .child("gamemaster/" + user_account + "/" + game_id + "/" + fileName)
                  .put(file, metadata);
                uploadTask.on(
                  firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                  function (snapshot) {
                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    switch (snapshot.state) {
                      case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log("Upload is paused");
                        break;
                      case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log("Upload is running");
                        break;
                    }
                  },
                  function (error) {
                    switch (error.code) {
                      case "storage/unauthorized":
                        break;
                      case "storage/canceled":
                        break;
                      case "storage/unknown":
                        break;
                    }
                  },
                  function () {
                    uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                      console.log("File available at", downloadURL);
        
                      db.collection("gamemaster")
                        .doc(game_id)
                        .update({
                          thumbnail: {
                            url: downloadURL,
                            name: file.name,
                            type: file.type,
                            last_updated: new Date(),
                            expired: false
                        },
                        })
                        .then(() => {
                          console.log('THUMBNAIL UPLOADED')
                        })
                        .catch((error) => {
                          console.log(error);
                          //dispatch({type: "SAVE_GAME_SETTINGS_ERROR", error})
                        });
                    });
                  }
                )


                }
            })


      };
}

export const completeGame = (state) => {
    return (dispatch, getState) => {

        dispatch({type: 'COMPLETE_GAME', state})

    }
}

export const updateClueOnScreen = (clue, func) => {
    return (dispatch, getState) => {

        dispatch({type: 'UPDATE_CLUE_ON_SCREEN', clue, func})

    }
}

export const updateVideoClueOnScreen = (clue, func) => {
  return (dispatch, getState) => {
      console.log('Dispatch update video clue on screen')
      
      if (clue) {
        localStorage.setItem(
          "BOLT_videoClue",
          JSON.stringify({
            ...clue
          })
        );
      } else {
        localStorage.setItem(
          "BOLT_videoClue",
          null
        )
      }

      //dispatch({type: 'UPDATE_VIDEO_CLUE_ON_SCREEN', clue, func})

  }
}

export const updateVideoClueCurrentTime = (obj) => {
  return (dispatch, getState) => {
      console.log('udpate video clue time')

      const videoClue = JSON.parse(localStorage.getItem("BOLT_videoClue"))
      
      if (obj && videoClue !== null){
        localStorage.setItem(
          "BOLT_videoClue",
          JSON.stringify({
            ...JSON.parse(localStorage.getItem("BOLT_videoClue")),
            ...obj
          })
        );
      }

      //dispatch({type: 'UPDATE_VIDEO_CLUE_ON_SCREEN', clue, func})

  }
}

// export const updateClueOnScreenCount = (clueCountArray) => {
//     return (dispatch, getState) => {

//         dispatch({type: 'UPDATE_CLUE_ON_SCREEN_COUNT', clueCountArray})

//     }
// }

export const updateClueOnScreenCount = (clueCountArray, count, game_doc) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();

    dispatch({ type: "UPDATE_CLUE_ON_SCREEN_COUNT", clueCountArray });
    
    if (game_doc) {
      db.collection("gamemaster")
        .doc(game_doc)
        .update({
          clueCount: count || 0,
        })
        .then(() => {
          console.log("SUCCESS");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
};

export const updateHideCluesOnScreen = (showHints, game_doc) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();

      if (game_doc) {
        db.collection("gamemaster")
          .doc(game_doc)
          .collection("settings")
          .doc("live_window")
          .update({
            show_hintIcons: showHints,
          })
          .then(() => {
            console.log("success");
          })
          .catch((error) => {
            console.log(error);
          });
      }

        // dispatch({type: 'UPDATE_CLUE_ON_SCREEN_COUNT', clueCountArray})

    }
}

export const updateInfiniteClues = (bool) => {
    return (dispatch, getState) => {

        dispatch({type: 'UPDATE_INFINITE_CLUES', bool})

    }
}

export const updateControlAfterVideo = (data) => {
    return (dispatch, getState) => {

        dispatch({type: 'UPDATE_CONTROL_AFTER_VIDEO', data})

    }
}


export const updateVideoCluePlayingControl = (isPlaying) => {
  return (dispatch, getState) => {

      console.log('UPDATE VIDEO CLUE PLAYING CONTROLS', isPlaying)
      dispatch({type: 'UPDATE_CONTROL_AFTER_VIDEO_CLUE', isPlaying})

  }
}