import React, { Component } from "react";
import FadeIn from "react-fade-in";
import moment from "moment";
import { connect } from "react-redux";

import {
  loadAudioState,
  loadStateFromLocalStorage,
  getVideoClueFromLocalStorage,
} from "../../../localStorage";

import Send from "./Send";
import MessageInput from "./MessageInput";

// Message Components
import { Message_Text } from "./MessageComponents/Message_Text";
import { Message_Audio } from "./MessageComponents/Message_Audio";
import { Message_Image } from "./MessageComponents/Message_Image";
import Message_Video from "./MessageComponents/Message_Video";
import { Message_ClearScreen } from "./MessageComponents/Message_ClearScreen";
import { Message_TimerAdjust } from "./MessageComponents/Message_TimerAdjust";
import { Bot } from "./MessageComponents/Bot";
import Message_Intro from "./MessageComponents/Message_Intro";
import { Message_StartStop } from "./MessageComponents/Message_GameStartStop";
import { classNames } from "../../../utils/Utils";

class Messages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      video_brief_current: 0,
      video_brief_duration: 0,
      progressBar: 100,
      videoIsPlaying: false,
      timerRunning: false,
      isPlayerWindowOpen: loadStateFromLocalStorage().timer.isPlayerWindowOpen,
      videoClue_currentTime: 0,
      videoClue_duration: 0,
    };

    this.localStorageUpdated = this.localStorageUpdated.bind(this);
  }

  clueOnScreenTime = () => {
    return "0:31";
  };

  componentDidMount() {
    // Add Timer Event Listener
    window.addEventListener("storage", this.localStorageUpdated);
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("storage", this.localStorageUpdated);
    }
  }

  localStorageUpdated() {
    if (localStorage.getItem("state")) {
      const ls = loadStateFromLocalStorage();
      this.setState({
        ...this.state,
        videoIsPlaying: ls.timer.videoIsPlaying,
        timerRunning: ls.timer.tmerRunning,
        isPlayerWindowOpen: ls.timer.isPlayerWindowOpen,
      });
    }

    if (localStorage.getItem("BOLT_videoClue")) {
      const ls = getVideoClueFromLocalStorage();

      if (ls) {
        this.setState({
          ...this.state,
          videoClue_currentTime: ls.currentTime ? ls.currentTime : 0,
          videoClue_duration: ls.duration ? ls.duration : 0,
          videoClue_id: ls.send_id,
        });
      }
    }

    if (localStorage.getItem("audio")) {
      const audioState = loadAudioState();

      const current = audioState.video_brief_current;
      const duration = audioState.video_brief_duration;

      const progressBar = (Number(current) / Number(duration)) * 100;
      const newProgress = 100 - progressBar;

      //console.log('progress', newProgress)

      this.setState({
        ...this.state,
        progressBar: newProgress,
      });
    }
  }

  render() {
    const {
      messages,
      loadedClue,
      handleLoadClue,
      handleAddMessage,
      handleAddAudioClue,
      handleSendImageClue,
      handleSendVideoClue,
      handleAddMessageKeyDown,
      handleScreenClear,
      hintsUsed,
      avgCluePerMinute,
      addedMinutes,
      togglePlayAudioClue,
      account,
      profile,
      game,
      handleLoadPreviousClue,
      previousMessage,
      //countdownWidth,
      //showCountdownBar,
      handleClearLoadedClue,
    } = this.props;

    const { progressBar, timerRunning, videoIsPlaying } = this.state;

    //const MessageInputMemo = React.memo(MessageInput)

    console.log("messages", messages);

    return (
      <React.Fragment>
        {/* <div className="w-full mt-4 relative z-10 max-w-2xl mx-auto">
            <div className={classNames(
              "h-[200px]  flex flex-col justify-between rounded mx-4 duration-100",
              this.props.timer.clueOnScreen ? "border-2 border-red-600 bg-[#28292e] shadow" : "border border-gray-700 bg-[#23232b]"
            )}>



<div className="absolute right-0 mr-[35px] mt-[5px] z-10">
            <button
            href="#editClueIconsModal"
            data-toggle="modal"
            className={classNames(
              "text-gray-500 hover:text-gray-500 px-1.5 rounded-sm hover:bg-gray-700 focus:outline-none duration-75"
            )}
          >
            <i class="fa-solid fa-ellipsis"></i>
          </button>
            </div>



            <div className="flex items-center justify-center pt-2 bg-[#33363d">
              <p className="text-xs text-gray-500 mb-2 font-semibold">Player Window Preview</p>
              
            </div>



              <div className="items-center justify-center py-4 px-24 text-center h-[185px] absolute flex right-0 left-0">
                
                
                {this.props.timer.clueOnScreen && this.props.timer.clueOnScreen.type === "text" && (
                  <p className="text-xl text-white line-clamp-2">{messages && messages[messages.length -1].message && messages[messages.length -1].message} </p>
                )}

                {this.props.timer.clueOnScreen && this.props.timer.clueOnScreen.type === "image" && (
                  <div className="flex flex-col items-center">

                  <img src={this.props.timer.clueOnScreen.file.url} className="mw-[142px] h-[90px] object-cover rounded"></img>
                  <p className="pt-2 text-white"><i class="fa-regular fa-image mr-2"></i>{this.props.timer.clueOnScreen.name}</p>
                    </div>
                )}

                {!this.props.timer.clueOnScreen &&  <p className="text-gray-600 italic text-3xl">{this.props.timer.time.m}:{this.props.timer.time.s}</p>}

              </div>


      <div className="w-full flex items-center justify-between px-2 mb-2">

<div className="pl-2">
        {this.props.timer.clueOnScreen && (
          
          <p className={classNames(
            " text-xs",
            Date.now() - messages[messages.length -1].timestamp >= 180000 ? "text-red-500 font-semibold" : "text-gray-500"
          )}>Sent {moment(messages[messages.length -1].timestamp).fromNow()}</p>
        )}

</div>
        {this.props.timer.clueOnScreen || this.props.remote.clueOnScreen ? (
          <div>
            <button
              onClick={handleScreenClear}
              type="button"
              className="button-red-dark clear-screen"
              style={{
                fontSize: "12px",
                fontWeight: "500",
                padding: "0px 10px",
                height: "25px",
              }}
            >
              <i class="fa-solid fa-x" style={{height:'12px', marginRight:'5px'}}></i>
              Clear player window
            </button>
          </div>
        ) : (
          <div>
            <button
              disabled
              onClick={handleScreenClear}
              type="button"
              className="button-outline-dark"
              style={{ fontSize: "10px", padding: "0px 10px", height: "25px" }}
            >
              <i class="fa-solid fa-x" style={{height:'12px', marginRight:'5px'}}></i>
              Clear player window
            </button>
          </div>
        )}
        </div>



            </div>      
          </div> */}

        <div className="chat-messages-box">
          <div
            className="chat-group"
            style={{ paddingLeft: "0", paddingRight: "0" }}
          >
            <div id="messageFeed">
              <div>
                {/* <Message_Intro id={this.props.game.id} game={game} isPlayerWindowOpen={this.state.isPlayerWindowOpen} /> */}
              </div>

              {messages
                ? messages.map((messageObj) => {
                    if (messageObj.type === "screen-cleared") {
                      return <Message_ClearScreen messageObj={messageObj} />;
                    } else if (messageObj.type === "timer-start") {
                      return (
                        <Message_StartStop type={"start"} messageObj={messageObj} />
                      );
                    } else if (messageObj.type === "alert-button") {
                      return (
                        <Bot type={"alert-button"} messageObj={messageObj} />
                      );
                    } else if (messageObj.type === "puzzle-header") {
                      return (
                        <div
                          key={Math.random() * 1000}
                          className="chat-group-divider"
                        >
                          <ion-icon
                            name="rocket"
                            style={{ marginRight: "5px" }}
                          ></ion-icon>
                          {messageObj.puzzle}
                        </div>
                      );
                    } else if (messageObj.type === "timer-adjust") {
                      return (
                        <Bot type={"timer-adjust"} messageObj={messageObj} />
                      );
                    } else if (messageObj.type === "game-ended") {
                      return (
                        <Message_StartStop type={"stop"} messageObj={messageObj} />
                      );
                    } else if (messageObj.type === "audio") {
                      return (
                        <Message_Audio
                          account={account}
                          messageObj={messageObj}
                          timer={this.props.timer}
                          messages={messages}
                        />
                      );
                    } else if (messageObj.type === "image") {
                      return (
                        <Message_Image
                          account={account}
                          messageObj={messageObj}
                          timer={this.props.timer}
                          messages={messages}
                        />
                      );
                    } else if (messageObj.type === "video") {
                      return (
                        <Message_Video
                          account={account}
                          messageObj={messageObj}
                          timer={this.props.timer}
                          messages={messages}
                          currentTime={this.state.videoClue_currentTime}
                          duration={this.state.videoClue_duration}
                          videoClue_id={this.state.videoClue_id}
                          handleScreenClear={handleScreenClear}
                        />
                      );
                    } else {
                      return (
                        <Message_Text
                          account={account}
                          profile={profile}
                          messageObj={messageObj}
                          timer={this.props.timer}
                          messages={messages}
                        />
                      );
                    }
                  })
                : null}

              {/* {messages && messages.length < 1 ? 
                <div key={Math.random() * 1000} style={{margin:'0 auto', opacity:'0.5', textAlign:'center'}}>
                  <i className="icon ion-ios-text" style={{fontSize:'28px'}}></i>
                  <h5>No messages.</h5>
                </div>: null} */}

              {videoIsPlaying && (
                <Bot type={"video-playing"} progressBar={progressBar} />
                // <div
                //   key={Math.random() * 1000}
                //   className="media"
                //   style={{ padding: "10px 20px", margin:'10px 0px' }}
                // >
                //   <i
                //     className="icon ion-ios-play"
                //     style={{ color: "white" }}
                //   ></i>
                //   <div
                //     className="media-body"
                //     style={{ marginLeft: "10px" }}
                //   >
                //     <h5
                //       className="mg-b-0"
                //       style={{ fontWeight: "500", fontSize:'15px' }}
                //     >
                //       Video brief is playing.
                //     </h5>
                //     <small className="d-block text-muted">
                //       The timer will automatically start when finished.
                //     </small>

                //     <div className="progress" style={{marginTop:'10px', width:'50%', height:'5px'}}>
                //       <div className="progress-bar"
                //       style={{width: progressBar + "%"}}
                //       role="progressbar"
                //       aria-valuenow="25"
                //       aria-valuemin="0"
                //       aria-valuemax="100"></div>
                //     </div>
                //   </div>
                // </div>
              )}
            </div>
          </div>
        </div>

        {/* <div
          style={{
            position: "absolute",
            bottom: "235px",
            left: "10px",
            width: "60%",
          }}
        >
          {showCountdownBar ? (
            <div>
              <div className="progress ht-2 mg-b-10 mg-t-10">
              <div
                style={{ width: countdownWidth + "%" }}
                className="progress-bar bg-danger"
                role="progressbar"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            </div>
          ) : null}
        </div> */}

        <div
          className="w-full flex items-center justify-center"
          style={{ position: "absolute", bottom: "168px" }}
        >
          {this.props.timer.clueOnScreen || this.props.remote.clueOnScreen ? (
            <div>
              <button
                onClick={handleScreenClear}
                type="button"
                className="button-red-dark clear-screen"
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  padding: "0px 10px",
                  height: "25px",
                }}
              >
                <i
                  class="fa-solid fa-x"
                  style={{ height: "12px", marginRight: "5px" }}
                ></i>
                Clear player window
              </button>
            </div>
          ) : (
            <div>
              <button
                disabled
                onClick={handleScreenClear}
                type="button"
                className="button-outline-dark"
                style={{
                  fontSize: "10px",
                  padding: "0px 10px",
                  height: "25px",
                }}
              >
                <i
                  class="fa-solid fa-x"
                  style={{ height: "12px", marginRight: "5px" }}
                ></i>
                Clear player window
              </button>
            </div>
          )}
        </div>

        <MessageInput
          handleAddMessage={handleAddMessage}
          handleAddAudioClue={handleAddAudioClue}
          handleAddMessageKeyDown={handleAddMessageKeyDown}
          handleLoadPreviousClue={handleLoadPreviousClue}
          loadedClue={loadedClue}
          handleLoadClue={handleLoadClue}
          hintsUsed={hintsUsed}
          avgCluePerMinute={avgCluePerMinute}
          addedMinutes={addedMinutes}
          togglePlayAudioClue={togglePlayAudioClue}
          previousMessage={previousMessage}
          handleClearLoadedClue={handleClearLoadedClue}
          handleSendImageClue={handleSendImageClue}
          handleSendVideoClue={handleSendVideoClue}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    timer: state.timer,
    remote: state.remote,
    account: state.firestore.ordered.user_accounts
      ? state.firestore.ordered.user_accounts[0]
      : null,
    profile: state.firebase.profile,
    game: state.firestore.ordered.gamemaster
      ? state.firestore.ordered.gamemaster[0]
      : null,
  };
};

export default connect(mapStateToProps, null)(Messages);
