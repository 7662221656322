import React, {useState, useEffect} from 'react'
import { Link } from "react-router-dom";
import moment from 'moment'
import { loadStripe } from "@stripe/stripe-js";

import ActiveSubBlock from './components/ActiveSubBlock'
import NotActiveSubBlock from './components/NotActiveSubBlock'

import { handleCheckoutClick } from '../../utils/Utils';

function AccountBilling(props) {

    const { account, uid } = props; 
    const [accountData, setAccountData] = useState({
      room_usage: 0
    })

    const [subscription_status, setSubscriptionStatus] = useState(null);


    useEffect(() => {
      
      
      if (account){
        setAccountData(account)

        // Set Subscription Status
        const subscription_status_type = account && account.subscription_status ? account.subscription_status.type : null;
        setSubscriptionStatus(subscription_status_type)
      }
    }, [account])

    if (!account){
        return (
          <div className="content ht-100v pd-0">
              <div
                className="text-center"
                style={{
                  left: "50%",
                  marginLeft: "-2em",
                  position: "absolute",
                  top: "40%",
                }}
              >
                <div
                  className="spinner-border"
                  style={{ width: "5rem", height: "5rem" }}
                ></div>
              </div>
            </div>
        )
      }

  // Has Active Subscription?
  const active_subscription = () => {
    if (account.subscription) {
      if (account.subscription.status === "active") {
        return true;
      } else if (account.subscription.status === "cancelled") {
        return false;
      }
    }
  };

  // Stripe Customer ID
  const stripe_id = account.subscription.customer_id
  ? account.subscription.customer_id
  : null;

  // Plan Type
  const plan_type = () => {
    if (account.subscription.product_id) {
      if (account.subscription.product_id === "prod_JDMsnc2gCLWWkA") {
        return "Starter Plan";
      } else if (
        account.subscription.product_id === "prod_JDMvOig4rtxg0U"
      ) {
        return "Unlimited";
      } else if (
        account.subscription.product_id === "prod_JDMw9iECHFJwCs"
      ) {
        return "Scale Plan";
      } else {
        return "Active Subscription"
      }
    }
  };


  const getRoomLimit = () => {
    const plan_type = account ? account.subscription.product_id : null;

    if (plan_type === 'prod_JDMsnc2gCLWWkA' || plan_type === undefined) return '1 of 1 room'
    if (plan_type === 'prod_JDMvOig4rtxg0U') return 'Unlimited Rooms'
    if (plan_type === 'prod_JDMw9iECHFJwCs') return 10
    
  }

  console.log('get room limit', getRoomLimit())
  console.log('sub status', subscription_status)

  // const progressBar = (accountData.room_usage / getRoomLimit()) *100

    const subscription = account.subscription ? account.subscription : null;
    const subscription_amount = account.subscription.plan_items ? account.subscription.plan_items.data[0].price.unit_amount : 0

    const current_period_end = account.subscription.current_period_end
    ? moment(account.subscription.current_period_end * 1000).format("LL")
    : "";

    return (
        <div>
            <div class="billing-main-header">
            <div class="div-block-7">
              <h1 class="heading-2">Billing</h1>
            </div>
            <div class="billing-tabs-container">
              <div class="div-block-11">
                <Link to="/account/billing">Overview</Link>
              </div>
              <div class="div-block-12">
                {active_subscription() && (<Link to="/account/billing/history" class="paragraph-7">Billing History</Link>)}
              </div>
              <div>
                {active_subscription() && (<Link to="/account/billing/payment" class="paragraph-8">Payment Method</Link>)}
              </div>
            </div>
          </div>
          <div class="billing-container">

        {active_subscription() ? (
            <ActiveSubBlock 
            subscription={subscription}
            plan_type={plan_type}
            stripe_id={stripe_id}
            subscription_amount={subscription_amount}
            current_period_end={current_period_end}
            uid={uid}
            account={account}
             />

        ) : <NotActiveSubBlock stripe_id={stripe_id} handleCheckoutClick={handleCheckoutClick} uid={uid} subscription_status={subscription_status} />}

            {/* <div>
                <div class="div-block-15">
                <div class="div-block-16">
                    <ion-icon name="cube-outline" size="large"></ion-icon>
                </div>
                <div class="div-block-17">
                    <p class="paragraph-13">Room Usage</p>
                    <p class="paragraph-12">{getRoomLimit()}</p>

                </div>
                </div>
            </div> */}
          </div>
        </div>
    )
}

export default AccountBilling
