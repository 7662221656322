import React, {useState} from 'react'
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import moment from 'moment'
import { loadStripe } from "@stripe/stripe-js";

import NewUser from './modals/NewUser'
import UpdateUser from './modals/UpdateUser'

import SubsModal from './modals/SubsModal';

function AccountUsers(props) {

  const {account_users, user_invites, account} = props;

  const [selectedUser, setSelectedUser] = useState()

  const checkUserPermissions = (uid) =>{
    const account = props.account;
    const permissions = account && account.permissions ? account.permissions: null;

    function search(id, myArray){
        for (var i=0; i < myArray.length; i++) {
            if (myArray[i].user_id === id) {
                return myArray[i];
            }
        }
    }
    
    if (permissions){
        const gm_permissions = search(uid, permissions)
        if (gm_permissions) return gm_permissions
    } else {
        return { isAdmin: true }
    }

}

// Stripe Customer ID
const stripe_id = account.subscription.customer_id
? account.subscription.customer_id
: null;

// Subscription Status
const subscription_status = account.subscription.status ? account.subscription.status : null;

// Hintspace Stripe Key
const stripePromise = loadStripe("pk_live_51IalDdGI8KW4Qd3J28lgIKcaZ3LzTul98KOFNm2DJZZsrRPT9AyIf8mohCscZWAgGUQRfDs4ywwNQtM0lUcXmVbS00is33DZeV");


const handleCheckoutClick = async (customer_id, price_id) => {
  console.log("Handle Checkout", customer_id, price_id);

  const stripe = await stripePromise;

  return fetch(
    "https://us-central1-escaperoomwaiver-v2.cloudfunctions.net/stripe_CheckoutSession",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        line_items: [
          {
            price: price_id,
            quantity: 1
          },
        ],
        customer_id: customer_id,
      }),
    }
  )
    .then(function (result) {
      return result.json();
    })
    .then((data) => {
      stripe
        .redirectToCheckout({
          sessionId: data.sessionId,
        })
        .then((result) => console.log("success", result));
    });
};

    return (
        <div>
            <div class="account-main-header">
            <div class="div-block-7">
              <h1 class="heading-2">Team Members</h1>
              <div>
                {checkUserPermissions(props.uid).role === 'Admin' && (
                  <a href={subscription_status == 'active' ? '#NewUserModal' : '#subsModal'} data-toggle="modal" className="account-button-blue w-button">
                  <ion-icon name="person-add-outline" style={{marginRight:'7px', marginBottom:'3px'}}></ion-icon>
                  New User
                </a>
                )}
                
              </div>
            </div>
          </div>
          <div class="account-users-container">
            <div class="user-container-header">
              <div class="users-profile-block">
                <div>
                  <p class="paragraph-6">NAME</p>
                </div>
              </div>
              <div class="div-block-10">
                <p class="paragraph-6">ROLE</p>
              </div>
            </div>

            {account_users && account_users.map(user => {
              const permissions = checkUserPermissions(user.id)
              const userData = {
                permissions,
                user
              }
              
              if (checkUserPermissions(props.uid).role === 'Admin') {
                return (
                  <a 
                  class="user-container" 
                  onClick={()=> setSelectedUser(userData)} 
                  href="#EditUserModal" 
                  data-toggle="modal">
                    <div class="users-profile-block"><img src="https://d3e54v103j8qbb.cloudfront.net/plugins/Basic/assets/placeholder.60f9b1840c.svg" loading="lazy" width="50" height="50" alt="" class="account-user-avatar" />
                      <div class="div-block-9">
                        <p class="paragraph-3">{user.fullName}</p>
                        <p class="paragraph-4">{user.email}</p>
                      </div>
                    </div>
                    <div class="div-block-10">
                      <p class="paragraph-5">{permissions ? permissions.role : null}</p>
                    </div>
                  </a>
                )
              } else {
                return (
                  <div  class="user-container">
                    <div class="users-profile-block"><img src="https://d3e54v103j8qbb.cloudfront.net/plugins/Basic/assets/placeholder.60f9b1840c.svg" loading="lazy" width="50" height="50" alt="" class="account-user-avatar" />
                      <div class="div-block-9">
                        <p class="paragraph-3">{user.fullName}</p>
                        <p class="paragraph-4">{user.email}</p>
                      </div>
                    </div>
                    <div class="div-block-10">
                      <p class="paragraph-5">{permissions ? permissions.role : null}</p>
                    </div>
                  </div>
                )
              }
            })}

          {user_invites && user_invites.map(user => {

              return (
                <a class="user-container" >
                  <div class="users-profile-block"><img src="https://d3e54v103j8qbb.cloudfront.net/plugins/Basic/assets/placeholder.60f9b1840c.svg" loading="lazy" width="50" height="50" alt="" class="account-user-avatar" />
                    <div class="div-block-9">
                      <p class="paragraph-3">{user.email}</p>
                      <p class="paragraph-4">{moment(user.created_at.seconds*1000).format('LLL')}</p>
                    </div>
                  </div>
                  <div class="div-block-10">
                    <p class="paragraph-5">Invited - Pending</p>
                  </div>
                </a>
              )
            })}

          </div>

          <NewUser account={props.account} />
          <UpdateUser selectedUser={selectedUser} account={props.account} />
          <SubsModal handleCheckoutClick={handleCheckoutClick} stripe_id={stripe_id} />
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
  console.log(state);

  const account_id = state.firebase.profile.account_id
    ? state.firebase.profile.account_id
    : null;

  return {
    account_id,
    account: state.firestore.ordered.user_accounts ? state.firestore.ordered.user_accounts[0] : null,
    account_users: state.firestore.ordered.users ? state.firestore.ordered.users : null,
    user_invites: state.firestore.ordered.user_invites ? state.firestore.ordered.user_invites : null,
    uid: state.firebase.auth.uid ? state.firebase.auth.uid : "123",
  };
};

//   const mapDispatchToProps = (dispatch) => {
//     return {
//       addNewRoom: (name) => dispatch(addNewRoom(name)),
//       deleteRoom: (room_id) => dispatch(deleteRoom(room_id)),
//     };
//   };

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => [  
     {
      collection: "users",
      where: ['account_id', '==', props.account_id]
      },
      {
        collection: "user_invites",
        where: ['account_id', '==', props.account_id]
      },
  ])
)(AccountUsers);
