import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { classNames } from "../../../utils/Utils";


type Props = {
    account: any;
};

const ProFeatureOnly = (props: Props) => {
  const {
    account
  } = props;


  return (
    <div
      className="modal effect-scale"
      id="proFeatureOnly"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered wd-sm-650"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body">
            <div className="mb-8">
              <p className="text-lg mb-1 text-white font-medium">Premium Feature</p>
              <p>You're on a plan which doesn't include features like image, video, or audio clues.</p>
            </div>

            
          </div>

          <div className="modal-footer-dark">
          <button
            type="button"
            onClick={()=> window.open("/account/billing", "_blank")}
                className="button-green-dark py-[6px]"
              >
                {account.subscription.status === "cancelled" || account.subscription.status === "canceled" ? 'Upgrade plan' : 'Free 14-day trial' }
              </button>
            <button
              type="button"
              className="button-outline-dark ml-2"
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};


export default connect(null, null)(ProFeatureOnly);