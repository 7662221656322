const initState = {
    account_save_success: false,
    account_save_error: '',
    upload_audio_progress: 0,
    upload_profile_pic_progress: 0,
    upload_image_clue_progress: 0,
    uploaded_image_clue_data: null,
    upload_video_clue_progress: 0,
    uploaded_video_clue_data: null,
    video_clue_thumbnails: []
}

const settingsReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SAVE_ACCOUNT_SETTINGS': 
            console.log("Saved Account Settings!")
            return {
                ...state,
                account_save_success: true
            };
        
        case 'SAVE_ACCOUNT_SETTINGS_ERROR':
            console.log("Saved Account Error")
            return {
                ...state,
                account_save_error: action.error.message
            } 
            
        case 'SAVE_GAME_SETTINGS': 
            console.log("Saved Game Settings!")
            return {
                ...state,
                game_save_success: action.status
            };
        
        case 'SAVE_GAME_SETTINGS_ERROR':
            console.log("Saved Game Error")
            return {
                ...state,
                game_save_error: action.error.message
            }  

        case 'SAVE_LIVEWINDOW_SETTINGS': 
            console.log("Saved Live Window Settings!")
            return {
                ...state,
                livewindow_save_success: action.status
            };
        
        case 'SAVE_LIVEWINDOW_SETTINGS':
            console.log("Save Live Window Error")
            return {
                ...state,
                livewindow_save_error: action.error.message
            } 

        case 'UPLOAD_AUDIO_PROGRESS':
            return {
                ...state,
                upload_audio_progress: {
                    progress: action.progress,
                    type: action.file_type
                }
            } 

        case 'UPLOAD_IMAGE_CLUE_PROGRESS':
            return {
                ...state,
                upload_image_clue_progress: action.progress
            } 

        case 'UPLOADED_IMAGE_CLUE_DATA':
                return {
                    ...state,
                    uploaded_image_clue_data: action.data
                } 

                case 'UPLOAD_VIDEO_CLUE_PROGRESS':
                    return {
                        ...state,
                        upload_video_clue_progress: action.progress
                    } 
        
                case 'UPLOADED_VIDEO_CLUE_DATA':
                        return {
                            ...state,
                            uploaded_video_clue_data: action.data
                        } 
            
            case 'UPLOAD_PROFILE_PIC_PROGRESS':
            return {
                ...state,
                upload_profile_pic_progress: {
                    progress: action.progress,
                    type: action.file_type
                }
            }  

            case 'VIDEO_CLUE_THUMBNAIL':
                //console.log("NEW assets!", action.new_assets)
                var video_clue_thumbnails = state.video_clue_thumbnails
                video_clue_thumbnails.push(action.thumbnail)
                
                return {
                    ...state,
                    video_clue_thumbnails,
                }

                
        default:
            return state;
    }
}

export default settingsReducer