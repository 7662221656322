import React, { useState } from "react";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";

//import AudioMeter from "./AudioMeter";
import { Howler } from "howler";
import { saveAudioState, loadAudioState } from "../../../../localStorage";


const AudioTrack = (props) => {
  const { title, audio, save_key, type, status } = props;

  const audioState = loadAudioState();
  const savedAudioState = audioState[save_key] * 100 || 100;
  const [volume, setVolume] = useState(savedAudioState);
  const [mute, setMute] = useState(false);
  //const [seek, setSeek] = useState(0);
  const [videoPreviousVol, setvideoPreviousVol] = useState();

  //const [disabledState, setdisabledState] = useState(disabled);

  const checkDisable = () => {
      if (props.disabled === 'true') return { opacity:'0.5', pointerEvents:'none'}
  }
  

  const updateVolume = (value) => {
    const vol = value / 100;
    setVolume(value);
    if (type === "Howler") {
      // Change global volume.
      Howler.volume(vol);
      saveAudioState(save_key, vol);
    } else if (type === "video_brief") {
      saveAudioState(save_key, vol);
    } else {
      audio.sound.volume(vol);
      saveAudioState(save_key, vol);
    }
  };

  const muteAudio = () => {
    if (!mute) {
      if (type === "Howler") {
        Howler.mute(true);
        setMute(!mute);
      } else if (type === "video_brief") {
        setvideoPreviousVol(volume);
        saveAudioState(save_key, 0);
        setMute(!mute);
      } else {
        audio.sound.mute(true);
        setMute(!mute);
      }
    } else {
      if (type === "Howler") {
        Howler.mute(false);
        setMute(!mute);
      } else if (type === "video_brief") {
        const previousVol = videoPreviousVol / 100;
        saveAudioState(save_key, previousVol);
        setMute(!mute);
      } else {
        audio.sound.mute(false);
        setMute(!mute);
      }
    }
  };

  const playPauseButton = () => {
    if (type === "Howler") {
      // Change global volume.
    } else if (type === "video_brief") {
    } else {
      if (audio && audio.sound && audio.sound.playing()) {
        return true;
      } else {
        return false;
      }
    }
  };

  const playPauseAudio = () => {
    if (type === "Howler") {
      // Change global volume.
    } else if (type === "video_brief") {
    } else {
      if (audio.sound.playing()) {
        audio.sound.stop();
      } else {
        audio.sound.play();
      }
    }
  };

  return (
    <div className="audio-track" style={{width:'16.6666666667%', background:'#303038'}}>
      {/* <div className="sub-header">
                        <h6 style={{marginBottom:"0px"}}>A2</h6>
                    </div> */}

      <div className="sub-header">
        <h6
          style={{
            fontSize: "12px",
            marginBottom: "0px",
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {title}
          
        </h6>
      </div>

      <div className="solo-mute" style={checkDisable()}>
        <div
          className="btn-group btn-group-sm w-100"
          role="group"
          aria-label="Basic example"
        >
          {mute ? (
            <button
              onClick={() => muteAudio()}
              style={{
                fontSize: "10px",
                padding: "5px",
                background: "#191a20",
              }}
              type="button"
              className="btn btn-outline-light"
            >
              <i
                className="icon ion-ios-volume-off"
                style={{ fontSize: "16px" }}
              ></i>
            </button>
          ) : (
            <button
              onClick={() => muteAudio()}
              style={{ fontSize: "10px", padding: "5px" }}
              type="button"
              className="btn btn-outline-light"
            >
              <i
                className="icon ion-ios-volume-high"
                style={{ fontSize: "16px" }}
              ></i>
              
            </button>
          )}
        </div>
      </div>

      <div className="solo-mute" style={checkDisable()}>
        <div
          className="btn-group btn-group-sm w-100"
          role="group"
          aria-label="Basic example"
        >
          {playPauseButton() ? (
            <button
              onClick={() => playPauseAudio()}
              style={{
                fontSize: "10px",
                padding: "10px 5px",
                background: "#191a20",
              }}
              type="button"
              className="btn btn-outline-light"
            >
              <i
                className="icon ion-ios-pause"
                style={{ fontSize: "16px" }}
              ></i>
            </button>
          ) : (
            <button
              onClick={() => playPauseAudio()}
              style={{ fontSize: "10px", padding: "10px 5px" }}
              type="button"
              className="btn btn-outline-light"
            >
              <i
                className="icon ion-ios-play"
                style={{ fontSize: "16px" }}
              ></i>
            </button>
          )}
        </div>
      </div>

      <div className="header">
        <h6
          style={{
            marginBottom: "0px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "11px",
          }}
        >
          {status ? status : 'No Status'}
        </h6>
      </div>

      <div className="volume-meter" style={checkDisable()}>
        <div style={{ display: "inline-block", margin: "auto" }}>
          <h6
            style={{
              fontSize: "10px",
              marginBottom: "0px",
              marginTop: "10px",
              width: "18px",
            }}
          >
            {volume.toFixed(0)}
          </h6>
          <Slider
            min={0}
            max={100}
            value={volume}
            orientation="vertical"
            onChange={updateVolume}
          />
        </div>

        {/* <AudioMeter id="audio-meter-alert" /> */}
      </div>
    </div>
  );
};

export default AudioTrack;
