import React, { useState } from "react";
import { connect } from "react-redux";

import { updateTimer } from "../../../../redux/actions/timerActions";

import { Style, ProfilePic } from "./Exports";

type Props = {
  id: any;
  game: any;
  isPlayerWindowOpen: boolean;
  updateTimer: any;
  timer: any;
};

const Message_Intro = (props: Props) => {
  const { game, isPlayerWindowOpen, id } = props;

  const game_name = game ? game.name : "";
  const timer = game && game.timer ? game.timer.time : { m: "00", s: "00" };

  const openLiveWindow = () => {
    const { id } = props;
    var params = "width=" + (window.screen.width - 200);
    params += ", height=" + (window.screen.height - 200);
    params += ", left=1000";
    params += ", menubar=1";

    props.updateTimer({
      ...props.timer,
      isPlayerWindowOpen: true,
    });

    window.open("/r/" + id + "/live", "windowname4", params);
  };

  return (
    <div style={{ marginBottom: "50px" }}>
      <div
        key={Math.random() * 1000}
        className="media"
        style={Style(null, null, null, "intro")}
      >
        <div
          className="media-body"
          style={{
            marginLeft: "10px",
            paddingRight: "20px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: "23px",
                marginBottom: "0px",
                marginTop: "0px",
                height: "18px",
              }}
            ></div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "4px",
              }}
            >
              <ProfilePic icon="rocket" type="intro" />
              <div>
                <p className="text-white"
                  style={{
                    fontSize: "16px",
                    whiteSpace: "pre-wrap",
                    marginBottom: "0px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Welcome to the message feed: {game_name}
                </p>

                <p
                  className="mg-t-0 mg-b-2 text-gray-300"
                >
                  Players will have <b>{timer.m}:{timer.s}</b> to complete the room.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {!isPlayerWindowOpen && (
        <div style={{ padding: "10px 80px" }}>
          <button id="openPlayerScreen" onClick={openLiveWindow} className="btn btn-warning">
            <i
              className="fa-solid fa-square-exclamation"
              style={{ marginRight: "5px" }}
            ></i>
            <b>Please open the Player Screen</b>
          </button>
        </div>
      )} */}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    timer: state.timer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateTimer: (timer: any) => dispatch(updateTimer(timer)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Message_Intro);
