import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { classNames, generateVideoThumbnail } from "../../../utils/Utils";

import { connect } from "react-redux";
import { getVideoClueThumbnail } from "../../../redux/actions/gmActions";

type Props = {
  account: any;
  clueObj: any;
  loadedClue: any;
  handleLoadClueClick: any;
  puzzlesDone: any;
  selectedPuzzleObj: any;
  color: any;
  isSent: boolean;
  userPermissions: { isAdmin: boolean; canEditHints: boolean };
  sortClueStatus: any;
  getVideoClueThumbnail: (path: any, game_doc: string) => void;
  game_doc: string;
  upload_video_clue_progress: number;
  uploaded_video_clue_data: any;
  video_clue_thumbnails: any;
};

const VideoClueButton = (props: Props) => {
  const {
    account,
    clueObj,
    loadedClue,
    handleLoadClueClick,
    puzzlesDone,
    selectedPuzzleObj,
    color,
    isSent,
    userPermissions,
    sortClueStatus,
    game_doc,
  } = props;

  console.log("GAME DOC", game_doc);
  const [showControls, setShowControls] = useState(true);

  const includesId = selectedPuzzleObj ? selectedPuzzleObj.id : 0;

  const style = () => {
    if (isSent) {
      if (loadedClue.id === clueObj.id) {
        return {
          background: "rgb(23, 24, 28)",
          border: "2px solid #0168fa",
          opacity: "0.5",
        };
      } else {
        return {
          border: "2px solid transparent",
          opacity: "0.5",
        };
      }
    } else if (loadedClue.id === clueObj.id) {
      return {
        background: "rgb(23, 24, 28)",
        border: "2px solid #0168fa",
      };
    } else {
      return {
        border: "2px solid transparent",
      };
    }
  };

  const clueObject = {
    ...clueObj,
    color,
  };

  return (
    <button
      className={classNames("media")}
      disabled={account.subscription.status !== "active" ? true : false}
      onClick={() => handleLoadClueClick(clueObject)}
      onMouseOver={() =>
        setShowControls(
          userPermissions.isAdmin || userPermissions.canEditHints ? true : false
        )
      }
      onMouseLeave={() => setShowControls(false)}
      style={{ ...style() }}
    >
      {account.subscription.status !== "active" && (
        <div className="absolute right-0 left-0 h-[50px] flex items-center justify-center text-white z-10">
          <a
            href="#proFeatureOnly"
            data-toggle="modal"
            className="button-green-dark py-1 px-2 rounded text-xs"
          >
            <i className="fa-sharp fa-regular fa-lock mr-2"></i>Premium
          </a>
        </div>
      )}

      <div
        className={classNames(
          "flex w-full",
          account.subscription.status !== "active"
            ? "brightness-50 blur-[1px]"
            : "opacity-100"
        )}
      >
        <div className="contents">
          <img
            src={clueObj && clueObj.thumbnail}
            className="w-[50px] h-[50px]  object-cover rounded"
            loading="lazy"
          ></img>
          {/* <i className="fa-solid fa-image mt-[3px] text-gray-400"></i> */}
        </div>

        <div
          className="media-body mg-l-10 w-[75%]"
          style={{ alignItems: "center" }}
        >
          <h6
            className="mg-b-0 break-words overflow-hidden text-ellipsis line-clamp-2 text-gray-50"
            style={{
              fontStyle: isSent ? "italic" : "inherit",
            }}
          >
            {clueObj.name}
          </h6>

          <div className="flex items-center center space-x-2 pt-1">
            {/* {clueObj.rating >= 1 && (
            <small
            className="d-block text-muted"
            style={{ display: "flex", marginRight: "10px" }}
          >
            {clueObj.rating ? clueRating() : null}
          </small>
          )} */}

            <i className="fa-light fa-clapperboard-play text-gray-400"></i>
            <p className="text-gray-400 text-xs">Video</p>

            <p className="text-xs bg-purple-700 text-white px-1 rounded uppercase font-medium">
              Beta
            </p>

            {/* <p className="text-gray-500 text-xs">
            {clueObj.file.name}
          </p> */}
          </div>

          {showControls ? (
            <Tooltip title="Edit" placement="right">
              <a
                // style={{fontSize:'18px', height:'16px', float:'right'}}
                style={{
                  fontSize: "18px",
                  position: "absolute",
                  right: "20px",
                  transform: "translate(0px, -16px)",
                }}
                href="#editDeleteVideoClueModal"
                onClick={() => handleLoadClueClick(clueObj)}
                data-toggle="modal"
              >
                <i className="icon ion-ios-more"></i>
              </a>
            </Tooltip>
          ) : null}
        </div>
      </div>
    </button>
  );
};

const mapStateToProps = (state: any, props: any) => {
  return {
    ...state,
    upload_video_clue_progress: state.settings.upload_video_clue_progress,
    uploaded_video_clue_data: state.settings.uploaded_video_clue_data,
    video_clue_thumbnails: state.settings.video_clue_thumbnails,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getVideoClueThumbnail: (path: any, game_doc: string) =>
      dispatch(getVideoClueThumbnail(path, game_doc)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoClueButton);
