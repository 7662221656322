import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/storage'
import 'firebase/compat/analytics'
// import {initializeApp} from 'firebase/app'
// import 'firebase/firestore'
// import 'firebase/auth'
// import 'firebase/storage'
// import {getAnalytics} from 'firebase/analytics'
// import { enableIndexedDbPersistence } from "firebase/firestore"; 

var config = {
    apiKey: "AIzaSyCm6vT_D2qPpl6HJ1W9SwQzI_1Mlk1R7TU",
    authDomain: "auth.boltgm.com",
    databaseURL: "https://escaperoomwaiver-v2.firebaseio.com",
    projectId: "escaperoomwaiver-v2",
    storageBucket: "escaperoomwaiver-v2.appspot.com",
    messagingSenderId: "406728580970",
    appId: "1:406728580970:web:e957269a1d6470eadd4f13",
    measurementId: "G-L06YKC8ZR0"
};

firebase.initializeApp(config)
firebase.analytics()
// const firebase = initializeApp(config);
// getAnalytics(firebase)
//firebase.firestore();

// enableIndexedDbPersistence(firebase)
//   .catch((err) => {
//       if (err.code == 'failed-precondition') {
//           // Multiple tabs open, persistence can only be enabled
//           // in one tab at a a time.
//           // ...
//           console.log('PERSISTENCE ERROR:', err)
//       } else if (err.code == 'unimplemented') {
//           // The current browser does not support all of the
//           // features required to enable persistence
//           // ...
//           console.log('PERSISTENCE - Unimplemented:', err)
//       }
//   });


// firebase.firestore().enablePersistence()
// .catch(function(err) {
//     if (err.code == 'failed-precondition') {
//         // Multiple tabs open, persistence can only be enabled
//         // in one tab at a a time.
//         // ...
//         console.log('PERSISTENCE ERROR:', err)
//     } else if (err.code == 'unimplemented') {
//         // The current browser does not support all of the
//         // features required to enable persistence
//         // ...
//         console.log('PERSISTENCE ERROR:', err)
//     }
// });


export default firebase