import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { RadioGroup } from "@headlessui/react";

import {
  signIn,
  signInWithGoogle,
  checkUserExists,
  signOut,
} from "../../../redux/actions/authActions";
import { uploadUserProfilePic, createAccount } from "../../../redux/actions/settingsActions";
import { Redirect, Link } from "react-router-dom";
//Toasts
import { Toaster } from "sonner";

import { classNames } from "../../../utils/Utils";
import Steps from "./Sub/Steps";

// Steps
import CompanyInfo from "./Components/CompanyInfo";

const plans = [
  {
    name: "I own an escape room",
    type: "company",
    icon: "fa-duotone fa-users text-lg",
  },
  {
    name: "I work for an escape room",
    type: "individual",
    icon: "fa-duotone fa-user text-lg",
  },
  {
    name: "I'm just exploring",
    type: "starter",
    icon: "fa-duotone fa-cube text-lg",
  },
];

function Onboarding(props) {
  const { authError, auth } = props;
  if (!auth.uid) return <Redirect to="/" />;

  const [company, setCompany] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState();
  const [emailExists, setEmailExists] = useState(false);

  const [currentStep, setCurrentStep] = useState(1);
  const [selected, setSelected] = useState(plans[0]);

  const [photoURL, setPhotoURL] = useState(props.profile_pic);

  // Data
  const [companyInfo, setCompanyInfo] = useState({
    name: null,
    type: null,
    location: null,
    size: null,
    website: null
  });

  const [userProfile, setUserProfile] = useState({
    first_name: null,
    last_name: null,
    user_type: null,
  });

  useEffect(() => {
    const step = new URLSearchParams(props.location.search).get("step");

    if (!step) {
      setCurrentStep(1);
    } else {
      setCurrentStep(Number(step));
    }
  }, [props]);

  const checkEmail = async () => {
    setLoading(true);
    props.checkUserExists(email).then((exists) => {
      setTimeout(() => {
        setEmailExists(exists);
        setLoading(false);
      }, 1000);
    });
  };

  const uploadPic = (file) => {
    setPhotoURL(URL.createObjectURL(file))
    props.uploadUserProfilePic(file)
  }

  const completeOnboarding = () => {
    console.log('lets go')
    const settings = {
      companyInfo,
      userProfile
    }
    props.createAccount(settings)
  }


  console.log("company", companyInfo);
  console.log("user info", userProfile);

  return (
    <React.Fragment>
      <Toaster richColors position="top-center" />
      <div className="py-2 px-2">
        <button
          onClick={() => props.signOut()}
          className="button-outline-light"
        >
          Sign out
        </button>
      </div>

      <div className="pt-6 mb-12">
        <Steps currentStep={currentStep} />
      </div>

      <div
        className="w-full flex justify-center sm:px-4
    "
      >
        <div className="w-full max-w-md">
          <div>
            <div
              className="text-center flex items-center justify-center flex-col"
              style={{ paddingBottom: "20px" }}
            >
              {/* <Link to="/">
                <img
                  src="/bolt-logo.svg"
                  alt="Logo"
                  style={{
                    width: "175px",
                    marginTop: "-8px",
                    marginBottom: "30px",
                  }}
                ></img>
              </Link> */}

              {/* {!emailExists ? (
                <p className="text-2xl font-medium">Tell us about you</p>
              ) : (
                <p className="text-2xl font-medium">Enter your password</p>
              )} */}
            </div>

            {currentStep === 1 && (
              <div>
                <p className="text-2xl font-medium mb-4">Tell us about you</p>

                <RadioGroup value={selected} onChange={setSelected}>
                  <RadioGroup.Label className="sr-only">
                    {" "}
                    Server size{" "}
                  </RadioGroup.Label>
                  <div className="space-y-2">
                    {plans.map((plan) => (
                      <RadioGroup.Option
                        key={plan.name}
                        value={plan}
                        className={({ checked, active }) =>
                          classNames(
                            checked ? "border-blue-500 " : "border-gray-300",
                            active
                              ? "border-blue-500 ring-2 ring-blue-500"
                              : "",
                            "relative block cursor-pointer rounded-lg border bg-white px-6 py-3 focus:outline-none sm:flex sm:justify-between"
                          )
                        }
                      >
                        {({ active, checked }) => (
                          <>
                            <span className="flex items-center">
                              <div className="mr-3">
                                <i className={plan.icon} />
                              </div>
                              <span className="flex flex-col text-sm">
                                <RadioGroup.Label
                                  as="span"
                                  className="font-medium text-gray-900"
                                >
                                  {plan.name}
                                </RadioGroup.Label>
                              </span>
                            </span>
                            <span
                              className={classNames(
                                active ? "border " : "border-2 ",
                                checked
                                  ? "border-blue-500"
                                  : "border-transparent",
                                "pointer-events-none absolute -inset-px rounded-lg"
                              )}
                              aria-hidden="true"
                            />
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>

                <div className="mt-4">
                  <Link
                    to={"?step=2&type=" + selected.type}
                    disabled={!selected ? true : false}
                    className={classNames(
                      "auth-button duration-150 float-right flex justify-center ",
                      !selected
                        ? "pointer-events-none bg-gray-100 border border-gray-300 text-gray-300 hover:text-black"
                        : "bg-black border border-black text-white hover:bg-gray-500 hover:text-black"
                    )}
                  >
                    <svg
                      className={classNames(
                        !loading
                          ? "hidden"
                          : "w-[22px] fill-gray-300  animate-spin"
                      )}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M256 64c106 0 192 86 192 192h64C512 114.6 397.4 0 256 0V64zM448 256c0 35-9.4 67.8-25.7 96L477.7 384c21.8-37.7 34.3-81.5 34.3-128H448z" />
                    </svg>

                    <span className={classNames(loading ? "hidden" : null)}>
                      Next
                    </span>
                  </Link>
                </div>
              </div>
            )}

            {currentStep === 2 && (
              <div>
                <CompanyInfo
                  companyInfo={companyInfo}
                  setCompanyInfo={setCompanyInfo}
                  selected={selected}
                  setSelected={setSelected}
                />
              </div>
            )}

            {currentStep === 3 && (
              <div>
                <p className="text-2xl font-medium mb-4">User Profile</p>

                {/* <div className="mb-4 flex items-center">
                  {photoURL ? (
                    <img
                      src={photoURL}
                      className="w-[100px] h-[100px] rounded-full mr-4 object-cover"
                    />
                  ) : (
                    <div className="bg-gray-200 border border-gray-300 w-[100px] h-[100px] rounded-full mr-4 flex items-center justify-center">
                      <i class="fa-regular fa-bolt text-3xl text-gray-500"></i>
                    </div>
                  )}
                  <div>
                    {props.upload_progress === 0 || props.upload_progress.progress === 0 ? (
                      <label
                      htmlFor="file-upload"
                      className="button-outline-light"
                    >
                      <span>Upload</span>
                      <input
                        onChange={(e) =>
                          uploadPic(e.target.files[0])
                        }
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        className="sr-only"
                      />
                    </label>
                    ) : (
                      <p>Uploading: {props.upload_progress.progress.toFixed(0)}%</p>
                    )}
                  </div>
                </div> */}

                <div className="grid grid-cols-2 space-x-3 mb-3">
                  <div className="col-span-1">
                    <p className="mb-1">First Name *</p>
                    <input
                      name={"first_name"}
                      className="input-light auth-input shadow-sm w-full"
                      placeholder="Michael"
                      onChange={(e) =>
                        setUserProfile({
                          ...userProfile,
                          [e.target.name]: e.target.value,
                        })
                      }
                    ></input>
                  </div>
                  <div className="col-span-1">
                    <p className="mb-1">Last Name *</p>
                    <input
                      name={"last_name"}
                      className="input-light auth-input shadow-sm w-full"
                      placeholder="Scott"
                      onChange={(e) =>
                        setUserProfile({
                          ...userProfile,
                          [e.target.name]: e.target.value,
                        })
                      }
                    ></input>
                  </div>
                </div>

                <div className="mb-3">
                  <p className="mb-1">What describes you? *</p>
                  <select
                    name={"user_type"}
                    className="input-light auth-input shadow-sm w-full"
                    placeholder="USA"
                    // onChange={(e) =>
                    //   setCompanyInfo({ ...companyInfo, country: e.target.value })
                    // }
                    onChange={(e) =>
                      setUserProfile({
                        ...userProfile,
                        [e.target.name]: e.target.value,
                      })
                    }
                    value={userProfile.user_type}
                  >
                    <option>Select option</option>
                    <option>Owner</option>
                    <option>Business partner</option>
                    <option value="gamemaster">Game-master</option>
                    <option>Other</option>
                  </select>
                </div>

                <div className="mt-4 flex space-x-4">
                <button
                  onClick={()=> window.history.back()}
                  className="button-outline-light"
                >
        
                  <span>Back</span>
                </button>

                  <button
                    onClick={() => completeOnboarding()}
                    disabled={userProfile.first_name && userProfile.last_name && userProfile.user_type ? false : true}
                    className={classNames(
                      "auth-button duration-150 float-right flex justify-center disabled:opacity-50 ",
                      !selected
                        ? "pointer-events-none bg-gray-100 border border-gray-300 text-gray-300 hover:text-black"
                        : "bg-black border border-black text-white hover:bg-gray-500 hover:text-black"
                    )}
                  >
                    <svg
                      className={classNames(
                        !loading
                          ? "hidden"
                          : "w-[22px] fill-gray-300  animate-spin"
                      )}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M256 64c106 0 192 86 192 192h64C512 114.6 397.4 0 256 0V64zM448 256c0 35-9.4 67.8-25.7 96L477.7 384c21.8-37.7 34.3-81.5 34.3-128H448z" />
                    </svg>

                    <span className={classNames(loading ? "hidden" : null)}>
                      Let's go!
                    </span>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    authError: state.auth.authError,
    authLoading: state.auth.authLoading,
    auth: state.firebase.auth,
    profile_pic: state.firebase.auth.photoURL,
    upload_progress: state.settings.upload_profile_pic_progress
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds)),
    checkUserExists: (email) => dispatch(checkUserExists(email)),
    signInWithGoogle: () => dispatch(signInWithGoogle()),
    signOut: () => dispatch(signOut()),
    uploadUserProfilePic: (file) => dispatch(uploadUserProfilePic(file)),
    createAccount: (settings) => dispatch(createAccount(settings)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);