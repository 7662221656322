import React, { useState, useEffect } from "react";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";

import { Style, ProfilePic } from "./Exports";
import { classNames } from "../../../../utils/Utils";

type Props = {
  account: {
    account_email: string;
    admins: any;
    company_size: string;
    created_at: any;
    id: string;
    onboarding: { gm: boolean };
    permissions: any;
    room_usage: number;
    staff: any;
    subscription: {
      customer_id: string;
    };
    title: string;
  };
  profile: any;
  messages: any;
  messageObj: any;
  timer: any;
};

export const Message_Text = (props: Props) => {
  const { messageObj, timer, messages, account, profile } = props;

  const message = messageObj ? messageObj.message : "0";
  const clueOnScreen = timer ? timer.clueOnScreen : "0";

  console.log("game timestapm", messageObj.game_timestamp);

  // const style = () => {
  //   var style: any = {
  //       padding: "10px 20px",
  //       margin: "10px 0px",
  //       alignItems: "center"
  //   };

  //   if (hover) style = { ...style, background: "#2c2c35"}

  //   return style
  // };

  const isClueOnScreen = () => {
    if (messageObj.send_id === clueOnScreen.send_id) return true;
  };

  const [hover, setHover] = useState(false);
  const [loaded, setLoaded] = useState(true);
  // useEffect(()=> {
  //   setTimeout(() => {
  //     setLoaded(true)
  //   }, 700);
  // },[])

  if (messageObj.shortened) {
    return (
      <div className={classNames(isClueOnScreen() ? "" : null)}>
        <div
          key={messageObj.send_id}
          className="message-feed-item px-4 py-1 duration-150 hover:bg-gray-700/40"
          //style={Style(hover, clueOnScreen, messageObj)}
          // onMouseEnter={() => setHover(true)}
          // onMouseLeave={() => setHover(false)}
        >
          <div>
            {/* {isClueOnScreen() && (
              <div className="text-red-500 flex items-center mb-1 ml-[35px] mt-0">
                <i
                  className="fa-light fa-eye w-4"
                  style={{ marginRight: "10px", fontSize: "18px" }}
                ></i>
                <p className="text-xs">Visible to players</p>
              </div>
            )} */}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: "0px",
              }}
            >
              {/* <div
              style={{
                opacity: hover ? "1" : "0",
                width: "46px",
              }}
            >
              <span
                className="text-xs text-gray-500 whitespace-nowrap"
              >
                {moment(messageObj.timestamp).format("LT")}
              </span>
            </div> */}

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "75px",
                  width: "100%",
                }}
              >
                <p className="mb-0 pb-0 whitespace-pre-wrap text-white">
                  <span className="mr-2 font-medium text-blue-600">
                    {messageObj.game_timestamp.m}:{messageObj.game_timestamp.s}
                  </span>
                  {messageObj.message}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(isClueOnScreen() ? "" : null)}>
      {/* {isClueOnScreen() && (
        <div className="text-red-500 flex items-center mb-1 ml-[35px] mt-0">
          <i
            className="fa-light fa-eye w-4"
            style={{ marginRight: "10px", fontSize: "18px" }}
          ></i>
          <p className="text-xs">Visible to players</p>
        </div>
      )} */}

      <div
        key={messageObj.send_id}
        className={classNames(
          "message-feed-item px-4 py-2 duration-150 rounded-sm hover-bg-gray-700/40",
          !loaded ? "opacity-40" : "opacity-100"
        )}
      >
        <div
          className="w-full"
          style={{
            marginLeft: "15px",
            paddingRight: "20px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "4px",
              }}
            >
              {/* <ProfilePic icon="flash" type="text" /> */}
              <div>
                <div
                  style={{
                    width: "45px",
                    height: "45px",
                    borderRadius: "5px",
                    background: "rgb(59 63 68)",
                    marginRight: "15px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <i className="fa-regular fa-text text-gray-300"></i>
                </div>
              </div>

              <div>
                <p className="mb-0 whitespace-pre-wrap text-gray-100">
                  {messageObj.message}
                </p>

                <div className="flex items-center space-x-2 mt-0.5">
                  <p className="font-medium text-blue-600 mr-2 mb-0 leading-[0px]">
                    {messageObj.game_timestamp.m}:{messageObj.game_timestamp.s}
                  </p>
                  <p className="text-gray-400 text-xs whitespace-nowrap mb-0">
                    {moment(messageObj.timestamp).format("LT")}
                  </p>
                  {messageObj.puzzle && (
                    <p className="text-gray-400 mb-0">&#183;</p>
                  )}
                  <p className="text-gray-400 text-xs line-clamp-1">
                    {messageObj.puzzle && messageObj.puzzle}
                  </p>
                  {/* {isClueOnScreen() && (
                    <div className="ml-3 px-2 rounded-sm bg-red-600">
                      <p className="font-medium  text-gray-100 mb-0 text-xs">
                        PLAYER WINDOW
                      </p>
                    </div>
                  )} */}
                </div>
              </div>
            </div>

            {/* <small className="d-block text-muted">
  {moment(messageObj.timestamp).fromNow()}
</small> */}

            {/* {messageObj.puzzle ? (
  <small className="d-block text-muted">
    <span style={{ fontWeight: "500" }}>{messageObj.puzzle}</span> -
    {moment(messageObj.timestamp).fromNow()}
  </small>
) : (
  <small className="d-block text-muted">
    {moment(messageObj.timestamp).fromNow()}
  </small>
)} */}
          </div>
        </div>
      </div>
    </div>
  );
};
