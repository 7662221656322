import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import uuid from "react-uuid";

import { get, createStore } from "idb-keyval";
import {
  gameId,
  addToDB,
  deleteAsset,
  readBlob,
} from "../Functions/SettingsFunctions";

import { isSubscribed } from "../../../../utils/Utils";

const SoundtrackAlert = (props) => {
  const {
    game_data,
    game_id,
    assets,
    editAudioSoundtrack,
    editAudioAlert,
    handleSoundtrackFile,
    handleAlertFile,
    handleDeleteFile,
    handleUploadFile,
    account,
    upload_progress,
  } = props;

  // Init IndexDB
  gameId(game_id);

  const [settings, setSettings] = useState({});
  // IndexDB Items
  const [soundtrack, setSoundtrack] = useState("");
  const [audio_alert, setAudioAlert] = useState("");

  useEffect(() => {
    setSettings(assets);
    if (assets && assets.audio_soundtrack)
      setSoundtrack(assets.audio_soundtrack);
    if (assets && assets.audio_alert) setAudioAlert(assets.audio_alert);

    return () => {
      setSoundtrack("");
      setAudioAlert("");
    };
  }, [assets]);

  // useEffect(() => {
  //   const assetsStore = createStore(game_id, "assets");

  //   // Get Soundtrack
  //   get("soundtrack", assetsStore).then((val) => {
  //     if (val) {
  //       const blob = readBlob(val);
  //       var url = URL.createObjectURL(blob);
  //       setSoundtrack({ ...val, url });
  //     }
  //   });

  //   // Get Alert Tone
  //   get("audio_alert", assetsStore).then((val) => {
  //     if (val) {
  //       const blob = readBlob(val);
  //       var url = URL.createObjectURL(blob);
  //       setAudioAlert({ ...val, url });
  //     }
  //   });
  // }, []);

  const uploadSoundtrack = (file) => {
    const id = uuid();

    if (file.size < 1073741824) {
      // Create Blob
      addToDB(file, "soundtrack", setSoundtrack, soundtrack, id);

      // Upload to Database
      props.handleUploadFile(file, "audio_soundtrack", id);
    } else {
      setSoundtrack({ error: "File too large." });
    }
  };

  const uploadAudioAlert = (file) => {
    const id = uuid();

    if (file.size < 1073741824) {
      // Create Blob
      addToDB(file, "audio_alert", setAudioAlert, audio_alert, id);

      // Upload to Database
      props.handleUploadFile(file, "audio_alert", id);
    } else {
      setAudioAlert({ error: "File too large." });
    }
  };

  const deleteSoundtrack = () => {
    // Upload to Database
    props.handleDeleteFile("audio_soundtrack", soundtrack);

    // Create Blob
    deleteAsset("soundtrack", soundtrack, setSoundtrack);
  };

  const deleteAlertTone = () => {
    // Upload to Database
    props.handleDeleteFile("audio_alert", audio_alert);

    // Create Blob
    deleteAsset("audio_alert", audio_alert, setAudioAlert);
  };

  return (
    <div
      className="bd-t pd-t-20"
      style={{
        marginBottom: "50px",
      }}
    >
      {/* <h5 className="mg-b-10 mg-l-10">Soundtrack & Alert Tone</h5> */}

      <form
        className="pd-t-0"
        style={{
          width: "100%",
          // opacity: isSubscribed(account) ? "1" : "0.5",
        }}
      >
        <label htmlFor="inputEmail3" className="col-form-label tx-18 mg-b-20">
          <b>Custom Soundtrack & Alert Tone</b>
        </label>

        <div className="form-group row">
          <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">
            <b>Soundtrack</b>
          </label>
          <div className="col-sm-9">
            {soundtrack && !editAudioSoundtrack ? (
              <React.Fragment>
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      background: "#f5f6fa",
                      padding: "5px 10px",
                      borderRadius: "5px",
                    }}
                  >
                    <div
                      style={{
                        marginBottom: "0px",
                        marginRight: "20px",
                        //marginTop: "5px",
                        padding: "5px",
                        display: "flex",
                      }}
                    >
                      {/* <ion-icon
                        name="open-outline"
                        style={{ marginRight: "5px", fontSize: "22px" }}
                      ></ion-icon> */}

                      <div
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {upload_progress.type === "audio_soundtrack" &&
                        upload_progress.progress > 0 ? (
                          <React.Fragment>
                            <b>
                              Uploading: {upload_progress.progress.toFixed(1)}%{" "}
                            </b>
                            <div
                              className="progress"
                              style={{
                                marginTop: "10px",
                                width: "100%",
                                height: "5px",
                              }}
                            >
                              <div
                                className="progress-bar"
                                style={{
                                  width: upload_progress.progress + "%",
                                }}
                                role="progressbar"
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <a href={soundtrack.url} target="_blank">
                                <b>{soundtrack.name}</b>
                                <i
                                  style={{ marginLeft: "5px" }}
                                  className="fa-regular fa-up-right-from-square"
                                ></i>
                              </a>
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    </div>

                    <button
                      // disabled={isSubscribed(account) ? false : true}
                      className="btn btn-icon btn-outline-danger"
                      type="button"
                      style={{ marginLeft: "auto", padding: "0px 10px" }}
                      //  onClick={() => deleteAsset("soundtrack", setSoundtrack)}
                      // onClick={() =>
                      //   deleteFile(
                      //     "audio_soundtrack",
                      //     "soundtrack",
                      //     setSoundtrack
                      //   )
                      // }
                      onClick={() => deleteSoundtrack()}
                    >
                      <i
                        className="icon ion-ios-trash tx-18"
                        style={{ padding: "0px 0px" }}
                      ></i>
                    </button>
                  </div>
                  <small>Max size: 1GB. File type: *.mp3, *.wav</small>
                </div>

                {/* <div className="col-sm-9 pd-t-10" style={{display:'flex', alignItems:'center', paddingLeft:'0px'}}>
<div className="custom-control custom-switch">
<input 
name="loop"
onChange={(e) => props.saveAssetsSettings(game_id, 'audio_soundtrack', {loop: e.target.checked} )}
defaultChecked={assets && assets.audio_soundtrack.loop ? assets.audio_soundtrack.loop: null}
type="checkbox" 
className="custom-control-input"
id="loopAudioSoundtrack"
/>
<label className="custom-control-label" htmlFor="loopAudioSoundtrack">
<b>Loop</b>
</label>
</div>
</div>  */}
              </React.Fragment>
            ) : (
              <div className="custom-file">
                {soundtrack && soundtrack.error ? (
                  <p className="tx-danger mg-b-0">{soundtrack.error}</p>
                ) : null}
                <small>soundtrack.mp3</small>
                <input
                  // disabled={isSubscribed(account) ? false : true}
                  // onChange={(e) =>
                  //   props.handleSoundtrackFile(e.target.files[0])
                  // }
                  onChange={(e) => uploadSoundtrack(e.target.files[0])}
                  accept=".mp3,.wav"
                  name="audio_soundtrack"
                  type="file"
                  className="custom-file-input"
                  id="audio_soundtrack"
                />
                <label className="custom-file-label" htmlFor="customFile">
                  Upload file
                </label>
              </div>
            )}
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">
            <b>Alert Tone</b>
          </label>
          <div className="col-sm-9">
            {audio_alert && !editAudioAlert ? (
              <React.Fragment>
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      background: "#f5f6fa",
                      padding: "5px 10px",
                      borderRadius: "5px",
                    }}
                  >
                    <div
                      style={{
                        marginBottom: "0px",
                        marginRight: "20px",
                        //marginTop: "5px",
                        padding: "5px",
                        display: "flex",
                      }}
                    >
                      {/* <ion-icon
                        name="open-outline"
                        style={{ marginRight: "5px", fontSize: "22px" }}
                      ></ion-icon> */}

                      <div
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {upload_progress.type === "audio_alert" &&
                        upload_progress.progress > 0 ? (
                          <React.Fragment>
                            <b>
                              Uploading: {upload_progress.progress.toFixed(1)}%{" "}
                            </b>
                            <div
                              className="progress"
                              style={{
                                marginTop: "10px",
                                width: "100%",
                                height: "5px",
                              }}
                            >
                              <div
                                className="progress-bar"
                                style={{
                                  width: upload_progress.progress + "%",
                                }}
                                role="progressbar"
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <a href={audio_alert.url} target="_blank">
                                <b>{audio_alert.name}</b>
                                <i
                                  style={{ marginLeft: "5px" }}
                                  className="fa-regular fa-up-right-from-square"
                                ></i>
                              </a>
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    </div>

                    <button
                      // disabled={isSubscribed(account) ? false : true}
                      className="btn btn-icon btn-outline-danger"
                      type="button"
                      style={{ marginLeft: "auto", padding: "0px 10px" }}
                      // onClick={() => deleteAsset("audio_alert", setAudioAlert)}
                      // onClick={() =>
                      //   deleteFile("audio_alert", "audio_alert", setAudioAlert)
                      // }
                      onClick={() => deleteAlertTone()}
                    >
                      <i
                        className="icon ion-ios-trash tx-18"
                        style={{ padding: "0px 0px" }}
                      ></i>
                    </button>
                  </div>
                  <small>Max size: 1GB. File type: *.mp3, *.wav</small>
                </div>

                {/* 
              <div className="col-sm-9 pd-t-10" style={{display:'flex', alignItems:'center', paddingLeft:'0px'}}>
              <div className="custom-control custom-switch">
              <input 
              name="loop"
              //onChange={(e) => props.saveAssetsSettings(game_id, 'audio_success', {loop: e.target.checked} )}
              //defaultChecked={assets && assets.audio_success.loop ? assets.audio_success.loop: null}
              type="checkbox" 
              className="custom-control-input"
              id="loopVictoryMusic"
              />
              <label className="custom-control-label" htmlFor="loopVictoryMusic">
              <b>Loop</b>
              </label>
              </div>
              </div> */}
              </React.Fragment>
            ) : (
              <div className="custom-file">
                {audio_alert && audio_alert.error ? (
                  <p className="tx-danger mg-b-0">{audio_alert.error}</p>
                ) : null}
                <small>soundtrack.mp3</small>
                <input
                  // disabled={isSubscribed(account) ? false : true}
                  // onChange={(e) =>
                  //   props.handleAlertFile(e.target.files[0])
                  // }
                  onChange={(e) => uploadAudioAlert(e.target.files[0])}
                  accept=".mp3,.wav"
                  name="audio_alert"
                  type="file"
                  className="custom-file-input"
                  id="audio_alert"
                />
                <label className="custom-file-label" htmlFor="customFile">
                  Upload file
                </label>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    settings: state.settings,
  };
};

export default connect(mapStateToProps, null)(SoundtrackAlert);
