import React, { useState, useEffect } from "react";
import uuid from "react-uuid";
import moment from 'moment'

type Props = {
  type: string;
  messageObj: {
    game_timestamp: {
        m: string;
        s: string;
    },
    timestamp: any;
    type: string; // "timer-start"
  };
};

export const Message_StartStop = (props: Props) => {

    console.log('msg obj', props.messageObj)
  if (props.type === "start") {
    return (
      <div key={uuid()} className="chat-group-divider text-gray-400 before:bg-gray-600/50 after:bg-gray-600/50">
         <div className="border border-gray-600 rounded-full px-3 py-1 flex items-center space-x-3 bg-gray-600">
            <p className="text-gray-100 font-medium">Game started</p>
            <p className="text-gray-100">{moment(props.messageObj.timestamp).format('LT')}</p>
         </div>
      </div>
    );
  }

  if (props.type === "stop") {
    return (
      <div key={uuid()} className="chat-group-divider text-gray-400 before:bg-gray-600/50 after:bg-gray-600/50">
         <div className="border border-red-600 rounded-full px-3 py-1 flex items-center space-x-3 bg-red-600">
            <p className="text-gray-100 font-medium">Game over</p>
            <p className="text-gray-100">{moment(props.messageObj.timestamp).format('LT')}</p>
         </div>
      </div>
    );
  }

  return <div></div>;
};
