import React, {useEffect} from 'react';
import { connect } from "react-redux";

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


function GMSnackBar(props) {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');

  useEffect(() => {
    if (props.add_new_puzzle === 'success'){
        setMessage('Puzzle Saved')
        setOpen(true);

        setTimeout(() => {
            handleClose()
        }, 3000);
    }
}, [props.add_new_puzzle])



const handleClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }

  setOpen(false);
};

const action = (
  <React.Fragment>
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  </React.Fragment>
);


  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        action={action}
      />
    </div>
  );
}



const mapStateToProps = (state, ownProps) => {
    return {
      upload_progress: state.settings.upload_audio_progress,
      lw_settings: state.firestore.ordered.livewindow_settings ? state.firestore.ordered.livewindow_settings[0] : null,
      lw_save: state.settings.livewindow_save_success
    };
  };

  export default connect(mapStateToProps)(GMSnackBar)