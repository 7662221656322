import React, { Component } from 'react';
import { connect } from 'react-redux'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
// Sentry.io
import * as Sentry from "@sentry/react";


//V4
//import Sidebar from '../layout/V4/Sidebar'
import GMControl from './GMControl'
import LiveWindow from '../../components/gamemaster/live_window/LiveWindowV2'

// Remote Live Window
import LiveWindowRemote from '../../components/gamemaster/live_window/LiveWindowRemoteV1'


class GameMaster extends Component {
  render (){

    const { auth, account } = this.props;
    if (!auth.uid) return <Redirect to="/login" />
    
    Sentry.setUser({
      email: auth.email,
      id: auth.uid
    });

    return (
        <BrowserRouter>
        {/* <aside className="aside aside-fixed">
            <Sidebar profile={profile} /> 
        </aside> */}

        <div className="content ht-100v pd-0">

            {account ? (
                <Switch>
                <Route exact path="/r/:id" component={GMControl} />
                <Route exact path="/r/:id/live" component={LiveWindow} />
                <Route exact path="/r/:id/remote" component={LiveWindowRemote} />


                </Switch>

            ) : null}
            
        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  const account_id = state.firebase.profile.account_id ? state.firebase.profile.account_id : null;

    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        account: state.firestore.ordered.user_accounts,
        account_id: account_id,
        app: state.app
    }
}

export default compose(
  connect(mapStateToProps, null),
  firestoreConnect(props => [

    {
      collection: 'user_accounts', 
      doc: props.account_id
    }

  ])
)(GameMaster)

