import React from "react";
import FadeIn from "react-fade-in";

export default function Upgrade(props) {
  const { rooms, subscription_status, subscription_active } = props;

  console.log('ROOMS', rooms)


  const FreeTrial = () => {
    return (
        <FadeIn>
        <div
        className="alert alert-outline alert-dark d-flex align-items-center mb-6"
        role="alert"
      >
        <i
          className="fa-solid fa-sparkles"
          style={{ marginRight: "10px", color: "#0168fa" }}
        ></i>
        Need more rooms? Try 14 days of unlimited free.
        <a style={{ marginLeft: "5px" }} href="#subsModal" data-toggle="modal">
          {" "}
          Start free trial
          <i className="fa-solid fa-arrow-right" style={{ marginLeft: "5px" }}></i>
        </a>
      </div>
      </FadeIn>
    )
  }

  const Upgrade = () => {
    return (
        <FadeIn>
        <div
        className="bg-gray-100 py-6 px-4 border-l-2 border-gray-400 mb-6"
        role="alert"
      >
        <i
          className="fa-solid fa-sparkles"
          style={{ marginRight: "10px", color: "#0168fa" }}
        ></i>
        
        <a href="#subsModal" data-toggle="modal" className="text-black">
          {" "}
          Upgrade to Unlimited
          <i className="fa-solid fa-arrow-right" style={{ marginLeft: "5px" }}></i>
        </a>
      </div>
      </FadeIn>
    )
  }
  if (subscription_active) return <div></div>;
  if (subscription_status === "canceled" || subscription_status === "cancelled") return Upgrade();
  if (!subscription_status && rooms && rooms.length > 0) return FreeTrial();
  return (
    <div></div>
  );
}
