import React from 'react'

export const Style = (hover, clueOnScreen, messageObj, type) => {
    const message = messageObj ? messageObj.message : "0";

    var style = {
        padding: "5px",
        margin: "0px 0px",
        alignItems: "center"
    };

    if (type === 'bot') style = { ...style}
    // if (messageObj && messageObj.message === clueOnScreen) style = { 
    //     ...style, 
    //     background: "#2c2c35",
    //     padding: '20px 20px',
    //     margin: '0px 20px',
    //     borderRadius: '5px',
    //     border: '1px solid rgb(70, 72, 75)'
    // }
    
    return style
  };



export const ProfilePic = (props) => {
    const { icon, type } = props;
    const bot_types = ['timer-start', 'alert-button', 'bot', 'game-ended']
    return (
        <div>
        <div
            style={{
            width: "40px",
            height: "40px",
            borderRadius: "5px",
            background: "rgb(59 63 68)",
            marginRight: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundRepeat:'no-repeat',
            backgroundSize:'cover'
            }}
        >
            {type === 'bot' || type ==="intro" ? (
                <i class="fa-solid fa-bolt" style={{ fontSize: "22px", color: "#c9cece" }}></i>
            ) : (
                <i class="fa-solid fa-paper-plane-top" style={{ fontSize: "22px", color: "#c9cece" }}></i>
            )}
        </div>
        </div>
    )
}
