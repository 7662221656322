const initState = {
    time: {
        m: "00",
        s: "00"
    }, 
    seconds: 0,
    startingSeconds: 0,
    timerRunning: false,
    gameOver: false,
    timerSpeed: 1000,
    gameComplete: false,
    didWinGame: false,
    videoIsPlaying: false,
    isVideoBrief: false,
    estimatedFinshTime: '',
    clueOnScreenCount: {0: true, 1: true, 2: true},
    isInfiniteClues: false,
    isPlayerWindowOpen: false,
    videoCluePlaying: false,
    clueOnScreen_Video: ""
}

const timerReducer = (state = initState, action) => {
    switch (action.type) {

        case 'INITIALIZE_STATE':
            //console.log("Initialize!")
            return {
                ...initState,
            }
        
        case 'UPDATE_TIMER':
            //console.log("Updated timer!", action.timer)
            return {
                ...initState,
                ...action.timer  
            }

        case 'COMPLETE_GAME':
            //console.log("Game Complete", action.state)
            return {
                ...state,
                ...action.state
                
            }

        case 'UPDATE_CLUE_ON_SCREEN':
            //console.log("Updated Clue on Screen!", state)
                return {
                    ...state,
                    clueOnScreen: action.clue
                }

                case 'UPDATE_VIDEO_CLUE_ON_SCREEN':
                    //console.log("Updated Clue on Screen!", state)
                        return {
                            ...state,
                            clueOnScreen_Video: action.clue
                        }
            
    
        case 'UPDATE_CONTROL_AFTER_VIDEO':
            console.log("Updated Control After video!", action.data)
            return {
                ...state,
                ...action.data
            }

            case 'UPDATE_CONTROL_AFTER_VIDEO_CLUE':
                console.log("Updated Control After video clue!", action.isPlaying)
                return {
                    ...state,
                    videoCluePlaying: action.isPlaying
                }

        case 'UPDATE_CLUE_ON_SCREEN_COUNT':
                console.log("Updated Clue Count", action.clueCountArray)
                return {
                    ...state,
                    clueOnScreenCount: action.clueCountArray
                }


        case 'UPDATE_INFINITE_CLUES':
                console.log("Updated Clue Count", action.bool)
                return {
                    ...state,
                    isInfiniteClues: action.bool
                }

        default:
            return state;
    }
}

export default timerReducer