import React from "react";
import uuid from 'react-uuid'
import moment from 'moment'

type Props = {
  type: string;
  messageObj: {
    game_timestamp: {
        m: string;
        s: string;
    },
    timestamp: any;
    type: string; // "timer-start"
  };
};

export const Message_ClearScreen = (props: Props) => {
  return (
    <div key={uuid()} className="chat-group-divider text-gray-400 before:bg-gray-600/50 after:bg-gray-600/50">
         <div className="border border-gray-600 rounded-full px-3 py-1 flex items-center space-x-3 text-xs">
            <p className="text-gray-400">Screen cleared</p>
            <p className="text-gray-400">{moment(props.messageObj.timestamp).format('LT')}</p>
         </div>
      </div>
  );
};
