export const remoteEditVideoIsPlaying = (bool, doc) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const db = getFirestore();        

        db.collection('gamemaster_live').doc(doc).update({
            videoIsPlaying: bool
        })
        .then(() => {
            dispatch({type: "REMOTE_VIDEOPLAYING", bool})
        }).catch((error) =>{
            console.log(error)
            dispatch({type: "REMOTE_VIDEOPLAYING_ERROR", error})
        })
        
    }
}


export const remoteEditTimerIsRunning = (bool, doc) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const db = getFirestore();

        dispatch({type: "VIDEOBUTTON_LOADING", state: true})

        db.collection('gamemaster_live').doc(doc).update({
            timerRunning: bool
        })
        .then(() => {
            dispatch({type: "REMOTE_TIMERRUNNING", state: bool})
        }).catch((error) =>{
            console.log(error)
            dispatch({type: "REMOTE_TIMERRUNNING_ERROR", error})
        })
        
    }
}


export const remoteSendClue = (clue, doc) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const db = getFirestore();
        db.collection('gamemaster_live').doc(doc).update({
            clueOnScreen: clue
        })
        .then(() => {
            dispatch({type: "REMOTE_SENDCLUE", state: clue})
        }).catch((error) =>{
            console.log(error)
            dispatch({type: "REMOTE_SENDCLUE_ERROR", error})
        })
        
    }
}

export const remoteUpdateClueCount = (array, doc) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const db = getFirestore();

        db.collection('gamemaster_live').doc(doc).update({
            clueOnScreenCount: array
        })
        .then(() => {
            dispatch({type: "REMOTE_UPDATE_CLUE_ON_SCREEN_COUNT"})
        }).catch((error) =>{
            console.log(error)
            dispatch({type: "REMOTE_UPDATE_CLUE_ON_SCREEN_COUNT_ERROR", error})
        })
        
    }
}


export const remoteSetInfiniteClues = (bool, doc) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const db = getFirestore();

        db.collection('gamemaster_live').doc(doc).update({
            isInfiniteClues: bool
        })
        .then(() => {
            dispatch({type: "REMOTE_IS_INFINITE_CLUES"})
        }).catch((error) =>{
            console.log(error)
            dispatch({type: "REMOTE_IS_INFINITE_CLUES_ERROR", error})
        })
        
    }
}

export const remoteAdjustTimer = (string, doc) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const db = getFirestore();
        const firebase = getFirebase();

        dispatch({type: "ADJUST_TIMER_LOADING", state: true})

        const obj = {
            id: new Date(),
            update: string
        };

        const data = {};
        data["timer_adjustments"] = firebase.firestore.FieldValue.arrayUnion(obj);

        db.collection('gamemaster_live').doc(doc).update(data)
        .then(() => {
            dispatch({type: "REMOTE_ADJUST_TIMER"})
        }).catch((error) =>{
            console.log(error)
            dispatch({type: "REMOTE_ADJUST_TIMER_ERROR", error})
        })
        
    }
}


export const resetTimerAdjustment = (doc) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const db = getFirestore();

        db.collection('gamemaster_live').doc(doc).update({
            timer_adjustments: ''
        })
        .then(() => {
            dispatch({type: "RESET_TIMER_ADJUST"})
        }).catch((error) =>{
            console.log(error)
            dispatch({type: "RESET_TIMER_ADJUST_ERROR", error})
        })
        
    }
}