const steps = [
    { index: 1, href: '#', status: 'complete' },
    { index: 2, href: '#', status: 'current' },
    { index: 3, href: '#', status: 'upcoming' },
  ]
  
  export default function Steps(props) {

    const { currentStep } = props;

    return (
      <nav className="flex items-center justify-center" aria-label="Progress">
        <p className="text-sm font-medium">
          Step {currentStep} of {steps.length}
        </p>
        <ol role="list" className="ml-8 flex items-center space-x-5">
          {steps.map((step) => (
            <li key={step.index}>
              {step.index < currentStep ? (
                <a href={step.href} className="block h-2.5 w-2.5 rounded-full bg-indigo-600 hover:bg-indigo-900">

                </a>
              ) : step.index === currentStep ? (
                <a href={step.href} className="relative flex items-center justify-center" aria-current="step">
                  <span className="absolute flex h-5 w-5 p-px" aria-hidden="true">
                    <span className="h-full w-full rounded-full bg-indigo-200" />
                  </span>
                  <span className="relative block h-2.5 w-2.5 rounded-full bg-indigo-600" aria-hidden="true" />

                </a>
              ) : (
                <a href={step.href} className="block h-2.5 w-2.5 rounded-full bg-gray-200 hover:bg-gray-400">

                </a>
              )}
            </li>
          ))}
        </ol>
      </nav>
    )
  }