import React  from 'react'

const WhyChromeModal = (props) => {

    return (
        <div 
        className="modal effect-scale" 
        id="whyChrome" 
        tabIndex="-1" 
        role="dialog" 
        aria-hidden="true"
        >

      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
            <a href="/" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </a>
            <div className="media">
              <span className="d-none d-sm-block" style={{color:'#0168fa'}}>
              <i class="fa-brands fa-chrome" style={{fontSize:'50px'}}></i></span>
              <div className="media-body mg-sm-l-20">
                <h4 className="tx-18 tx-sm-20 mg-b-20">Why Google Chrome?</h4>
                <p className="mg-b-8">Google Chrome allows us to store game assets locally in the browser instead of fetching them from the cloud everytime you load a room.</p>
                <p className="mg-b-20">This is helpful because it allows instant and reliable loading of game assets, even if you lose internet connection.</p>

                <a href="https://www.google.com/chrome/downloads/" target="_blank" className="btn btn-primary">Download Chrome</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}


export default WhyChromeModal
