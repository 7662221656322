import React from 'react'
import { connect } from "react-redux";


const StopButtonNew = (props) => {

    const {timerRunning, videoIsPlaying, startTimer, pauseTimer, videoButtonLoading, gameComplete, gameIsReady } = props;

        const buttonText = () => {
            return (
                <span style={{fontSize:'14px', fontWeight:'600'}}>
                    <i className="fa-solid fa-stop"
                    style={{marginRight:"0px", color:'white'}}></i>
                </span>
            )
        }

        const dataUpload = () => {
            if (props.gameMaster.endgame_data_upload === 'loading') {
                return false
            } else {
                return true
            }    
        }

        if (gameComplete){
            return (
    <button disabled type="button" className="btn btn-danger w-100" style={{height:'38px'}}>
            {buttonText()}
            </button>
            )
        } else if (timerRunning && !videoIsPlaying){
            return (
                <button onClick={pauseTimer} type="button" className="btn btn-danger w-100" style={{height:'38px'}}>
                    {buttonText()}
                </button>
            )
        } else if (!timerRunning && videoIsPlaying){
            return (
    <button disabled type="button" className="btn btn-danger w-100" style={{height:'38px'}}>
            {buttonText()}
            </button>
            )
        } else if (timerRunning && videoIsPlaying){
            return (
        <button onClick={pauseTimer} type="button" className="btn btn-danger w-100" style={{height:'38px'}}>
                    {buttonText()}
                </button>
            )
        } else if (!timerRunning && !videoIsPlaying) {
            return (
    <button disabled id="startButton" type="button" className="btn btn-danger w-100" style={{height:'38px'}}>
                    {buttonText()}
                </button>
            )
        }
}

const mapStateToProps = (state) => {
  
    return {
      gameMaster: state.gameMaster,
    };
  };

export default connect(mapStateToProps, null)(StopButtonNew)
