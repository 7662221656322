import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import uuid from "react-uuid";
import { makeStyles } from "@mui/styles";
import Slider from "@mui/material/Slider";
import { SketchPicker } from "react-color";

import { get, createStore } from "idb-keyval";
import {
  gameId,
  addToDB,
  deleteAsset,
  readBlob,
} from "../Functions/SettingsFunctions";

import VictoryPreview from "./LiveScreens/VictoryPreview";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});

function valuetext(value) {
  return `${value}°C`;
}

const marks = [
  {
    value: 10,
    label: "S",
  },
  {
    value: 16,
    label: "M",
  },
  {
    value: 22,
    label: "L",
  },
  {
    value: 32,
    label: "XL",
  },
];

const VictorySettings = (props) => {
  const classes = useStyles();
  const {
    game_data,
    game_id,
    assets,
    editAudioSuccess,
    handleSuccessFile,
    handleDeleteFile,
    upload_progress,
  } = props;

  // Init game_id with IndexedDB functions
  gameId(game_id);

  const [settings, setSettings] = useState({});
  useEffect(() => {
    setSettings(game_data);
    setTextColor(
      game_data && game_data.victory ? game_data.victory.color : "#fff"
    );
    setText(
      game_data && game_data.victory ? game_data.victory.text : "Success!"
    );
    setBackgroundColor(
      game_data && game_data.victory ? game_data.victory.bg_color : "#fff"
    );
  }, [game_data]);

  useEffect(() => {
    if (assets && assets.audio_success) setVictoryMusic(assets.audio_success);
    if (assets && assets.background_image_victory)
      setBackgroundImage(assets.background_image_victory);
  }, [assets]);

  const [text, setText] = useState();

  const changeSettings = (e, type) => {
    if (type === "success_text") {
      setSettings({ ...settings, victory: { ...settings.victory, text: e } });
      const newData = {
        ...settings,
        victory: {
          ...settings.victory,
          last_updated: new Date(),
          text: e,
        },
      };

      props.saveLiveWindowSettings(game_id, newData);
    } else if (e.target.name === "show_victory_text") {
      setSettings({
        ...settings,
        victory: { ...settings.victory, show: e.target.checked },
      });
      const newData = {
        ...settings,
        victory: {
          ...settings.victory,
          last_updated: new Date(),
          show: e.target.checked,
        },
      };

      props.saveLiveWindowSettings(game_id, newData);
    } else if (e.target.name === "show_timeRemaining") {
      setSettings({
        ...settings,
        victory: { ...settings.victory, show_timeRemaining: e.target.checked },
      });
      const newData = {
        ...settings,
        victory: {
          ...settings.victory,
          last_updated: new Date(),
          show_timeRemaining: e.target.checked,
        },
      };

      props.saveLiveWindowSettings(game_id, newData);
    } else {
      setSettings({
        ...settings,
        victory: { ...settings.victory, [e.target.name]: e.target.value },
      });
      const newData = {
        ...settings,
        victory: {
          ...settings.victory,
          last_updated: new Date(),
          [e.target.name]: e.target.value,
        },
      };

      props.saveLiveWindowSettings(game_id, newData);
    }
  };
  var typingTimer;
  const [sizeValue, setSizeValue] = useState();
  const [timeRemainingSize, setTimeRemainingSize] = useState();
  const handleChange = (e, newValue) => {
    setSizeValue(newValue);

    if (sizeValue !== newValue) {
      setTimeout(() => {
        setSettings({
          ...settings,
          victory: { ...settings.victory, size: newValue },
        });
        const newData = {
          ...settings,
          victory: {
            ...settings.victory,
            last_updated: new Date(),
            size: newValue,
          },
        };

        props.saveLiveWindowSettings(game_id, newData);
      }, 500);
    }
  };
  const handleTimeRemainingSize = (e, newValue) => {
    setTimeRemainingSize(newValue);

    if (timeRemainingSize !== newValue) {
      setTimeout(() => {
        setSettings({
          ...settings,
          victory: { ...settings.victory, size_timeRemaining: newValue },
        });
        const newData = {
          ...settings,
          victory: {
            ...settings.victory,
            last_updated: new Date(),
            size_timeRemaining: newValue,
          },
        };

        props.saveLiveWindowSettings(game_id, newData);
      }, 500);
    }
  };

  const [editTextColor, setEditTextColor] = useState(false);
  const [textColor, setTextColor] = useState("#fff");
  const handleChangeTextColor = (color) => {
    setEditTextColor(!editTextColor);

    if (textColor !== settings.victory.color) {
      setSettings({
        ...settings,
        victory: { ...settings.victory, color: textColor },
      });
      const newData = {
        ...settings,
        victory: {
          ...settings.victory,
          last_updated: new Date(),
          color: textColor,
        },
      };

      props.saveLiveWindowSettings(game_id, newData);
    }
  };

  // Edit Background Color
  const [editBackgroundColor, setEditBackgroundColor] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("#fff");
  const handleChangeBackgroundColor = (color) => {
    setEditBackgroundColor(!editBackgroundColor);

    if (backgroundColor !== settings.victory.bg_color) {
      setSettings({
        ...settings,
        victory: { ...settings.victory, bg_color: backgroundColor },
      });
      const newData = {
        ...settings,
        victory: {
          ...settings.victory,
          last_updated: new Date(),
          bg_color: backgroundColor,
        },
      };

      props.saveLiveWindowSettings(game_id, newData);
    }
  };

  // Edit Background Image
  const [editBackgroundImage, setEditBackgroundImage] = useState(false);

  // IndexDB Asset Items
  const [backgroundImage, setBackgroundImage] = useState("");
  const [victoryMusic, setVictoryMusic] = useState("");
  //    useEffect(() => {
  //     const assetsStore = createStore(game_id, 'assets');

  //     // Get Background Image
  //     get('background_image_victory', assetsStore).then((val) => {
  //       if (val){
  //         const blob = readBlob(val)
  //         var url = URL.createObjectURL(blob)
  //         setBackgroundImage({ ...val, url })
  //       }

  //     });

  //     // Get Victory Music
  //     get('victory_music', assetsStore).then((val) => {
  //       if (val){
  //         const blob = readBlob(val)
  //         var url = URL.createObjectURL(blob)
  //         setVictoryMusic({ ...val, url })
  //       }

  //     });

  // }, [])

  const uploadBackground = (file) => {
    const id = uuid();

    if (file.size < 1073741824) {
      // Create Blob
      addToDB(
        file,
        "background_image_victory",
        setBackgroundImage,
        backgroundImage,
        id
      );

      // Upload to Database
      props.handleUploadFile(file, "background_image_victory", id);
    } else {
      setBackgroundImage({ error: "File too large." });
    }
  };

  const deleteBackground = () => {
    // Delete blob
    deleteAsset(
      "background_image_victory",
      backgroundImage,
      setBackgroundImage
    );

    // Delete from database
    props.handleDeleteFile("background_image_victory", backgroundImage);
  };

  const uploadVictoryMusic = (file) => {
    const id = uuid();

    if (file.size < 1073741824) {
      // Create Blob
      addToDB(file, "audio_success", setVictoryMusic, victoryMusic, id);

      // Upload to Database
      props.handleUploadFile(file, "audio_success", id);
    } else {
      setVictoryMusic({ error: "File too large." });
    }
  };

  const deleteVictoryMusic = () => {
    // Delete blob
    deleteAsset("audio_success", victoryMusic, setVictoryMusic);

    // Delete from database
    props.handleDeleteFile("audio_success", victoryMusic);
  };

  return (
    <div style={{ marginTop: "20px", marginBottom: "50px" }}>
      <form style={{ width: "100%" }}>
        <div className="form-group row">
          <div className="col-sm-3">
            {/* <label htmlFor="inputEmail3" className="col-form-label tx-18 mg-b-20">
            <b>Victory Screen</b>
          </label> */}

            <div className="pd-b-10 bd-b">
              <div className="custom-control custom-switch pd-t-10">
                <input
                  name="show_victory_text"
                  onInput={(e) => changeSettings(e)}
                  defaultChecked={
                    settings && settings.victory ? settings.victory.show : null
                  }
                  type="checkbox"
                  className="custom-control-input"
                  id="showVictorySwitch"
                />
                <label
                  className="custom-control-label"
                  htmlFor="showVictorySwitch"
                >
                  <b>Show Victory Text</b>
                </label>
              </div>

              {settings && settings.victory && settings.victory.show && (
                <React.Fragment>
                  <input
                    name="success_text"
                    //onChange={(e) => changeSettings(e)}
                    onKeyUp={(e) => {
                      clearTimeout(typingTimer);
                      typingTimer = setTimeout(
                        (param) => {
                          changeSettings(param.value, "success_text");
                        },
                        1000,
                        e.target
                      );
                    }}
                    onKeyDown={() => clearTimeout(typingTimer)}
                    type="text"
                    className="form-control mg-t-10"
                    placeholder="Victory Text"
                    defaultValue={text}
                  />

                  <div className="pd-t-10">
                    <Slider
                      defaultValue={
                        settings && settings.victory
                          ? settings.victory.size
                          : 22
                      }
                      getAriaValueText={valuetext}
                      aria-labelledby="discrete-slider"
                      style={{ color: "#0168fa" }}
                      //valueLabelDisplay="auto"
                      step={null}
                      marks={marks}
                      min={8}
                      max={32}
                      onChange={handleChange}
                    />
                  </div>
                </React.Fragment>
              )}
            </div>

            <div className="pd-b-10 bd-b">
              <div className="custom-control custom-switch pd-t-10">
                <input
                  name="show_timeRemaining"
                  onInput={(e) => changeSettings(e)}
                  defaultChecked={
                    settings && settings.victory
                      ? settings.victory.show_timeRemaining
                      : null
                  }
                  type="checkbox"
                  className="custom-control-input"
                  id="showTimeRemainingSwitch"
                />
                <label
                  className="custom-control-label"
                  htmlFor="showTimeRemainingSwitch"
                >
                  <b>Show Time Remaining</b>
                </label>
              </div>

              {settings &&
                settings.victory &&
                settings.victory.show_timeRemaining && (
                  <React.Fragment>
                    <div className="pd-t-10">
                      <Slider
                        defaultValue={
                          settings && settings.victory
                            ? settings.victory.size_timeRemaining
                            : 22
                        }
                        getAriaValueText={valuetext}
                        aria-labelledby="discrete-slider"
                        style={{ color: "#0168fa" }}
                        //valueLabelDisplay="auto"
                        step={null}
                        marks={marks}
                        min={8}
                        max={32}
                        onChange={handleTimeRemainingSize}
                      />
                    </div>
                  </React.Fragment>
                )}
            </div>

            <div className="pd-b-20 bd-b">
              <label htmlFor="inputEmail3" className="col-form-label">
                <b>Victory Font</b>
              </label>

              <select
                name="font"
                onChange={(e) => changeSettings(e)}
                className="form-control"
                value={
                  settings && settings.victory ? settings.victory.font : ""
                }
              >
                <option value="Roboto">System (Default)</option>
                <option style={{ fontFamily: "Arial" }} value="Arial">
                  Arial
                </option>
                <option
                  style={{ fontFamily: "Arial Black" }}
                  value="Arial Black"
                >
                  Arial Black
                </option>
                <option
                  style={{ fontFamily: "Comic Sans MS" }}
                  value="Comic Sans MS"
                >
                  Comic Sans
                </option>
                <option
                  style={{ fontFamily: "Courier New" }}
                  value="Courier New"
                >
                  Courier New
                </option>
                <option style={{ fontFamily: "Georgia" }} value="Georgia">
                  Georgia
                </option>
                <option style={{ fontFamily: "Impact" }} value="Impact">
                  Impact
                </option>
                <option
                  style={{ fontFamily: "Lucida Console" }}
                  value="Lucida Console"
                >
                  Lucida Console
                </option>
                <option
                  style={{ fontFamily: "Lucida Sans Unicode" }}
                  value="Lucida Sans Unicode"
                >
                  Llucida Sans
                </option>
                <option
                  style={{ fontFamily: "Palatino Linotype" }}
                  value="Palatino Linotype"
                >
                  Palatino Linotype
                </option>
                <option style={{ fontFamily: "Tahoma" }} value="Tahoma">
                  Tahoma
                </option>
                <option
                  style={{ fontFamily: "Times New Roman" }}
                  value="Times New Roman"
                >
                  Times New Roman
                </option>
                <option style={{ fontFamily: "Verdana" }} value="Verdana">
                  Verdana
                </option>
              </select>
            </div>

            <div>
              <label htmlFor="inputEmail3" className="col-form-label">
                <b>Text Color</b>
              </label>

              <div
                className="color-picker-swatch"
                onClick={() => setEditTextColor(!editTextColor)}
              >
                <div
                  className="color-picker-bubble"
                  style={{ background: textColor }}
                />
                <p className="mg-b-0">{textColor}</p>
              </div>

              {editTextColor ? (
                <div style={{ position: "absolute", zIndex: "2", top: "50px" }}>
                  <div
                    style={{
                      position: "fixed",
                      top: "50px",
                      right: "0px",
                      bottom: "0px",
                      left: "0px",
                    }}
                    onClick={() => handleChangeTextColor()}
                  />
                  <SketchPicker
                    color={textColor}
                    onChange={(e) => setTextColor(e.hex)}
                    onChangeComplete={(e) => setTextColor(e.hex)}
                    disableAlpha={false}
                    presetColors={[
                      "#D0021B",
                      "#F5A623",
                      "#F8E71C",
                      "#8B572A",
                      "#7ED321",
                      "#417505",
                      "#BD10E0",
                      "#9013FE",
                      "#4A90E2",
                      "#50E3C2",
                      "#B8E986",
                      "#000000",
                      "#4A4A4A",
                      "#9B9B9B",
                      "#FFFFFF",
                      "transparent",
                    ]}
                  />
                </div>
              ) : null}
            </div>

            <div className="pd-b-20 bd-b">
              <label htmlFor="inputEmail3" className="col-form-label">
                <b>Background Color</b>
              </label>

              <div
                className="color-picker-swatch"
                onClick={() => setEditBackgroundColor(!editBackgroundColor)}
              >
                <div
                  className="color-picker-bubble"
                  style={{ background: backgroundColor }}
                />
                <p className="mg-b-0">{backgroundColor}</p>
              </div>

              {editBackgroundColor ? (
                <div style={{ position: "absolute", zIndex: "2", top: "50px" }}>
                  <div
                    style={{
                      position: "fixed",
                      top: "50px",
                      right: "0px",
                      bottom: "0px",
                      left: "0px",
                    }}
                    onClick={() => handleChangeBackgroundColor()}
                  />
                  <SketchPicker
                    color={backgroundColor}
                    onChange={(e) => setBackgroundColor(e.hex)}
                    onChangeComplete={(e) => setBackgroundColor(e.hex)}
                    disableAlpha={false}
                    presetColors={[
                      "#D0021B",
                      "#F5A623",
                      "#F8E71C",
                      "#8B572A",
                      "#7ED321",
                      "#417505",
                      "#BD10E0",
                      "#9013FE",
                      "#4A90E2",
                      "#50E3C2",
                      "#B8E986",
                      "#000000",
                      "#4A4A4A",
                      "#9B9B9B",
                      "#FFFFFF",
                      "transparent",
                    ]}
                  />
                </div>
              ) : null}
            </div>

            <div className="pd-b-20 bd-b">
              <label htmlFor="inputEmail3" className="col-form-label">
                <b>Background Image/Video</b>
              </label>

              {backgroundImage && !editBackgroundImage ? (
                <div>
                  <div
                    style={{
                      display: "flex",
                      background: "#f5f6fa",
                      padding: "5px",
                      borderRadius: "5px",
                    }}
                  >
                    <p
                      style={{
                        marginBottom: "0px",
                        marginRight: "20px",
                        //marginTop: "5px",
                        padding: "5px",
                        display: "flex",
                      }}
                    >
                      {/* <ion-icon
                      name="open-outline"
                      style={{ marginRight: "5px", fontSize:'22px' }}
                    ></ion-icon> */}

                      <div
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "155px",
                        }}
                      >
                        {upload_progress.type === "background_image_victory" &&
                        upload_progress.progress > 0 ? (
                          <React.Fragment>
                            <b>
                              Uploading: {upload_progress.progress.toFixed(1)}%{" "}
                            </b>
                            <div
                              className="progress"
                              style={{
                                marginTop: "10px",
                                width: "100%",
                                height: "5px",
                              }}
                            >
                              <div
                                className="progress-bar"
                                style={{
                                  width: upload_progress.progress + "%",
                                }}
                                role="progressbar"
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <a href={backgroundImage.url} target="_blank">
                              <b>{backgroundImage.name}</b>
                              <i
                                style={{ marginLeft: "5px" }}
                                className="fa-regular fa-up-right-from-square"
                              ></i>
                            </a>
                          </React.Fragment>
                        )}
                      </div>
                    </p>

                    <button
                      className="btn btn-icon btn-outline-danger"
                      type="button"
                      style={{ marginLeft: "auto", padding: "0px 10px" }}
                      //onClick={() => deleteAsset("background_image_victory", setBackgroundImage)}
                      onClick={() => deleteBackground()}
                    >
                      <i
                        className="icon ion-ios-trash tx-18"
                        style={{ padding: "0px 0px" }}
                      ></i>
                    </button>
                  </div>
                </div>
              ) : (
                <React.Fragment>
                  <div className="custom-file">
                  {backgroundImage && backgroundImage.error ? (
                <p className="tx-danger mg-b-0">{backgroundImage.error}</p>
              ) : null}
                    <input
                      // onChange={(e) =>
                      //   props.handleUploadFile(
                      //     e.target.files[0],
                      //     "background_image_victory"
                      //   )
                      // }
                      onChange={(e) => uploadBackground(e.target.files[0])}
                      accept=".jpg,.jpeg,.png,.mp4"
                      name="background_image_victory"
                      type="file"
                      className="custom-file-input"
                      id="background_image_victory"
                    />

                    <label className="custom-file-label" htmlFor="customFile">
                      Upload file
                    </label>
                  </div>
                  <small>Reccomended: 1920x1080.</small>
                  <br />
                  <small>Max size: 1GB. File type: *.jpg, *.png, *.mp4</small>
                </React.Fragment>
              )}
            </div>

            <div className="pd-b-20">
              <label htmlFor="inputEmail3" className="col-form-label">
                <b>Victory Music</b>
              </label>

              {victoryMusic && !editAudioSuccess ? (
                <React.Fragment>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        background: "#f5f6fa",
                        padding: "5px",
                        borderRadius: "5px",
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "0px",
                          marginRight: "20px",
                          //marginTop: "5px",
                          padding: "5px",
                          display: "flex",
                        }}
                      >
                        {/* <ion-icon
                      name="open-outline"
                      style={{ marginRight: "5px", fontSize:'22px' }}
                    ></ion-icon> */}

                        <div
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "155px",
                          }}
                        >
                          {upload_progress.type === "audio_success" &&
                          upload_progress.progress > 0 ? (
                            <React.Fragment>
                              <b>
                                Uploading: {upload_progress.progress.toFixed(1)}
                                %{" "}
                              </b>
                              <div
                                className="progress"
                                style={{
                                  marginTop: "10px",
                                  width: "100%",
                                  height: "5px",
                                }}
                              >
                                <div
                                  className="progress-bar"
                                  style={{
                                    width: upload_progress.progress + "%",
                                  }}
                                  role="progressbar"
                                  aria-valuenow="25"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <a href={victoryMusic.url} target="_blank">
                                <b>{victoryMusic.name}</b>
                                <i
                                  style={{ marginLeft: "5px" }}
                                  className="fa-regular fa-up-right-from-square"
                                ></i>
                              </a>
                            </React.Fragment>
                          )}
                        </div>
                      </p>

                      <button
                        className="btn btn-icon btn-outline-danger"
                        type="button"
                        style={{ marginLeft: "auto", padding: "0px 10px" }}
                        // onClick={() => deleteAsset("victory_music", setVictoryMusic)}
                        onClick={() => deleteVictoryMusic()}
                      >
                        <i
                          className="icon ion-ios-trash tx-18"
                          style={{ padding: "0px 0px" }}
                        ></i>
                      </button>
                    </div>
                    <small>Max size: 1GB. File type: *.mp3, *.wav</small>
                  </div>

                  {/* 
<div className="col-sm-9 pd-t-10" style={{display:'flex', alignItems:'center', paddingLeft:'0px'}}>
<div className="custom-control custom-switch">
  <input 
  name="loop"
  //onChange={(e) => props.saveAssetsSettings(game_id, 'audio_success', {loop: e.target.checked} )}
  //defaultChecked={assets && assets.audio_success.loop ? assets.audio_success.loop: null}
  type="checkbox" 
  className="custom-control-input"
  id="loopVictoryMusic"
    />
  <label className="custom-control-label" htmlFor="loopVictoryMusic">
    <b>Loop</b>
  </label>
</div>
</div> */}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="custom-file">
                    <small>soundtrack.mp3</small>
                    <input
                      onChange={(e) => uploadVictoryMusic(e.target.files[0])}
                      // onChange={(e)=> addToDB(e.target.files[0], 'victory_music', setVictoryMusic, victoryMusic)}
                      accept=".mp3,.wav"
                      name="audio_success"
                      type="file"
                      className="custom-file-input"
                      id="audio_success"
                    />
                    <label
                      className="custom-file-label mg-b-0"
                      htmlFor="customFile"
                    >
                      Upload file
                    </label>
                  </div>
                  <small>Max size: 1GB. File type: *.mp3, *.wav</small>
                </React.Fragment>
              )}
            </div>
          </div>

          <div
            className="col-sm-9"
            style={{ display: "flex", alignItems: "start" }}
          >
            <VictoryPreview
              backgroundColor={backgroundColor}
              backgroundImage={backgroundImage}
              textColor={textColor}
              settings={settings}
              game_data={game_data}
            />
          </div>
          <small className="tx-gray-500" style={{ marginLeft: "auto" }}>
            *This preview is a close approximation of the real live window.
          </small>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    settings: state.settings,
    upload_progress: state.settings.upload_audio_progress,
  };
};

export default connect(mapStateToProps, null)(VictorySettings);
