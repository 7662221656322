import React from 'react'

const DeletePuzzleModal = (props) => {

   const { delete_puzzle, id, selectedPuzzle } = props;

   const name = selectedPuzzle ? selectedPuzzle.name : ''


    return (
<div className="modal effect-scale" id="deletePuzzleModal" tabIndex="-1" role="dialog" aria-hidden="true">

      <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
        <div className="modal-content">

            
          <div className="modal-header">
            <a href="/" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </a>
            <div className="media">
              <span className="tx-color-03 d-none d-sm-block">
                <i className="icon ion-ios-trash" style={{fontSize:"30px"}}></i></span>
              <div className="media-body mg-sm-l-20">
                <h4 className="text-lg mb-0 font-medium">Delete objective: {name}?</h4>
                <p className='mg-b-0'>This will delete the puzzle and it's clues forever.</p>
              </div>
            </div>
          </div>

          {/* <ul className="nav" id="myTab" role="tablist">
        <li className="nav-item">
            <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">
                <i className="icon ion-ios-send" style={{marginRight:"5px"}}></i>
                Send</a>
        </li>
        <li className="nav-item">
            <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">
            <i className="icon ion-md-options" style={{marginRight:"5px"}}></i>
                Audio</a>
        </li>
        <li className="nav-item">
            <a className="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">
            <i className="icon ion-ios-videocam" style={{marginRight:"5px"}}></i>
            Video</a>
        </li>
        </ul> */}


         
          <div className="modal-footer-dark">
          <button 
            onClick={() => delete_puzzle(id, selectedPuzzle.id)}
            data-dismiss="modal"
            className="button-gray-dark mr-2">Delete objective</button>
            <button type="button" className="button-outline-dark" data-dismiss="modal">Cancel</button>
            
          </div>
        </div>
      </div>
    </div>
    )
}


// const mapDispatchToProps = (dispatch) => {
//     return {
//         addNewClue: (data, id) => dispatch(addNewClue(data, id))
//     }
// }

// export default connect(null, mapDispatchToProps)(AddClueModal)
export default DeletePuzzleModal