import React, { useState } from "react";
import { connect } from "react-redux";

import {inviteNewUser} from '../../../redux/actions/settingsActions'

const NewUser = (props) => {
    
    const [data, setData] = useState({
        email: '',
        isAdmin: true,
        role: 'Admin',
        canEditHints: true,
        canEditPuzzles: true,
        canEditLiveWindow: true
    })

    const setAdmin = (e) => {
        if (e === 'admin') {
            setData({
                ...data,
                isAdmin: true,
                role: 'Admin',
                canEditHints: true,
                canEditPuzzles: true,
                canEditLiveWindow: true
            })
        } else if (e === 'staff') {
            setData({
                ...data,
                isAdmin: false,
                role: 'Staff'
            })
        }
    }

    const setPuzzles = (e) => {
        if (e === 'true') {
            setData({
                ...data,
                canEditPuzzles: true
            })
        } else if (e === 'false') {
            setData({
                ...data,
                canEditPuzzles: false
            })
        }
    }

    const setHints = (e) => {
        if (e === 'true') {
            setData({
                ...data,
                canEditHints: true
            })
        } else if (e === 'false') {
            setData({
                ...data,
                canEditHints: false
            })
        }
    }

    const setLiveWindow = (e) => {
        if (e === 'true') {
            setData({
                ...data,
                canEditLiveWindow: true
            })
        } else if (e === 'false') {
            setData({
                ...data,
                canEditLiveWindow: false
            })
        }
    }



    console.log('data', data)
    console.log('account', props.account)
  return (
    <div
      className="modal effect-scale"
      id="NewUserModal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{width:'400px'}}
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body pd-sm-t-30 pd-sm-b-20 pd-sm-x-30">
            <h4>New User</h4>
            <div className="form-group">
              <label className="mg-b-5 mg-t-10">
                Email Address
              </label>
              <input
                onChange={(e) => setData({...data, email : e.target.value})}
                type="text"
                className="form-control"
                placeholder="someone@yourcompany.com"
              ></input>

            </div>

            <div className="form-group">

            <div className="row">
                <div className="col-6">
                <label className="mg-b-5 mg-t-10">
                    Role
                </label>
                </div>

                <div className="col-6">
                <select
                    onChange={(e) => setAdmin(e.target.value)}
                    class="custom-select">
                        <option value="admin">Admin</option>  
                        <option value="staff">Staff</option>
                        
                </select>
                    </div>
            </div>

        </div>

        {!data.isAdmin && (
            <div className="form-group">
            <div className="row">
                <div className="col-6">
                    <label className="mg-b-5 mg-t-10">
                        Puzzles
                    </label>
                </div>

                <div className="col-6">
                    <select
                        onChange={(e) => setPuzzles(e.target.value)}
                        class="custom-select">
                        <option value={true}>Read / Write</option>  
                        <option value={false}>Read Only</option> 
                    </select>
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <label className="mg-b-5 mg-t-10">
                        Hints
                    </label>
                </div>

                <div className="col-6">
                    <select
                        onChange={(e) => setHints(e.target.value)}
                        class="custom-select">
                        <option value={true}>Read / Write</option>  
                        <option value={false}>Read Only</option> 
                    </select>
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <label className="mg-b-5 mg-t-10">
                        Live Window Settings
                    </label>
                </div>

                <div className="col-6">
                    <select
                        onChange={(e) => setLiveWindow(e.target.value)}
                        class="custom-select">
                        <option value={true}>Read / Write</option>  
                        <option value={false}>Read Only</option> 
                    </select>
                </div>
            </div>
            </div>
        )}




          </div>

          <div className="modal-footer pd-x-20 pd-y-15">
            <button
              type="button"
              className="btn btn-white"
              data-dismiss="modal"
            >
              <b>Cancel</b>
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-dismiss="modal"
              onClick={()=> props.inviteNewUser(data, data.email, props.account)}
            >
              <b>Invite User</b>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    inviteNewUser: (permissions, email, account_id) => dispatch(inviteNewUser(permissions, email, account_id)),
  };
};

export default connect(null, mapDispatchToProps)(NewUser);
