import React from 'react'
import { connect } from "react-redux";


const StartButton = (props) => {

    const {timerRunning, videoIsPlaying, startTimer, pauseTimer, videoButtonLoading, gameComplete, gameIsReady } = props;

        const buttonText = () => {
            if (videoButtonLoading){
                return <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            } else if (timerRunning){
                return (<span>
                    <i className="icon ion-md-pause" 
                    style={{marginRight:"5px", color:'white'}}></i> Pause game
                        </span>)
            } else if(!timerRunning) {
                return (
                    <span>
                        <i className="icon ion-ios-play" 
                        style={{marginRight:"8px", color:'white'}}></i>Start game
                    </span>)
            }
        }

        const dataUpload = () => {
            if (props.gameMaster.endgame_data_upload === 'loading') {
                return false
            } else {
                return true
            }    
        }

        if (gameComplete){
            return (
    <button disabled type="button" className="button-blue-dark w-full">
            {buttonText()}
            </button>
            )
        } else if (!dataUpload()){
            return (
    <button disabled type="button" className="button-blue-dark w-full">
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </button>
            )
        
        } else if (timerRunning && !videoIsPlaying){
            return (
                <button onClick={pauseTimer} type="button" className="button-gray-dark w-full">
                    {buttonText()}
                </button>
            )
        } else if (!timerRunning && videoIsPlaying){
            return (
    <button disabled type="button" className="button-blue-dark w-full">
            {buttonText()}
            </button>
            )
        } else if (timerRunning && videoIsPlaying){
            return (
        <button onClick={pauseTimer} type="button" className="button-gray-dark w-full">
                    {buttonText()}
                </button>
            )
        } else if (!timerRunning && !videoIsPlaying) {
            return (
    <button id="startButton" onClick={startTimer} type="button" className="button-blue-dark w-full">
                    {buttonText()}
                </button>
            )
        }
}

const mapStateToProps = (state) => {
    console.log(state.gameMaster)
  
    return {
      gameMaster: state.gameMaster,
    };
  };

export default connect(mapStateToProps, null)(StartButton)
