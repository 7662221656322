import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";

// Modals
import AddClueModal from "../modals/AddClueModal";
import EditPuzzleModal from "../modals/EditPuzzleModal";
import EditDeleteClueModal from "../modals/EditDeleteClueModal";
import DeletePuzzleModal from "../modals/DeletePuzzleModal";
import AddImageClueModal from "../modals/AddImageClueModal";
import AddVideoClueModal from "../modals/AddVideoClueModal";
import EditDeleteImageClueModal from "../modals/EditDeleteImageClueModal";
import EditDeleteVideoClueModal from "../modals/EditDeleteVideoClueModal";
import ProFeatureOnly from "../modals/ProFeatureOnly";

import {
  addNewClue,
  updatePuzzle,
  reorderClues,
  deletePuzzle,
} from "../../../redux/actions/gmActions";
import { ClueButton } from "../buttons/ClueButton";
import { ImageClueButton } from "../buttons/ImageClueButton";
import { AudioClueButton } from "../buttons/AudioClueButton";
import VideoClueButton from "../buttons/VideoClueButton";

// React Sortable
import { ReactSortable } from "react-sortablejs";

const LeftSideBar = (props) => {
  const {
    handleLoadClueClick,
    allClues,
    clues,
    id,
    puzzles,
    selectedPuzzleName,
    selectedPuzzleObj,
    handleClearFilteredClues,
    addNewClue,
    //handleRefreshClues,
    markPuzzleAsDone,
    loadedClue,
    gm_id,
    puzzlesDone,
    userPermissions,
    handleSelectPuzzle,
    account,
  } = props;

  const [puzzleDoneStatus, setPuzzleDoneStatus] = useState(
    puzzlesDone.filter((donePuzzle) => {
      if (donePuzzle.id === selectedPuzzleObj.id) return donePuzzle;
    })
  );
  const doneStatus = () => {
    // const includesId = selectedPuzzleObj ? selectedPuzzleObj.id : 0;

    // if (puzzlesDone.includes(includesId)) {
    //   return true;
    // } else return false;

    const puzzleDone = puzzlesDone.filter((donePuzzle) => {
      if (donePuzzle.id === selectedPuzzleObj.id) return donePuzzle;
    });
    if (puzzleDone) {
      return true;
    } else {
      return false;
    }
  };

  const handleAddNewClue = (data, id) => {
    addNewClue(data, id);
  };

  const delete_puzzle = (id, selectedPuzzle) => {
    props.deletePuzzle(id, selectedPuzzle);
    handleSelectPuzzle();
  };

  const addNewClueUsingInput = (message) => {
    if (message.newClue) {
      const data = {
        puzzle_id: selectedPuzzleObj.id,
        message: message.newClue,
      };

      addNewClue(data, id, selectedPuzzleObj.id);
    }
  };

  const puzzleUpdate = (update) => {
    props.updatePuzzle(id, selectedPuzzleObj.id, update);
    handleClearFilteredClues();
  };

  const [puzzleName, setPuzzleName] = useState("Puzzle Name");
  const [note, setNote] = useState("Note");
  const [contains, setContains] = useState();

  const [sortClueStatus, setSortClueStatus] = useState(false);

  const [newClue, setNewClue] = useState("Write a hint...");
  const [showDoneButton, setShowDoneButton] = useState();

  useEffect(() => {
    if (selectedPuzzleObj && selectedPuzzleObj.done) setShowDoneButton(true);
    if (selectedPuzzleObj && !selectedPuzzleObj.done) setShowDoneButton(false);
    if (selectedPuzzleObj && selectedPuzzleObj.color)
      setPuzzleColor(selectedPuzzleObj.color);
    if (selectedPuzzleObj && !selectedPuzzleObj.color) setPuzzleColor("#fff");

    setPuzzleDoneStatus(
      puzzlesDone.filter((donePuzzle) => {
        if (donePuzzle.id === selectedPuzzleObj.id) return donePuzzle;
      })
    );
  }, [selectedPuzzleObj]);

  useEffect(() => {
    setPuzzleName(selectedPuzzleName);
  }, [selectedPuzzleName]);

  useEffect(() => {
    setNote(selectedPuzzleObj ? selectedPuzzleObj.note : "Note");
    setContains(selectedPuzzleObj ? selectedPuzzleObj.contains : null);
  }, [selectedPuzzleObj]);

  // Sortable Clues
  const [sortableClues, setSortableClues] = useState(clues);
  useEffect(() => {
    setSortableClues(clues);
  }, [clues]);

  const [sentClues, setSentClues] = useState(props.sentClues);
  useEffect(() => {
    setSentClues(props.sentClues);
  }, [props.sentClues]);

  const sortableChange = () => {
    props.reorderClues(id, selectedPuzzleObj.id, sortableClues);
  };

  //Set Puzzle Color
  const [canEditPuzzleColor, setCanEditPuzzleColor] = useState(false);
  const [puzzleColor, setPuzzleColor] = useState(
    selectedPuzzleObj && selectedPuzzleObj.color
      ? selectedPuzzleObj.color
      : "#000"
  );

  const changePuzzleColor = (color) => {
    if (userPermissions.isAdmin || userPermissions.canEditHints) {
      setCanEditPuzzleColor(!canEditPuzzleColor);

      if (puzzleColor !== selectedPuzzleObj.color) {
        setPuzzleColor(puzzleColor);
        const update = {
          color: puzzleColor,
        };

        props.updatePuzzle(id, selectedPuzzleObj.id, update);
      }
    }
  };

  const [showEditPuzzle, setShowEditPuzzle] = useState(false);
  const [puzzleEditColor, setPuzzleEditColor] = useState(false);

  const [showHintDetails, setShowHintDetails] = useState(false);

  console.log("ACCOUNT", account);

  return (
    <React.Fragment>
      <div
        id="clue-box"
        className="chat-sidebar clues-sidebar bolt-box-shadow"
        style={{ left: "250px", bottom: "42px", top: "40px" }}
      >
        <div
          className="box-header"
          style={{ paddingRight: "10px", whiteSpace: "nowrap" }}
        >
          {selectedPuzzleName ? (
            <h6
              style={{
                marginBottom: "0px",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {selectedPuzzleName}
            </h6>
          ) : (
            <h6 style={{ marginBottom: "0px" }}>Clues</h6>
          )}
        </div>

        <div className="chat-sidebar-body" style={{ top: "34px" }}>
          {selectedPuzzleName ? (
            <React.Fragment>
              <div
                className="border-b border-gray-700"
                style={{
                  padding: "20px",
                  color: "#a8b8ba",
                  fontWeight: "500",
                  // borderTop: "none",
                  // borderBottom: "1px solid #404040",
                }}
              >
                {/* <div>{doneStatus()}</div> */}

                {puzzleDoneStatus.length > 0 ? (
                  <div
                  className="flex items-center justify-between mb-3"

                  >
                    <div className="text-green-500">
                      {/* <i
                        class="fa-solid fa-check"
                        style={{ marginRight: "8px" }}
                      ></i>{" "} */}
                      <i class="fa-kit fa-regular-cube-circle-check mr-2"></i>
                      Completed at {puzzleDoneStatus[0].completed_at.timer.m}:
                      {puzzleDoneStatus[0].completed_at.timer.s}
                    </div>

                    <button
                      onClick={(e) =>
                        markPuzzleAsDone(selectedPuzzleObj, false)
                      }
                      type="button"
                      className="button-outline-dark text-xs"
                    >
                      Undo
                    </button>
                  </div>
                ) : (
                  <button
                    onClick={(e) => markPuzzleAsDone(selectedPuzzleObj, true)}
                    type="button"
                    className="button-gray-dark w-full"
                    style={{
                      marginRight: "10px",
                      fontSize: "14px",
                      fontWeight: "600",
                      marginBottom: "20px",
                    }}
                  >
                    <i
                      class="fa-solid fa-check"
                      style={{ marginRight: "8px" }}
                    ></i>
                    Mark Complete
                  </button>
                )}

                {/* <div>

{selectedPuzzleObj && selectedPuzzleObj.example ? (
    <div style={{
      background:'#ccc',
      padding: '5px 10px',
      borderRadius:'3px',
      border: '1px solid #000',
      color: '#000',
      marginBottom:'10px',
      // width:'100%',
    }}>
        <p style={{margin:'0'}}><i style={{marginRight:'5px'}} className="fa-solid fa-circle-info"></i>Example</p>
    </div>
) : null }

</div> */}

                <div
                  style={{ display: "flex", alignItems: "center" }}
                  onMouseOver={() => setShowEditPuzzle(true)}
                  onMouseLeave={() => setShowEditPuzzle(false)}
                >
                  <div
                    style={{
                      paddingRight: "10px",
                      //cursor: userPermissions.isAdmin || userPermissions.canEditHints ? "pointer" : null
                    }}
                    // href={userPermissions.isAdmin || userPermissions.canEditHints ? "#editPuzzleModal" : '#'}
                    // data-toggle="modal"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <h5 style={{ marginBottom: "0px" }}>
                        {/* <ion-icon
                          name="ellipse"
                          style={{
                            color:
                              puzzleColor === "transparent"
                                ? null
                                : puzzleColor,
                            marginRight: "5px",
                            marginBottom: "-2px",
                          }}
                        ></ion-icon> */}
                        {puzzleName}
                      </h5>
                    </div>

                    <div
                      style={{
                        overflow: "hidden",
                        // height: !showHintDetails ? '0px' : 'auto',
                        transition: "height 0.5s",
                      }}
                    >
                      {note && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "start",
                            marginTop: "10px",
                            marginBottom: "5px",
                          }}
                        >
                          {/* <p
                            className="tx-12 tx-bold text-muted"
                            style={{ marginBottom: "0" }}
                          >
                            Answer
                          </p> */}
                          <Tooltip title="Answer" placement="left" arrow>
                            <span>
                              <i
                                class="fa-regular fa-unlock text-muted"
                                style={{
                                  marginRight: "10px",
                                  marginTop: "1px",
                                }}
                              ></i>
                            </span>
                          </Tooltip>
                          <p
                            className="tx-12"
                            style={{ marginBottom: "0", fontWeight: "400" }}
                          >
                            {note ? note : "Add a note"}
                          </p>
                        </div>
                      )}

                      {contains && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "start",
                          }}
                        >
                          {/* <p
                            className="tx-12 tx-bold text-muted"
                            style={{ marginBottom: "0" }}
                          >
                            Description
                          </p> */}
                          <Tooltip title="Description" placement="left" arrow>
                            <span>
                              <i
                                class="fa-regular fa-circle-info text-muted"
                                style={{ marginRight: "8px", marginTop: "2px" }}
                              ></i>
                            </span>
                          </Tooltip>
                          <p
                            className="tx-12"
                            style={{ marginBottom: "0", fontWeight: "400" }}
                          >
                            {selectedPuzzleObj.contains}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>


              </div>
            </React.Fragment>
          ) : null}


{selectedPuzzleName && (
            <div
              className="w-full bg-[#28292e] py-1 border-b border-gray-700"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "10px",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Tooltip title="Add Text Clue" placement="top" arrow>
                  <button
                    href={
                      userPermissions.isAdmin || userPermissions.canEditHints
                        ? "#addClueModal"
                        : "#"
                    }
                    id="addTextClue"
                    data-toggle="modal"
                    className="bolt-icon-button group"
                  >
                    {/* <i className="fa-solid fa-plus"></i> */}
                    {/* <i class="fa-solid fa-message-text"></i> */}
                    <i class="fa-kit fa-regular-message-text-circle-plus group-hover:text-gray-200"></i>
                  </button>
                </Tooltip>

                <Tooltip
                  title={
                    <div className="flex items-center space-x-2">
                      <p>Add Image Clue</p>
                      {account.subscription.status !== "active" && (
                        <div className="bg-green-600 border border-green-400 px-2 rounded">
                          <i class="fa-regular fa-lock mr-1"></i>Premium
                        </div>
                      )}
                    </div>
                  }
                  placement="top"
                  arrow
                >
                  <button
                    href={
                      (userPermissions.isAdmin &&
                        account.subscription.status === "active") ||
                      (userPermissions.canEditHints &&
                        account.subscription.status === "active")
                        ? "#addImageClueModal"
                        : "#proFeatureOnly"
                    }
                    id="addImageClue"
                    data-toggle="modal"
                    className="bolt-icon-button group"
                  >
                    {/* <i class="fa-solid fa-image"></i> */}
                    <i class="fa-kit fa-regular-image-circle-plus group-hover:text-gray-200"></i>
                  </button>
                </Tooltip>

                <Tooltip
                  title={
                    <div className="flex items-center space-x-2">
                      <p>Add Video Clue</p>
                      <div className="bg-purple-500 px-2 rounded">BETA</div>
                      {account.subscription.status !== "active" && (
                        <div className="bg-green-600 border border-green-400 px-2 rounded">
                          <i class="fa-regular fa-lock mr-1"></i>Premium
                        </div>
                      )}
                    </div>
                  }
                  placement="top"
                  arrow
                >
                  <button
                    href={
                      (userPermissions.isAdmin &&
                        account.subscription.status === "active") ||
                      (userPermissions.canEditHints &&
                        account.subscription.status === "active")
                        ? "#addVideoClueModal"
                        : "#proFeatureOnly"
                    }
                    id="addImageClue"
                    data-toggle="modal"
                    className="bolt-icon-button group"
                  >
                    {/* <i class="fa-brands fa-youtube"></i> */}
                    <i class="fa-kit fa-regular-clapperboard-play-circle-plus group-hover:text-gray-200"></i>
                  </button>
                </Tooltip>
              </div>

              <div>
                {/* <Tooltip title="Sort Clues" placement="top" arrow>
                <button
                  onClick={
                    userPermissions.isAdmin || userPermissions.canEditHints
                      ? (e) => setSortClueStatus(true)
                      : null
                  }
                  data-toggle="modal"
                  className="bolt-icon-button"
                >
                  <i className="fa-solid fa-sort"></i>
                </button>
              </Tooltip> */}

                <Tooltip title="Puzzle Settings" placement="top" arrow>
                  <button
                    href={
                      userPermissions.isAdmin || userPermissions.canEditHints
                        ? "#editPuzzleModal"
                        : "#"
                    }
                    data-toggle="modal"
                    className="bolt-icon-button group"
                  >
                    {/* <i className="fa-regular fa-gear"></i> */}
                    <i class="fa-solid fa-wrench group-hover:text-gray-200"></i>
                  </button>
                </Tooltip>

                <Tooltip title="Delete Puzzle" placement="top" arrow>
                  <button
                    href={
                      userPermissions.isAdmin || userPermissions.canEditHints
                        ? "#deletePuzzleModal"
                        : "#"
                    }
                    data-toggle="modal"
                    className="bolt-icon-button group"
                  >
                    <i className="fa-solid fa-trash group-hover:text-gray-200"></i>
                  </button>
                </Tooltip>
              </div>
            </div>
          )}

          <div className="flex-fill pd-y-10 pd-x-10 ">
            {allClues ? (
              <div id="chatDirectMsg" className="chat-msg-list mb-[40px]">
                {!selectedPuzzleName ? (
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "60px",
                      opacity: "0.5",
                    }}
                  >
                    <ion-icon
                      style={{ color: "#a8b8ba" }}
                      size="large"
                      name="cube-outline"
                    ></ion-icon>
                    <p className="text-gray-300">No objective selected.</p>
                  </div>
                ) : (
                  <React.Fragment>
                    {sortableClues.length < 1 ? (
                      <div
                      className="text-gray-400"
                        style={{
                          textAlign: "center",
                          marginTop: "60px",
                          opacity: "0.5",
                        }}
                      >
                        {/* <ion-icon
                          style={{ color: "#a8b8ba" }}
                          size="large"
                          name="flash-outline"
                        ></ion-icon> */}
                        <i class="fa-regular fa-umbrella text-4xl mb-2"></i>
                        <p>No clues added</p>
                      </div>
                    ) : (
                      <React.Fragment>
                        <ReactSortable
                          disabled={
                            userPermissions.isAdmin ||
                            userPermissions.canEditHints
                              ? false
                              : true
                          }
                          list={sortableClues}
                          setList={setSortableClues}
                          animation={200}
                          delayOnTouchStart={true}
                          delay={5}
                          onSort={() => sortableChange()}
                        >
                          {sortableClues.map((clueObj, idx) => {
                            var isSent = false;
                            if (sentClues.includes(clueObj.id)) isSent = true;

                            if (!clueObj.type || clueObj.type === "text") {
                              return (
                                <ClueButton
                                  key={clueObj.id}
                                  data-id={clueObj.id}
                                  //style={style}
                                  clueObj={clueObj}
                                  handleLoadClueClick={handleLoadClueClick}
                                  loadedClue={loadedClue}
                                  puzzlesDone={puzzlesDone}
                                  selectedPuzzleObj={selectedPuzzleObj}
                                  color={puzzleColor}
                                  isSent={isSent}
                                  userPermissions={userPermissions}
                                  sortClueStatus={sortClueStatus}
                                />
                              );
                            }

                            if (clueObj.type && clueObj.type === "image") {
                              return (
                                <ImageClueButton
                                  account={account}
                                  key={clueObj.id}
                                  data-id={clueObj.id}
                                  //style={style}
                                  clueObj={clueObj}
                                  handleLoadClueClick={handleLoadClueClick}
                                  loadedClue={loadedClue}
                                  puzzlesDone={puzzlesDone}
                                  selectedPuzzleObj={selectedPuzzleObj}
                                  color={puzzleColor}
                                  isSent={isSent}
                                  userPermissions={userPermissions}
                                />
                              );
                            }

                            if (clueObj.type && clueObj.type === "video") {
                              return (
                                <VideoClueButton
                                  account={account}
                                  key={clueObj.id}
                                  data-id={clueObj.id}
                                  //style={style}
                                  clueObj={clueObj}
                                  handleLoadClueClick={handleLoadClueClick}
                                  loadedClue={loadedClue}
                                  puzzlesDone={puzzlesDone}
                                  selectedPuzzleObj={selectedPuzzleObj}
                                  color={puzzleColor}
                                  isSent={isSent}
                                  userPermissions={userPermissions}
                                  game_doc={gm_id}
                                />
                              );
                            }

                            // if (clueObj.type && clueObj.type === "audio") {
                            //   return (
                            //     <AudioClueButton
                            //       key={clueObj.id}
                            //       data-id={clueObj.id}
                            //       //style={style}
                            //       clueObj={clueObj}
                            //       handleLoadClueClick={handleLoadClueClick}
                            //       loadedClue={loadedClue}
                            //       puzzlesDone={puzzlesDone}
                            //       selectedPuzzleObj={selectedPuzzleObj}
                            //       color={puzzleColor}
                            //       isSent={isSent}
                            //       userPermissions={userPermissions}
                            //     />
                            //   );
                            // }

                            return;
                          })}
                        </ReactSortable>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </div>
            ) : null}
          </div>


        </div>
      </div>

      <AddClueModal
        id={id}
        game_id={gm_id}
        puzzles={puzzles}
        handleAddNewClue={handleAddNewClue}
        selectedPuzzleObj={selectedPuzzleObj}
      />

      <AddImageClueModal
        id={id}
        game_id={gm_id}
        puzzles={puzzles}
        handleAddNewClue={handleAddNewClue}
        selectedPuzzleObj={selectedPuzzleObj}
      />

      <AddVideoClueModal
        id={id}
        game_id={gm_id}
        puzzles={puzzles}
        handleAddNewClue={handleAddNewClue}
        selectedPuzzleObj={selectedPuzzleObj}
      />

      <ProFeatureOnly account={account} />

      <EditDeleteClueModal
        id={id}
        puzzles={puzzles}
        loadedClue={loadedClue}
        gm_id={gm_id}
      />

      <EditDeleteImageClueModal
        id={id}
        puzzles={puzzles}
        loadedClue={loadedClue}
        gm_id={gm_id}
      />

      <EditDeleteVideoClueModal
        id={id}
        puzzles={puzzles}
        loadedClue={loadedClue}
        gm_id={gm_id}
      />

      <EditPuzzleModal
        id={id}
        selectedPuzzleObj={selectedPuzzleObj}
        gm_id={gm_id}
        puzzleUpdate={puzzleUpdate}
      />

      <DeletePuzzleModal
        delete_puzzle={delete_puzzle}
        id={id}
        selectedPuzzle={selectedPuzzleObj}
      />
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addNewClue: (data, id, puzzle_id) =>
      dispatch(addNewClue(data, id, puzzle_id)),
    updatePuzzle: (doc_id, puzzle_id, update) =>
      dispatch(updatePuzzle(doc_id, puzzle_id, update)),
    reorderClues: (doc_id, puzzle_id, data) =>
      dispatch(reorderClues(doc_id, puzzle_id, data)),
    deletePuzzle: (doc_id, puzzle_id) =>
      dispatch(deletePuzzle(doc_id, puzzle_id)),
  };
};

export default connect(null, mapDispatchToProps)(LeftSideBar);
