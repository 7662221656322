import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { active } from "sortablejs";

export const Header = (props) => {
  const { game, profile, account, userPermissions } = props;
  const displayName = () => {
    if (profile.firstName && profile.lastName)
      return profile.firstName + " " + profile.lastName;
    else if (account) return account.title;
    else return "";
  };

  const [username, setUsername] = useState();
  useEffect(() => {
    if ((account && account.title) || account.company_title)
      setUsername(account.title || account.company_title);
  }, [account]);

  const [activeButton, setActiveButton] = useState("puzzles");

  const toggleFullScreen = () => {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  };

  return (
    <div
      style={{
        height: "40px",
        background: "rgb(26, 27, 32)",
        borderBottom: "1px solid black",
        width: "100%",
        display: "flex",
        alignItems: "center",
        paddingLeft: "10px",
        paddingRight: "20px",
      }}
    >
      <a
        href="#ExitToDashboardModal"
        data-toggle="modal"
        style={{
          borderRadius: "50%",
          display: "flex",
          padding: "5px",
        }}
        className="btn btn-outline-light"
      >
        {/* <img src="/hintspace_icon.svg" width="20px" alt="icon" /> */}
        <ion-icon
          name="flash"
          style={{ color: "rgb(236, 236, 88)", fontSize: "20px" }}
        ></ion-icon>
      </a>

      <div style={{ marginLeft: "20px", display: "flex" }}>
        <div
          style={{
            marginRight: "30px",
            marginBottom: activeButton !== "puzzles" ? "2px" : "0px",
            paddingTop: "2px",
            padding: "0px 10px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            borderBottom: activeButton === "puzzles" ? "2px solid white" : null,
          }}
        >
          <Link
            to="?view=puzzles"
            onClick={() => setActiveButton("puzzles")}
            style={activeButton !== "puzzles" ? { opacity: "0.5" } : null}
          >
            <p className="mb-0 text-white">
              <i
                class="fa-solid fa-rectangles-mixed"
                style={{ marginRight: "8px", opacity: "0.5" }}
              ></i>
              Control Panel
            </p>
          </Link>
        </div>

        <div
          style={{
            marginRight: "30px",
            marginBottom: activeButton !== "audio-mixer" ? "2px" : "0px",
            paddingTop: "2px",
            padding: "0px 10px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            borderBottom:
              activeButton === "audio-mixer" ? "2px solid white" : null,
          }}
        >
          <Link
            to="?view=audio-mixer"
            onClick={() => setActiveButton("audio-mixer")}
            style={activeButton !== "audio-mixer" ? { opacity: "0.5" } : null}
          >
            {/* <i className="icon ion-md-options" style={{marginRight:"5px"}}></i> */}
            <p className="mb-0 text-white">
              <i
                class="fa-solid fa-speakers"
                style={{ marginRight: "8px", opacity: "0.5" }}
              ></i>
              Audio Mixer
            </p>
          </Link>
        </div>


        <div
          style={{
            marginRight: "30px",
            marginBottom: activeButton !== "history" ? "2px" : "0px",
            paddingTop: "2px",
            padding: "0px 10px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            borderBottom: activeButton === "history" ? "2px solid white" : null,
          }}
        >
          {/* <Link
            to="?view=history"
            onClick={() => setActiveButton("history")}
            style={activeButton !== "history" ? { opacity: "0.5" } : null}
          >
            <p className="mb-0 text-white">
              <i
                class="fa-solid fa-chart-simple"
                style={{ marginRight: "8px", opacity: "0.5" }}
              ></i>
              History
            </p>
          </Link> */}
        </div>

      </div>

      <div
        className="flex items-center justify-center space-x-2"
        style={{ position: "absolute", left: "50%" }}
      >
        <p className="mb-0 text-white">
          <i class="fa-regular fa-door-open mr-2"></i> {game}
        </p>
        {/* {account.subscription.status !== "active" && <div className='bg-green-600 px-2 rounded text-white text-xs'>Free Plan</div>} */}
      </div>

      <div
        style={{
          marginLeft: "auto",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <button
          onClick={(e) => toggleFullScreen()}
          className="button-outline-dark text-xs"
          style={{ marginRight: "20px" }}
        >
          <i class="fa-solid fa-expand" style={{ marginRight: "8px" }}></i>
          Fullscreen
        </button>
        <p className="mb-0 text-white">
          {/* <i className="icon ion-ios-contact" style={{marginRight:"5px"}}></i> */}
          <i class="fa-solid fa-user" style={{ marginRight: "8px" }}></i>
          {username}
        </p>
      </div>
    </div>
  );
};

export default Header;
